import copyToClipboard from "copy-to-clipboard";
import { FC, useCallback, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import createRecordingSessionShareLink from "../../../store/actions/projects/createRecordingSessionShareLink";
import createListenLink from "../../../store/actions/projects/createListenLink";
import {
  createScheduledProjectShareLink,
  shareInProgressProjectOverviewLink,
} from "../../../store/actions/scheduledprojects";
import { useAppDispatch } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { getLoggedOutSessionDetailsRoute } from "../../../store/utils/routeGetters";
import { getHost, validateEmailList } from "../../../utils/utils";
import { Button } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Textfield } from "../../elements/Textfield1/Textfield1";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch"; // for ES6 modules
import {
  ShareLinkButtonContainer,
  ShareLinkContentContainer,
  ShareLinkInput,
  ShareLinkModalContainer,
  ShareLinkText,
} from "./ShareLinkModal.styles";

export interface ShareLinkModalProps {
  closeModal?: () => void;
  contentOnly?: boolean; // used to render the link form without the modal card
  hidePassword?: boolean;
  inProgressScheduledProjectId?: number; // inProgressScheduledProjectId is only used to share an in progress project with an artist.
  projectId?: string; // projectId is only used when creating a listen link.
  scheduledProjectId?: string; // scheduledProjectId is only used when creating a share link.
  projectType?: ProjectType;
}
export const ShareLinkModal: FC<ShareLinkModalProps> = ({
  closeModal = () => {},
  contentOnly = false,
  hidePassword = false,
  inProgressScheduledProjectId,
  projectId,
  scheduledProjectId,
  projectType,
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [password, setPassword] = useState("");
  const [isSingleUse, setIsSingleUse] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string>("");
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const dispatch = useAppDispatch();
  const host = getHost();
  const { isMobile } = useMediaQueryBreakpoint();

  const buttonTitle =
    !emails || emails.length === 0
      ? "Get Link"
      : isSingleUse
        ? "Send single use link"
        : "Send link";

  const handleCopyLink = useCallback(
    async (link: string) => {
      setLink(link);
      if (navigator.share && isMobile) {
        await navigator.share({ url: link }).catch(() => {});
      } else {
        setCopiedToClipboard(copyToClipboard(link));
      }
      setTimeout(() => {
        setCopiedToClipboard(false);
      }, 3000);
    },
    [isMobile, setCopiedToClipboard],
  );

  const handleCreateShareLink = useCallback(
    async (scheduledProjectId: string) => {
      return dispatch(
        createScheduledProjectShareLink({
          scheduled_project_id: scheduledProjectId,
          emails: emails,
          ...(password.length > 0 && { password }),
        }),
      )
        .unwrap()
        .then(
          (data) =>
            `${host}/project-overview/${scheduledProjectId}/?code=${data[0].code}`,
        );
    },
    [dispatch, password, emails, host],
  );

  const handleCreateListenLink = useCallback(
    async (projectId: string) => {
      return dispatch(
        createListenLink({
          project_id: projectId,
          emails: emails,
          ...(password.length > 0 && { password }),
          ...(isSingleUse && { single_use: isSingleUse }),
        }),
      )
        .unwrap()
        .then((data) => `${host}/listen/${projectId}/?code=${data[0].code}`);
    },
    [dispatch, emails, password, isSingleUse, host],
  );

  const handleCreateRecordingSessionShareLink = useCallback(
    async (projectId: string) => {
      return dispatch(
        createRecordingSessionShareLink({
          project_id: projectId,
          emails: emails,
          ...(password.length > 0 && { password }),
        }),
      )
        .unwrap()
        .then(
          (data) =>
            `${host}${getLoggedOutSessionDetailsRoute(projectId, data[0].code)}`,
        );
    },
    [dispatch, emails, password, host],
  );

  const handleShareInProgressProject = useCallback(
    async (inProgressProjectId: number) => {
      return dispatch(
        shareInProgressProjectOverviewLink({
          scheduledProjectId: inProgressProjectId,
          emails,
        }),
      )
        .unwrap()
        .then(
          (res) =>
            `${host}/project-overview/${inProgressProjectId}/?code=${res.code}`,
        );
    },
    [dispatch, host, emails],
  );

  const handleOnClick = useCallback(async () => {
    setLoading(true);

    if (emails.length > 0 && !validateEmailList(emails)) {
      toast.error("Invalid emails provided");
      setLoading(false);
      return;
    }

    try {
      if (inProgressScheduledProjectId) {
        await handleShareInProgressProject(inProgressScheduledProjectId).then(
          (link) => handleCopyLink(link),
        );
      } else if (scheduledProjectId) {
        await handleCreateShareLink(scheduledProjectId).then((link) =>
          handleCopyLink(link),
        );
      } else if (projectId && projectType === ProjectType.RECORDING) {
        await handleCreateRecordingSessionShareLink(projectId).then((link) =>
          handleCopyLink(link),
        );
      } else if (projectId) {
        await handleCreateListenLink(projectId).then((link) =>
          handleCopyLink(link),
        );
      }
    } finally {
      setLoading(false);
      toast.success("Share link has been generated.");
      if (emails && emails.length > 0) closeModal();
    }
  }, [
    emails,
    inProgressScheduledProjectId,
    scheduledProjectId,
    projectId,
    projectType,
    handleShareInProgressProject,
    handleCopyLink,
    handleCreateShareLink,
    handleCreateRecordingSessionShareLink,
    handleCreateListenLink,
    closeModal,
  ]);

  const shareLinkText = () => {
    if (projectType === ProjectType.RECORDING) {
      return "recording session";
    }

    if (projectId) {
      return "playback";
    }

    return "project";
  };

  const ShareLinkContent = (
    <ShareLinkContentContainer>
      {!contentOnly && <Text variant={TextStyleVariant.S3}>Share Project</Text>}
      <ShareLinkText>
        We&apos;ll send an email with the link to access this {shareLinkText()}{" "}
        page
      </ShareLinkText>

      <ShareLinkText variant={TextStyleVariant.P2}>Emails</ShareLinkText>
      <ReactMultiEmail
        className="purchase-order-form-multi-email"
        placeholder={"Provide a list of emails"}
        emails={emails}
        onChange={(_emails: string[]) => {
          setEmails(_emails);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
        style={{ margin: "unset" }}
      />
      {!hidePassword && (
        <>
          <ShareLinkText variant={TextStyleVariant.P2}>Password</ShareLinkText>
          <Textfield
            type="password"
            value={password}
            autoComplete="new-password"
            custom={false}
            label="Enter a password to protect your link"
            onChange={(e) => {
              const text = e.target.value;
              setPassword(text);
            }}
          />
        </>
      )}
      {!scheduledProjectId &&
        !inProgressScheduledProjectId &&
        projectType !== ProjectType.RECORDING && (
          <div style={{ marginTop: "16px" }}>
            <ToggleSwitch
              id="single-use-toggle"
              label={"One Time Access"}
              onChange={() => {
                setIsSingleUse(!isSingleUse);
              }}
              checked={isSingleUse}
            />
          </div>
        )}
      <ShareLinkButtonContainer>
        <Button
          onClick={handleOnClick}
          disabled={loading || copiedToClipboard}
          loading={loading}
          style={{ width: "180px" }}
        >
          {copiedToClipboard ? "Copied to clipboard" : buttonTitle}
        </Button>
      </ShareLinkButtonContainer>
      {link && <ShareLinkInput readOnly value={link} />}
    </ShareLinkContentContainer>
  );

  if (contentOnly) {
    return ShareLinkContent;
  }

  return <ShareLinkModalContainer>{ShareLinkContent}</ShareLinkModalContainer>;
};
