import { useAtomValue, useSetAtom } from "jotai";
import { checkoutAuthInProgressAtom } from "../atoms/auth/checkoutAuthInProgress";
import { authModalOpenAtom } from "../stories/components/Auth/atoms";
import { useCallback, useEffect, useMemo } from "react";
import { useEnteredUMGEmail } from "./authHooks/useEnteredUMGEmail";
import useAuthHandler from "./authHooks/useAuthHandler";
import { activeUserAtom } from "../atoms/user/activeUserAtom";

// IF YOU CHANGE THIS LIST HERE, YOU MUST CHANGE THIS LIST ON THE BACKEND: is_valid_aandr_email
const validAandREmailDomains: string[] = [
  "@atlanticrecords.com",
  "@engineears.com",
  "@umusic.com",
  "@umgconsult.com",
  "@qualitycontrolmusic.com",
  "@eqt.co",
  "@colorsxstudio.com",
  "@neon16.co",
  "@redbullrecords.com",
  "@evglemusic.com",
  "@epicrecords.com",
  "@awal.com",
  "@warnerrecords.com",
  "@warnerchappell.com",
  "@warprecords.com",
  "@empi.re",
  "@rocnation.com",
];

function isValidAandREmail(email: string): boolean {
  return validAandREmailDomains.some((domain) => email.endsWith(domain));
}

export const useDisplayAandRAuth = (
  email: string,
  otpCheckoutEnabled: boolean,
) => {
  const setCheckoutAuth = useSetAtom(checkoutAuthInProgressAtom);
  const authModalOpen = useAtomValue(authModalOpenAtom);
  const { initializeAuth } = useAuthHandler();

  const enteredUMGEmail = useEnteredUMGEmail(email);
  const user = useAtomValue(activeUserAtom);

  useEffect(() => {
    if (user) {
      return;
    }
    if (!otpCheckoutEnabled && !authModalOpen) {
      setCheckoutAuth(false);
    }
    if (otpCheckoutEnabled || authModalOpen) {
      setCheckoutAuth(true);
    }
  }, [otpCheckoutEnabled, authModalOpen, setCheckoutAuth, user]);

  const isAandREmail = useMemo(() => {
    return isValidAandREmail(email);
  }, [email]);

  const handleAandRAuth = useCallback(() => {
    if (enteredUMGEmail) return;
    if (!isAandREmail) return;
    initializeAuth(email);
  }, [enteredUMGEmail, isAandREmail, initializeAuth, email]);

  useEffect(() => {
    if (user) {
      return;
    }
    handleAandRAuth();
  }, [handleAandRAuth, user]);

  return {
    isAandREmail,
    handleAandRAuth,
  };
};
