import { useQuery } from "@tanstack/react-query";
import {
  getGuestVerificationCode,
  GetGuestVerificationCodeParams,
} from "../api/auth/getGuestVerificationCode";
import { EngineEarsError } from "../store/actions/errorStore";
import { QUERY_KEYS } from "../constants/queryKeys";
import { emitAnalyticsTrackingEvent } from "../utils/analyticsUtils";
import { useAtomValue } from "jotai";
import { activeUserAtom } from "../atoms/user/activeUserAtom";

interface GuestCheckoutGetResponse {
  user_id: string;
}

const useGetGuestVerificationCodeQuery = (
  params: GetGuestVerificationCodeParams,
) => {
  const activeUser = useAtomValue(activeUserAtom);

  const hasContactInfo = Boolean(params.email || params.phone_number);
  const isUserActive = Boolean(activeUser?.id);

  return useQuery<GuestCheckoutGetResponse, EngineEarsError>({
    enabled: hasContactInfo && !isUserActive,
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
    queryKey: [QUERY_KEYS.LOGIN_GUEST_VERIFICATION_CODE, params],
    queryFn: async () => {
      emitAnalyticsTrackingEvent("guest_login_get_code", {
        email: params.email,
        phoneNumber: params.phone_number,
      });
      const result = await getGuestVerificationCode(params);
      if (!result.success) {
        throw result.resultJson as EngineEarsError;
      }
      return {
        user_id: result.resultJson.user_id,
      } as GuestCheckoutGetResponse;
    },
  });
};

export default useGetGuestVerificationCodeQuery;
