import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useIsInProgressProject } from "../../../hooks/partialPaymentHooks";
import useClickOutside from "../../../hooks/useClickOutside";
import renameProjectOrScheduledProject from "../../../store/actions/projects/renameProjectOrScheduledProject";
import { useAppDispatch } from "../../../store/hooks";
import {
  Project,
  ProjectById,
  ProjectType,
} from "../../../store/models/project";
import {
  PaginatedScheduledProject,
  ScheduledProject,
} from "../../../store/models/scheduledproject";
import {
  InfoBadge,
  InfoBadgeType,
} from "../../core-ui/components/InfoBadge/InfoBadge";
import { Text, TEXT_COLOR } from "../../core-ui/components/Text/Text";
import { AltsList } from "../AltsList/AltsList";
import {
  ProjectListButton,
  ProjectListRowAltInfoContainer,
  ProjectListRowButtonChildrenContainer,
  ProjectListRowContainer,
  ProjectListRowEditIconDiv,
  ProjectListRowText,
  StyledProjectListInput,
} from "./ProjectListRow.styles";

export interface ProjectListRowProps {
  defaultShowAlts?: boolean;
  transactionCode?: string;
  showTrackNumber?: boolean;
  // Only pick out necessary properties, so we can re-use this component
  project?:
    | Pick<Project, "service_type" | "title" | "id" | "alts" | "order_index">
    | ProjectById;
  paginatedScheduledProject?: PaginatedScheduledProject | ScheduledProject;
  allowEdit?: boolean;
  hideAlts?: boolean;
  showFundingStatus?: boolean;
  attachedProjects?: Project[];
}

export const ProjectListRow = ({
  defaultShowAlts = false,
  showTrackNumber = false,
  project,
  paginatedScheduledProject,
  transactionCode,
  allowEdit = true,
  hideAlts = false,
  showFundingStatus = false,
  attachedProjects = [],
}: ProjectListRowProps) => {
  const theme = useTheme();
  const [isShowingAlts, setIsShowingAlts] = useState(defaultShowAlts);
  const [isEditing, setIsEditing] = useState<boolean | null>(null);
  const [localProjectTitle, setLocalProjectTitle] = useState<string>(
    paginatedScheduledProject?.title ?? project?.title ?? "",
  );

  const { isInProgressProject } = useIsInProgressProject(
    paginatedScheduledProject,
  );
  const outstandingBalance = paginatedScheduledProject?.outstanding_balance;
  const isPartiallyPaid = outstandingBalance ? outstandingBalance > 0 : false;

  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsEditing(false);
  });

  useEffect(() => {
    if (!isEditing) return;
    if (localProjectTitle.includes("Untitled Project")) {
      setLocalProjectTitle("");
    }
  }, [localProjectTitle, isEditing]);

  useEffect(() => {
    if (project?.title && localProjectTitle !== project.title) {
      setLocalProjectTitle(project.title);
    }
  }, [project?.title]);

  useEffect(() => {
    if (!paginatedScheduledProject) return;
    setLocalProjectTitle(paginatedScheduledProject?.title ?? "");
    setIsEditing(false);
  }, [paginatedScheduledProject?.title]);

  useEffect(() => {
    if (!project) return;
    setLocalProjectTitle(project?.title ?? "");
    setIsEditing(false);
  }, [project?.title]);

  useEffect(() => {
    if (isEditing === null) return;
    if (isEditing) return;
    if (
      localProjectTitle === project?.title ||
      paginatedScheduledProject?.title === localProjectTitle
    )
      return;
    const title = localProjectTitle.length
      ? localProjectTitle
      : paginatedScheduledProject?.title ??
        project?.title ??
        "Untitled Project";
    dispatch(
      renameProjectOrScheduledProject({
        project_id: project?.id ?? undefined,
        scheduled_project_id: paginatedScheduledProject?.id ?? undefined,
        title: title,
        transaction_code: transactionCode,
      }),
    )
      .catch(() => toast.error("Failed to update project title"))
      .finally(() => {
        setIsEditing(false);
        setLocalProjectTitle(title);
      });
  }, [localProjectTitle, isEditing, transactionCode]);

  return (
    <ProjectListRowContainer>
      <ProjectListRowAltInfoContainer>
        <ProjectListRowButtonChildrenContainer
          ref={containerRef}
          // Inline style to push just this object to the max
          style={{ flex: 1, pointerEvents: isEditing ? "auto" : "none" }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            event.stopPropagation()
          }
        >
          {showTrackNumber && project?.order_index != null && (
            <Text>{project.order_index + 1}. </Text>
          )}
          {allowEdit && (
            <ProjectListRowEditIconDiv>
              <FontAwesomeIcon
                style={{
                  fontSize: theme.textSizeMd,
                  cursor: "pointer",
                  transform: isEditing ? "rotate(0deg)" : "rotate(270deg)",
                  marginLeft: showTrackNumber ? "16px" : "0px",
                  marginRight: "4px",
                  pointerEvents: "auto",
                }}
                icon={isEditing ? faCheck : faPen}
                size="sm"
                color={
                  isEditing ? "var(--success-color)" : theme.textPrimaryColor
                }
                onClick={() => {
                  setIsEditing((prev) => !prev);
                }}
              />
            </ProjectListRowEditIconDiv>
          )}
          <StyledProjectListInput
            type="text"
            disabled={!allowEdit || !isEditing}
            value={localProjectTitle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLocalProjectTitle(e.target.value)
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                setIsEditing((prev) => !prev);
              }
            }}
          />
          {showFundingStatus && (isInProgressProject || isPartiallyPaid) && (
            <InfoBadge
              type={
                isInProgressProject
                  ? InfoBadgeType.NOT_FUNDED
                  : InfoBadgeType.PARTIALLY_PAID
              }
              outstandingBalance={outstandingBalance}
            />
          )}
        </ProjectListRowButtonChildrenContainer>
        {project && !hideAlts && (
          <ProjectListButton onClick={() => setIsShowingAlts(!isShowingAlts)}>
            <ProjectListRowButtonChildrenContainer>
              <ProjectListRowText $small>Alt Version</ProjectListRowText>
              <FontAwesomeIcon
                color={TEXT_COLOR.TERTIARY}
                icon={isShowingAlts ? faChevronUp : faChevronDown}
              />
            </ProjectListRowButtonChildrenContainer>
          </ProjectListButton>
        )}
      </ProjectListRowAltInfoContainer>
      {project && project.service_type !== ProjectType.RECORDING && (
        <>
          <AltsList
            projectType={project.service_type}
            alts={project.alts}
            showContent={isShowingAlts}
          />
          {attachedProjects?.map((attachedProject) => (
            <AltsList
              key={attachedProject.id}
              projectType={attachedProject.service_type}
              alts={attachedProject.alts}
              showContent={isShowingAlts}
            />
          ))}
        </>
      )}
    </ProjectListRowContainer>
  );
};
