import { useProductTransaction } from "../../api/productTransactions/useFetchProducTransactionQuery";
import { TransactionStatus } from "../../store/models/transaction";

export const useTransactionStatus = (transactionCode?: string) => {
  const { data } = useProductTransaction(transactionCode);
  if (!data) {
    return TransactionStatus.PENDING;
  }
  return data.transaction.transaction_status;
};
