import {
  faFileArrowDown,
  faFileZipper,
} from "@fortawesome/pro-regular-svg-icons";
import { faWaveform } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { downloadTrack } from "../../../../store/actions/audioService";
import { useAppDispatch } from "../../../../store/hooks";
import {
  FileVersion,
  ZIP_UPLOAD_TYPE,
} from "../../../../store/models/fileVersion";
import { downloadFile } from "../../../../store/utils/utils";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { Text, TEXT_SIZE } from "../../../core-ui/components/Text/Text";
import {
  FileVersionButtonContainer,
  FileVersionRowContainer,
} from "./FileVersionRow.styles";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  useTheme,
} from "@mui/material";

export interface FileVersionRowProps {
  fileVersion: FileVersion;
  disabled: boolean;
  disabledText?: string;
  code?: string;
}

export const FileVersionRow = ({
  fileVersion,
  code,
  disabled,
  disabledText,
}: FileVersionRowProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: theme.palette.primary.main }}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text>{`${Math.round(props.value)}%`}</Text>
        </Box>
      </Box>
    );
  }

  const handleDownload = () => {
    setIsDownloading(true);
    void dispatch(
      downloadTrack({
        fileVersionId: fileVersion.id,
        code,
        onProgress: (progress: number) => setDownloadProgress(progress),
      }),
    )
      .unwrap()
      .then((response) => {
        downloadFile(response, fileVersion?.file_name ?? "no file name");
        setIsDownloading(false);
        setDownloadProgress(0);
      })
      .catch(() => {
        setIsDownloading(false);
        setDownloadProgress(0);
        toast.error("Failed to download file");
      });
  };

  return (
    <FileVersionRowContainer>
      <Text
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "100%",
          display: "inline-block",
        }}
        size={TEXT_SIZE.MEDIUM}
      >
        {fileVersion?.file_name ?? "No Name"}
      </Text>
      <FileVersionButtonContainer>
        {isDownloading ? (
          <CircularProgressWithLabel value={downloadProgress * 100} />
        ) : (
          <Button
            disabled={disabled}
            disableText={disabledText}
            onClick={handleDownload}
            variant={ButtonVariant.UNSTYLED}
          >
            <FontAwesomeIcon width={32} icon={faFileArrowDown} />
          </Button>
        )}
        <FontAwesomeIcon
          height={32}
          width={32}
          color={theme.palette.text.primary}
          icon={
            fileVersion.upload_type === ZIP_UPLOAD_TYPE
              ? faFileZipper
              : faWaveform
          }
        />
      </FileVersionButtonContainer>
    </FileVersionRowContainer>
  );
};
