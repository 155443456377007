import { SUPPLY_CHAIN_LABELS } from "../../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { SupplyChainLabelModel } from "./supplyChainModels";

export interface PostSupplyChainLabelParams {
  id?: number;
  name?: string;
  email?: string;
  countryId?: number;
}

interface PostSupplyChainLabelBody {
  id?: number;
  name?: string;
  email?: string;
  country_id?: number;
}

export const postSupplyChainLabel = async (
  params: PostSupplyChainLabelParams,
): Promise<SupplyChainLabelModel> => {
  const body: PostSupplyChainLabelBody = {
    id: params.id,
    name: params.name,
    email: params.email,
    country_id: params.countryId,
  };

  const response =
    await makeBackendPostCallWithJsonResponse<SupplyChainLabelModel>(
      SUPPLY_CHAIN_LABELS,
      body,
    );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};
