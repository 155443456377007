import {
  AddressElement,
  LinkAuthenticationElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  FinancialMethod,
  Transaction,
} from "../../../store/models/transaction";
import CardSection from "./CardSection";
import { useAccountStatus } from "../../../hooks/accountHooks";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import { updateProjectCounts } from "../../../store/actions/projects";
import {
  markRecordingSessionExtensionPaid,
  markRevisionTransactionPaid,
  markTransactionPaid,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import "./StripePaymentForm.css";
import { getPaymentRedirectURL } from "../../../store/utils/routeGetters";
import { Box, Grid2 } from "@mui/material";
import { GuestLoginAuth } from "../Auth/GuestLoginAuth";
import { useDisplayUMGAuth } from "../../../hooks/authHooks/useDisplayUMGAuth";
import { useAtomValue } from "jotai/index";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { isAddressFieldComplete } from "../../../api/productTransactions/utils";
import { useDisplayAandRAuth } from "../../../hooks/useDisplayAandRAuth";
import { getEmailFromActiveUser } from "../../../utils/getEmailFromActiveUser";
import { checkIfValidEmail } from "../../../utils/utils";
import { getPhoneFromActiveUser } from "../../../utils/getPhoneFromActiveUser";
import { useUpdateTransactionForProductMutation } from "../../../api/productTransactions/useUpdateTransactionForProductMutation";

export interface StripePaymentFormProps {
  transaction: Transaction;
  projectTitle?: string;
  artistName?: string;
  scheduledProjectIdForHandoff?: number;
  isRevisionPurchase?: boolean;
  isSessionExtensionPurchase?: boolean;
  checkPhoneNumber?: boolean;
  setParentLoadingState?: (loading: boolean) => void;
}

export const OTPAuthError: Error = {
  name: "OTPAuthError",
  message: "OTP Authentication failed. Please try again.",
};

export interface StripePaymentFormHandles {
  handleSubmit: () => Promise<void>;
}

export const StripePaymentForm = forwardRef<
  StripePaymentFormHandles,
  StripePaymentFormProps
>(
  (
    {
      projectTitle,
      scheduledProjectIdForHandoff,
      transaction,
      isRevisionPurchase,
      isSessionExtensionPurchase,
      artistName,
      setParentLoadingState,
    },
    ref: Ref<StripePaymentFormHandles>,
  ) => {
    // const [authModalValue] = useAtom(authModalOpenAtom);
    const [showOTPAuth, setShowOtpAuth] = useState(false);
    const activeUser = useAtomValue(activeUserAtom);
    const [email, setEmail] = useState(getEmailFromActiveUser(activeUser));
    const [isValidEmail, setIsValidEmail] = useState(checkIfValidEmail(email));
    const [phone, setPhone] = useState(getPhoneFromActiveUser(activeUser));
    const { phoneVerified, emailVerified } = useAccountStatus();
    const accountVerified = phoneVerified || emailVerified;
    const { user, isAuthenticated } = useAppSelector(
      (state) => state.accountInfo,
    );
    const stripe = useStripe();
    const elements = useElements();
    const [selectedFinancialMethod, setSelectedFinancialMethod] =
      useState<FinancialMethod>(transaction.financial_method);
    const dispatch = useAppDispatch();
    const errorMessages = {
      default:
        "Something went wrong. Please try again or reach out to customer support.",
      financial: "Please select a financial method.",
    };
    const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

    const urlEncodedProjectTitle = useMemo(
      () => encodeURIComponent(projectTitle ?? "Untitled"),
      [projectTitle],
    );
    const { enteredUMGEmail, handleUMGAuth } = useDisplayUMGAuth(
      email,
      showOTPAuth,
    );
    const { handleAandRAuth, isAandREmail } = useDisplayAandRAuth(
      email,
      showOTPAuth,
    );
    const { mutateAsync: updateTransactionForProduct } =
      useUpdateTransactionForProductMutation(transaction?.code);

    const handleSubmit = useCallback(async () => {
      if (!stripe || !elements) {
        throw new Error("Invalid stripe or elements instance");
      }
      if (!isValidEmail) {
        throw new Error("Please enter a valid email address.");
      }
      // Validate the shipping address
      const isAddressComplete = await isAddressFieldComplete(elements);
      if (!isAddressComplete[0]) {
        throw new Error(
          "Please complete your address information, including phone number",
        );
      }

      if (!isAuthenticated || !accountVerified) {
        if (isAandREmail) {
          handleAandRAuth();
        } else if (enteredUMGEmail) {
          handleUMGAuth();
        } else {
          setShowOtpAuth(true);
        }
        throw OTPAuthError;
      }
      if (!transaction.user || transaction.user.id !== activeUser?.id) {
        try {
          await updateTransactionForProduct(transaction.code);
        } catch {
          toast.error(
            "An error occurred while processing your payment. Please review you payment information and try again.",
          );
        }
      }

      const redirectUrl = getPaymentRedirectURL(selectedFinancialMethod, {
        transaction_id: `${transaction.id}`,
        transaction_code: transaction.code,
        project_title: urlEncodedProjectTitle,
        scheduled_project_id: `${scheduledProjectIdForHandoff}`,
      });

      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message ?? errorMessages.default);
      }
      const clientSecret = transaction.stripe_session_id;
      if (!clientSecret) {
        throw new Error(errorMessages.default);
      }
      // Use the clientSecret and Elements instance to confirm the setup
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: redirectUrl,
          receipt_email: email,
        },
        redirect: "if_required",
      });

      if (error) {
        throw new Error(error.message ?? errorMessages.default);
      }
      // Not sure why this is here. how do we handle cashapp on mobile?
      // if (selectedFinancialMethod === FinancialMethod.CASH_APP && isMobile) {
      //   return;
      // }
      if (isRevisionPurchase) {
        emitAnalyticsTrackingEvent(
          TRACKING_EVENTS_NAME.MARK_PAID_REVISION,
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );
        dispatch(
          markRevisionTransactionPaid({
            transaction_id: transaction.id,
          }),
        )
          .unwrap()
          .then((project) => {
            dispatch(updateProjectCounts(project));
          })
          .catch((error) => {
            if (error instanceof Error) {
              toast.error(error.message);
            }
          });
      } else if (isSessionExtensionPurchase) {
        emitAnalyticsTrackingEvent(
          TRACKING_EVENTS_NAME.MARK_PAID_SESSION_EXTENSION,
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );
        try {
          await dispatch(
            markRecordingSessionExtensionPaid({
              transaction_id: transaction.id,
            }),
          ).unwrap();
          toast.success(
            "You have requested a extension. The involved clients will be notified and will need to approve the request.",
          );
        } catch {
          toast.error(
            "Something went wrong. Please reach out to customer support.",
          );
        }
      } else {
        emitAnalyticsTrackingEvent(
          TRACKING_EVENTS_NAME.MARK_PAID_SCHEDULED_PROJECT,
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );

        await dispatch(
          markTransactionPaid({
            transaction_id: transaction.id,
            title: projectTitle,
            artist_name: artistName,
            booked_with_purchase_order: false,
            financial_method: selectedFinancialMethod,
          }),
        ).unwrap();
        await invalidateOnboardingProgress();
      }
    }, [
      stripe,
      elements,
      isValidEmail,
      isAuthenticated,
      accountVerified,
      transaction.user,
      transaction.id,
      transaction.code,
      transaction.stripe_session_id,
      transaction.total_price,
      activeUser?.id,
      selectedFinancialMethod,
      urlEncodedProjectTitle,
      scheduledProjectIdForHandoff,
      email,
      isRevisionPurchase,
      isSessionExtensionPurchase,
      isAandREmail,
      enteredUMGEmail,
      handleAandRAuth,
      handleUMGAuth,
      updateTransactionForProduct,
      errorMessages.default,
      user?.id,
      dispatch,
      projectTitle,
      artistName,
      invalidateOnboardingProgress,
    ]);

    useEffect(() => {
      if (showOTPAuth && accountVerified) {
        setParentLoadingState?.(true);
        setShowOtpAuth(false);
        void handleSubmit();
      }
    }, [showOTPAuth, accountVerified, handleSubmit, setParentLoadingState]);

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    if (!transaction.stripe_session_id) {
      return null;
    }
    if (transaction.financial_method === FinancialMethod.PAYPAL) {
      return null;
    }

    return (
      <Grid2
        // Hides the StripeForm in the Mobile Drawer without removing it from the DOM
        {...(showOTPAuth && { height: "240px", overflow: "hidden" })}
        position="relative"
      >
        <Box
          height={"max-content"}
          width={"99%"}
          mx={"auto"}
          display="flex"
          flexDirection="column"
          gap={(theme) => theme.spacing(2)}
        >
          <LinkAuthenticationElement
            options={{
              defaultValues: {
                email: email,
              },
            }}
            onChange={(e) => {
              if (e.complete) {
                const currentEmail = e.value.email;
                setIsValidEmail(checkIfValidEmail(currentEmail));
                setEmail(currentEmail);
              }
            }}
          />
          <CardSection
            handlePaymentTypeChange={(updatedFinancialMethod) => {
              setSelectedFinancialMethod(updatedFinancialMethod);
            }}
          />
          <AddressElement
            options={{
              mode: "billing",
              fields: {
                phone: "always",
              },
              defaultValues: {
                phone: phone,
              },
              validation: {
                phone: {
                  required: "always",
                },
              },
            }}
            onChange={(e) => {
              if (e.complete) {
                const currentEnteredPhone = e.value.phone;
                if (currentEnteredPhone) setPhone(currentEnteredPhone);
              }
            }}
          />
        </Box>
        {showOTPAuth && (
          <Box
            sx={(theme) => ({
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              background: theme.palette.background.default,
            })}
          >
            <GuestLoginAuth
              initialPhoneNumber={phone}
              initialEmail={email}
              transactionCode={transaction.code}
              cancel={() => {
                setParentLoadingState?.(false);
                setShowOtpAuth(false);
              }}
            />
          </Box>
        )}
      </Grid2>
    );
  },
);

StripePaymentForm.displayName = "StripePaymentForm";
