import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { assertEngineEarsErrorType } from "../../api/helpers";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import pathnameAtom from "../../atoms/location/pathnameAtom";
import { AuthFlows } from "../../constants/authSteps";
import { ROUTE_PREFIXES } from "../../routes";
import { SCREENS } from "../../routes/screens";
import { useAppSelector } from "../../store/hooks";
import {
  authModalOpenAtom,
  currentFlowAtom,
} from "../../stories/components/Auth/atoms";
import { isUmgPortalOpenAtom } from "../../stories/components/SignInForm/SignInForm";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { loginRequest } from "../../utils/umgAuthConfig";
import { useMediaQueryBreakpoint } from "../useMediaQuery";
import { useCheckUserLoginFlow } from "./useCheckUserLoginFlow";
import useLoginUMGMutation from "./useLoginUMGMutation";

/**
 * Originally located in the `SignInForm` component.
 * This hook is used to authenticate the user after they are redirected back
 * to EngineEars from the UMG portal.
 */
export const useAuthenticateUMG = () => {
  const { instance, accounts, inProgress } = useMsal();
  const token = useAtomValue(tokenAtom);
  const pathname = useAtomValue(pathnameAtom);
  const isUmgAuthenticated = useIsAuthenticated();
  const [umgAccessToken, setUmgAccessToken] = useState("");
  const setIsUmgPortalOpen = useSetAtom(isUmgPortalOpenAtom);
  const { mutateAsync: loginUMGAsync } = useLoginUMGMutation();
  const { isMobile } = useMediaQueryBreakpoint();
  const history = useHistory();
  const { checkUserAndSetFlow } = useCheckUserLoginFlow();
  const setFlow = useSetAtom(currentFlowAtom);
  const { localUTMParams, user } = useAppSelector((state) => state.accountInfo);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);

  useEffect(() => {
    if (token) return;
    if (!isUmgAuthenticated) return;
    if (!accounts.length) return;
    if (inProgress !== InteractionStatus.None) return;
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setUmgAccessToken(response.accessToken);
        setIsUmgPortalOpen(false);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          void instance.acquireTokenRedirect(request);
        }
      });
  }, [isUmgAuthenticated, accounts, inProgress, token]);

  useEffect(() => {
    if (!umgAccessToken) return;
    void loginUMGAsync({ access_token: umgAccessToken }).then((response) => {
      if (!assertEngineEarsErrorType(response)) {
        emitAnalyticsTrackingEvent(
          "login_umg",
          {
            username: `${user?.username}`,
            ...localUTMParams,
          },
          response.user.id,
        );

        if (response.created) {
          setAuthModalOpen(true);
          if (isMobile) history.push(SCREENS.AUTH_SCREEN);
          setFlow(AuthFlows.AANDR);
        } else {
          if (pathname?.startsWith(ROUTE_PREFIXES.BOOKING + "/")) {
            return;
          }
          checkUserAndSetFlow(response.user);
        }
      }
    });
  }, [umgAccessToken]);
};
