import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadFilesFromUrlsWithDelay } from "../../utils/downloadFilesFromUrls";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { DOWNLOAD_FINAL_FILES } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { DownloadFilesResponse } from "./types";

export interface downloadFinalFilesParams {
  project_id: string;
  get_zip_only?: boolean;
  code?: string;
}

const downloadFinalFiles = createAsyncThunk(
  DOWNLOAD_FINAL_FILES,
  async (args: downloadFinalFilesParams, thunkAPI) => {
    let params = `?project_id=${args.project_id}`;
    params +=
      "get_zip_only" in args && args.get_zip_only
        ? `&get_zip_only=${args.get_zip_only}`
        : ``;
    params += "code" in args && args.code ? `&code=${args.code}` : ``;
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_FINAL_FILES,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default downloadFinalFiles;
