import { useSearchFilters } from "../../hooks/searchHooks/useSearchFilters";
import { initialState as initialUserSearchState } from "../actions/userSearch";
import { useAppSelector } from "../hooks";
import { AutoCompleteTypeEnum } from "../models/autocomplete";

export const useSelectUserSearchFilterApplied = () => {
  const { maxRate, minRate, genres, simpleBudgetsSelected, serviceTypes } =
    useSearchFilters();
  const { autocompleteSuggestions } = useAppSelector(
    (state) => state.userSearch,
  );
  const { autocompleteSuggestions: initialAutocompleteSuggestions } =
    initialUserSearchState;

  return (
    maxRate ||
    minRate ||
    serviceTypes.length > 0 ||
    genres.length > 0 ||
    simpleBudgetsSelected.length > 0 ||
    JSON.stringify(
      [...autocompleteSuggestions].sort((a, b) => a.type.localeCompare(b.type)),
    ) !==
      JSON.stringify(
        [...initialAutocompleteSuggestions].sort((a, b) =>
          a.type.localeCompare(b.type),
        ),
      )
  );
};

export const useSelectStudioRoomSearchFiltersApplied = () => {
  const {
    maxRate,
    minRate,
    simpleBudgetsSelected,
    amenities: selectedAmenities,
    daysAvailable,
    maxDistance,
    duration,
    allowNoEngineer,
  } = useSearchFilters();

  const { autocompleteSuggestions } = useAppSelector(
    (state) => state.userSearch,
  );
  const { autocompleteSuggestions: initialAutocompleteSuggestions } =
    initialUserSearchState;

  return (
    maxRate ||
    minRate ||
    simpleBudgetsSelected.length > 0 ||
    selectedAmenities.length > 0 ||
    daysAvailable.length > 0 ||
    maxDistance ||
    duration ||
    allowNoEngineer ||
    JSON.stringify(
      autocompleteSuggestions
        .filter(
          (suggestion) =>
            suggestion.type !== AutoCompleteTypeEnum.ARTIST_CREDIT,
        )
        .sort((a, b) => a.type.localeCompare(b.type)),
    ) !==
      JSON.stringify(
        initialAutocompleteSuggestions
          .filter(
            (suggestion) =>
              suggestion.type !== AutoCompleteTypeEnum.ARTIST_CREDIT,
          )
          .sort((a, b) => a.type.localeCompare(b.type)),
      )
  );
};
