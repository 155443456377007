import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { makeBackendGetCall } from "../../store/utils/fetch";
import { GET_USER_ASSET } from "../../store/utils/routes";
import queryString from "query-string";
import { ProjectType } from "../../store/models/project";
import { useDebounce } from "ahooks";
import { useAtomValue } from "jotai";
import { activeUserIdAtom } from "../../atoms/user/activeUserAtom";

export interface GeneratedAssetQueryProps {
  key?: string;
  studioId?: string;
  promoCodeId?: string;
  serviceType?: ProjectType;
  variant?: string;
}

export interface GeneratedAsset {
  file: File;
  downloadUrl: string;
}

const DEBOUNCE_WAIT = 100;

const useGeneratedAssetQuery = ({
  key,
  promoCodeId,
  studioId,
  serviceType,
  variant,
}: GeneratedAssetQueryProps) => {
  const loggedInUserId = useAtomValue(activeUserIdAtom);
  // We have to debounce these values or we will fire off requests prematurely from badly stateful components
  const debouncedKey = useDebounce(key, { wait: DEBOUNCE_WAIT });
  const debouncedStudioId = useDebounce(studioId, { wait: DEBOUNCE_WAIT });
  const debouncedPromoCodeId = useDebounce(promoCodeId, {
    wait: DEBOUNCE_WAIT,
  });
  const debouncedServiceType = useDebounce(serviceType, {
    wait: DEBOUNCE_WAIT,
  });
  const debouncedVariant = useDebounce(variant, { wait: DEBOUNCE_WAIT });
  const queryKey = [QUERY_KEYS.GET_USER_ASSET, debouncedKey!];
  const params: Record<string, unknown> = {
    type: key,
  };
  if (loggedInUserId) {
    queryKey.push(`user_id:${loggedInUserId}`);
  }
  if (debouncedStudioId) {
    queryKey.push(`studio_id:${debouncedStudioId}`);
    params.studio_id = debouncedStudioId;
  }
  if (debouncedServiceType) {
    queryKey.push(`service_type:${debouncedServiceType.toString()}`);
    params.service_type = debouncedServiceType;
  }
  if (debouncedPromoCodeId) {
    queryKey.push(`promo_code_id:${debouncedPromoCodeId.toString()}`);
    params.promo_code_id = debouncedPromoCodeId;
  }
  if (debouncedVariant) {
    queryKey.push(`variant:${debouncedVariant.toString()}`);
    params.variant = debouncedVariant;
  }
  return useQuery({
    queryKey,
    queryFn: async () => {
      return await makeBackendGetCall(
        GET_USER_ASSET,
        `?${queryString.stringify(params, { skipEmptyString: true, skipNull: true })}`,
      ).then(async (res) => {
        const blob = await res.blob();
        const mimetype =
          res.headers.get("content-type") || "application/octet-stream";
        if (mimetype === "text/html; charset=utf-8") {
          return Promise.reject();
        }
        const dispo = res.headers.get("content-disposition");
        const ext =
          mimetype === "application/x-zip-compressed"
            ? ".zip"
            : mimetype === "image/jpeg"
              ? ".jpg"
              : ".png";
        const name =
          dispo?.match(/.*filename="?([^"]*)"?/)?.[1] ||
          `engineears_download${ext}`;
        const file = new File([blob], name, { type: mimetype });
        const downloadUrl = window.URL.createObjectURL(file);
        return { file, downloadUrl } as GeneratedAsset;
      });
    },
    enabled: Boolean(debouncedKey),
    // We only want to get the image once if it exists to be polite to the backend
    retry: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    retryOnMount: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });
};

export default useGeneratedAssetQuery;
