import { useState } from "react";
import useGuestLoginMutation from "../../../hooks/authHooks/useGuestLoginMutation";
import { Box } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SignInForm } from "../SignInForm/SignInForm";
import useGetGuestVerificationCodeQuery from "../../../hooks/useGetGuestVerificationCodeQuery";
import {
  USER_AND_PHONE_NUMBER_MISMATCH,
  USER_SHOULD_SIGN_IN_WITH_EMAIL,
} from "../../../store/models/exceptions";
import useGetGuestVerificationCodeMutation from "../../../hooks/authHooks/useGetGuestVerificationCodeMutation";
import { toast } from "react-toastify";
import VerificationCodeInput from "./VerificationCodeInput";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { displayEngineEarsError } from "../../../api/helpers";

interface GuestLoginAuthProps {
  initialPhoneNumber?: string;
  initialEmail?: string;
  transactionCode: string;
  cancel?: () => void;
}

export const GuestLoginAuth = ({
  initialPhoneNumber = "",
  initialEmail = "",
  transactionCode,
  cancel,
}: GuestLoginAuthProps) => {
  const [email] = useState(initialEmail.length > 0 ? initialEmail : "");
  const { error, isFetching, data } = useGetGuestVerificationCodeQuery({
    phone_number: initialPhoneNumber,
    email: email,
  });
  const { mutateAsync: guestLoginAsync, isPending } = useGuestLoginMutation();

  const handleVerifyPhoneOrEmail = async (verificationCode: string) => {
    return guestLoginAsync({
      phone_number: initialPhoneNumber,
      email: email,
      verification_code: verificationCode,
      transaction_code: transactionCode,
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const {
    mutateAsync: getVerificationCodeAsync,
    isPending: isSendingVerificationCode,
  } = useGetGuestVerificationCodeMutation();

  const handleResendVerification = async () => {
    const emailToNotify = email.length > 0 ? email : undefined;
    const phoneNumberToNotify =
      initialPhoneNumber.length > 0 ? initialPhoneNumber : undefined;
    if (!emailToNotify && !phoneNumberToNotify) {
      toast.error(
        "Please enter a phone number or email address to receive a verification code",
      );
      close();
      return;
    }
    getVerificationCodeAsync({
      resend_for_user_id: data?.user_id,
    }).catch(() => {
      toast.error("Failed to resend verification code");
    });
  };

  if (isFetching) {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        p={2}
      >
        <SoundWaveLoader width={100} height={100} />
      </Box>
    );
  }

  if (error?.code && error.code === USER_SHOULD_SIGN_IN_WITH_EMAIL) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Text>Existing account found please sign in via Email.</Text>
        <SignInForm
          defaultValueEmail={initialEmail}
          hideGoogle={true}
          register={initialEmail?.length === 0}
          skipFlow={true}
        />
        <Button fullWidth variant={ButtonVariant.OUTLINED} onClick={cancel}>
          Cancel
        </Button>
      </Box>
    );
  }

  if (error?.code && cancel) {
    displayEngineEarsError(error);
    cancel();
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Text>{`Please enter the verification code sent to ${email} and ${initialPhoneNumber}`}</Text>
      <VerificationCodeInput
        codeLength={6}
        onComplete={handleVerifyPhoneOrEmail}
      />
      <Button
        loading={isPending}
        fullWidth
        variant={ButtonVariant.OUTLINED}
        onClick={cancel}
      >
        Update email or phone number
      </Button>
      <Button
        fullWidth
        disabled={isSendingVerificationCode}
        loading={isSendingVerificationCode}
        onClick={handleResendVerification}
      >
        Re-send verification code
      </Button>
    </Box>
  );
};
