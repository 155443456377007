import { ProjectById } from "../../../../store/models/project";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { CompletePaymentButton } from "../../InProgressTransitionView/components/CompletePaymentButton";
import { InProgressButtonRow } from "../../InProgressTransitionView/InProgressTransitionView.styles";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { CompleteTransitionViewIndex } from "../CompleteTransitionView";
import { ApproveAssetsButton } from "./ApproveAssetsButton";
import { RequestReuploadButton } from "./RequestReuploadButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";

export interface CompleteArtistButtonRowProps {
  transitionToDownloadFiles: () => void;
  transitionToMainView: () => void;
  project: ProjectById;
  isAdminOrSecondaryEngineer: boolean;
  isDeliverablesUploaded: boolean;
  isPendingBudgetApproval: boolean;
  isPendingBillingInfo: boolean;
  code?: string;
  isPartiallyPaid?: boolean;
  allowFileDownload?: boolean;
  isCompletePaymentPopoverOpen: boolean;
  setIsCompletePaymentPopoverOpen: (status: boolean) => void;
  closeCompletePaymentPopover: () => void;
  outstandingBalance?: number;
  viewIndex: CompleteTransitionViewIndex;
}

export const CompleteArtistButtonRow = ({
  transitionToDownloadFiles,
  project,
  isDeliverablesUploaded,
  transitionToMainView,
  isAdminOrSecondaryEngineer,
  isPendingBudgetApproval,
  isPendingBillingInfo,
  code,
  isCompletePaymentPopoverOpen,
  setIsCompletePaymentPopoverOpen,
  closeCompletePaymentPopover,
  isPartiallyPaid = false,
  allowFileDownload = true,
  outstandingBalance,
  viewIndex,
}: CompleteArtistButtonRowProps) => {
  const assetsApproved = Boolean(project.assets_approved);
  const isDownloadFilesView =
    viewIndex === CompleteTransitionViewIndex.DOWNLOAD_FILES;

  const renderMainButton = () => {
    if (isDownloadFilesView) {
      if (
        !assetsApproved &&
        !isPendingBudgetApproval &&
        !isPendingBillingInfo
      ) {
        return (
          <ApproveAssetsButton
            isDeliverablesUploaded={isDeliverablesUploaded}
            projectId={project.id}
          />
        );
      }

      return (
        <Button
          onClick={transitionToMainView}
          fullWidth
          variant={ButtonVariant.OUTLINED}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: "0.5rem" }}
          />
          Back
        </Button>
      );
    }

    return allowFileDownload ? (
      <Button
        onClick={() => {
          if (isPendingBudgetApproval) {
            toast.error(
              "Budget must be approved before downloading final assets",
            );
            return;
          }
          if (isPendingBillingInfo) {
            toast.error(
              "Billing information must be provided before downloading final assets",
            );
            return;
          }
          transitionToDownloadFiles();
        }}
        fullWidth
        variant={
          isPendingBudgetApproval || isPendingBillingInfo
            ? ButtonVariant.DISABLED
            : ButtonVariant.PRIMARY
        }
      >
        Download project files
      </Button>
    ) : (
      <CompletePaymentButton
        project={project}
        code={code}
        isPartiallyPaid={isPartiallyPaid}
        isCompletePaymentPopoverOpen={isCompletePaymentPopoverOpen}
        setIsCompletePaymentPopoverOpen={setIsCompletePaymentPopoverOpen}
        closeCompletePaymentPopover={closeCompletePaymentPopover}
        outstandingBalance={outstandingBalance}
      />
    );
  };

  return (
    <MainProjectWorkflowButtonRow>
      <InProgressButtonRow>
        {!isAdminOrSecondaryEngineer && (
          <RequestReuploadButton
            disabled={
              isPendingBillingInfo ||
              isPendingBudgetApproval ||
              !allowFileDownload
            }
            transitionView={transitionToMainView}
            isDeliverablesUploaded={isDeliverablesUploaded}
            project={project}
          />
        )}
        {renderMainButton()}
      </InProgressButtonRow>
    </MainProjectWorkflowButtonRow>
  );
};
