import { PromoCode } from "../../store/models/promoCode";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { PROMO_CODE_API } from "../../store/utils/routes";

export interface CreatePromoCodeArgs {
  code: string;
  discount_percentage: number;
  single_use_per_user: boolean;
  expires_at?: string;
  studio_id?: number;
}

export const createPromoCode = async (args: CreatePromoCodeArgs) => {
  const response = await makeBackendPostCallWithJsonResponse<PromoCode>(
    PROMO_CODE_API,
    args,
  );

  if (response.success) {
    return response.resultJson;
  }
  throw response.resultJson;
};

export default createPromoCode;
