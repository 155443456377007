import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadFilesFromUrlsWithDelay } from "../../utils/downloadFilesFromUrls";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { getProjectFilesRoute } from "../../utils/routeGetters";
import { GET_PROJECT_FILES } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { DownloadFilesResponse } from "./types";

interface downloadFilesByIdParams {
  project_id: number;
  file_version_ids: number[];
  code?: string;
}

/**
 * Allows collaborator to download files for a single project based on the file IDs
 */
const downloadFilesById = createAsyncThunk(
  GET_PROJECT_FILES,
  async (
    { project_id, file_version_ids, code }: downloadFilesByIdParams,
    thunkAPI,
  ) => {
    const projectFilesRoute = getProjectFilesRoute(
      project_id,
      file_version_ids,
      code,
    );

    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        projectFilesRoute,
        "",
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default downloadFilesById;
