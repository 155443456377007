import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryString from "query-string";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../api/helpers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { Equipment, EquipmentTypeEnum } from "../../store/models/equipment";
import {
  makeBackendDeleteCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../../store/utils/fetch";
import { USER_EQUIPMENT } from "../../store/utils/routes";

interface DeleteEquipmentParams {
  equipment_id: number;
  deleted: boolean;
  association_type: string;
  studio_room_id?: number;
}

interface PostEquipmentParams {
  label: string;
  equipment_type: EquipmentTypeEnum;
  association_type: string;
  studio_room_id?: number;
}

export enum AssociationType {
  STUDIO = "STUDIO",
  USER = "USER",
}

export const usePostEquipment = (userId?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (arg: PostEquipmentParams) => {
      return await makeBackendPostCallWithJsonResponse<Equipment>(
        USER_EQUIPMENT,
        arg,
      );
    },
    onSuccess: (response) => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_USER_EQUIPMENT, userId],
      });

      return response.resultJson;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
    },
  });
};

export const useDeleteEquipment = (userId?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (arg: DeleteEquipmentParams) => {
      const queryParams = queryString.stringify({
        association_type: arg.association_type,
        studio_room_id: arg.studio_room_id,
      });
      return await makeBackendDeleteCallWithJsonResponse<Equipment>(
        USER_EQUIPMENT.concat(`${arg.equipment_id}?${queryParams}`),
      );
    },
    onSuccess: (response) => {
      if (assertEngineEarsErrorType(response)) {
        return Promise.reject(response);
      }

      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_USER_EQUIPMENT, userId],
      });
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
    },
  });
};
