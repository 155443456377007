import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadFilesFromUrlsWithDelay } from "../../utils/downloadFilesFromUrls";
import { makeBackendGetCallWithJsonResponse } from "../../utils/fetch";
import { DOWNLOAD_REVIEW_TRACKS } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { DownloadFilesResponse } from "./types";

interface downloadReviewTracksParams {
  project_id: number;
  code?: string | null;
}

const downloadReviewTracks = createAsyncThunk(
  DOWNLOAD_REVIEW_TRACKS,
  async (args: downloadReviewTracksParams, thunkAPI) => {
    let params = `?project_id=${args.project_id}`;
    if (args.code) {
      params += `&code=${args.code}`;
    }
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_REVIEW_TRACKS,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default downloadReviewTracks;
