import { throttle } from "lodash";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { default as WaveSurfer } from "wavesurfer.js";
import {
  setCurrentPosition,
  setFooterIsActive,
  setIsFooterPlaying,
  setIsFooterReady,
} from "../../store/actions/abPlayerStore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ColorPalette } from "../../stories/theme";
import { convertWaveformDurationToReadableTime } from "./waveformUtils";

export const FOOTER_WAVESURFER_REF_ID = "#waveform-footer-player";
export const DEFAULT_TIME = "0:00";

export const getFooterWaveformRef = () => {
  if (!window.surferidze) return null;
  return window.surferidze[FOOTER_WAVESURFER_REF_ID];
};

export const useGenerateWaveformForFooter = () => {
  const waveformRef = useRef<WaveSurfer | null>(null);
  const {
    url,
    currentPosition,
    isLooping,
    loopStartTime,
    loopEndTime,
    playOnLoad,
  } = useAppSelector((state) => state.abPlayerStore);
  const dispatch = useAppDispatch();
  const [duration, setDuration] = useState(DEFAULT_TIME);
  const currentSeekTime = useRef<string>(DEFAULT_TIME);
  const isLoopingRef = useRef(isLooping);
  const loopStartTimeRef = useRef(loopStartTime);
  const loopEndTimeRef = useRef(loopEndTime);

  useEffect(() => {
    isLoopingRef.current = isLooping;
    loopStartTimeRef.current = loopStartTime;
    loopEndTimeRef.current = loopEndTime;
  }, [isLooping, loopEndTime, loopStartTime]);

  const throttledHandleAudioProcess = throttle((position: number) => {
    if (!waveformRef.current) return;
    const currentTime = waveformRef.current?.getCurrentTime();
    if (
      isLoopingRef.current &&
      loopStartTimeRef.current &&
      loopEndTimeRef.current &&
      (position < loopStartTimeRef.current || position > loopEndTimeRef.current)
    ) {
      const duration = waveformRef.current.getDuration();
      const seekToValue = loopStartTimeRef.current / duration;
      if (seekToValue < 0 || !seekToValue) return;
      waveformRef.current?.seekTo(seekToValue);
      return;
    }
    if (waveformRef.current?.isPlaying()) {
      dispatch(setCurrentPosition(position));
      currentSeekTime.current =
        convertWaveformDurationToReadableTime(currentTime);
    }
  }, 100);

  useLayoutEffect(() => {
    if (!url) return;
    const waveFormDiv = document.getElementById(FOOTER_WAVESURFER_REF_ID);
    if (!waveFormDiv) return;

    const generateWaveform = async () => {
      dispatch(setIsFooterReady(false));
      if (window.surferidze) {
        delete window.surferidze[FOOTER_WAVESURFER_REF_ID];
      }

      if (waveformRef.current) {
        waveformRef.current.destroy();
      }
      currentSeekTime.current = DEFAULT_TIME;
      const audio = new Audio(url);
      audio.currentTime = currentPosition;
      audio.preload = "metadata";
      waveformRef.current = WaveSurfer.create({
        container: waveFormDiv,
        waveColor: ColorPalette.Gray400,
        progressColor: ColorPalette.BoomyOrange400,
        height: 50,
        cursorWidth: 0,
        autoplay: playOnLoad,
        media: audio,
      });
      if (!waveformRef.current) return;
      if (!window.surferidze) {
        window.surferidze = {};
      }
      window.surferidze[FOOTER_WAVESURFER_REF_ID] = waveformRef.current;
      waveformRef.current.once("ready", async (duration) => {
        setDuration(convertWaveformDurationToReadableTime(duration));
        dispatch(setFooterIsActive(true));
        // If somehow the audio isn't played after the audio has been downloaded (Safari on iOS)
        // We manually trigger the audio to play
        if (playOnLoad && !waveformRef.current?.isPlaying()) {
          try {
            await waveformRef.current?.play();
            dispatch(setIsFooterPlaying(true));
            // In the event that it takes too long to download the audio, Safari's anti-autoplay strategy takes place
            // In such cases, there is nothing we can do but to wait for the user to interact with the website
            // so that the audio could be played
          } catch (error) {
            window.addEventListener("click", async () => {
              try {
                await waveformRef.current?.play();
                // By now, if the audio is still not played, something must be wrong!
              } catch (error) {
                toast.error(
                  "Something went wrong! Please contact us for support!",
                );
              }
            });
          }
        }
      });

      waveformRef.current.on("audioprocess", throttledHandleAudioProcess);
      waveformRef.current.on("play", () => {
        dispatch(setIsFooterPlaying(true));
      });
      waveformRef.current.on("pause", () => {
        dispatch(setIsFooterPlaying(false));
      });
      waveformRef.current.on("click", (position) => {
        dispatch(setCurrentPosition(position));
      });
      waveformRef.current.on("destroy", () => {
        waveformRef.current?.unAll();
      });
    };
    void generateWaveform();
    return () => waveformRef.current?.destroy();
  }, [dispatch, url]);

  return {
    waveformRef,
    currentSeekTime,
    duration,
  };
};
