"use client";

console.warn(
  "⚠️ Avoid importing components from 'index.js' for better performance. This practice is deprecated and may be removed in the future.",
);

export * as _Builtin from "./_Builtin";
export * from "./devlinkContext";
export * from "./interactions";
export * from "./utils";
export * from "./devlink";
export * from "./2024CommunityHeader";
export * from "./CommunityNavbar";
export * from "./CommunityNavigator";
export * from "./DevLinkProvider";
export * from "./EngineEarsFooter2024";
export * from "./FaqListings";
export * from "./Footer";
export * from "./Header";
export * from "./StudiosHeader";
