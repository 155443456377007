import { useEffect, useMemo, useRef } from "react";
import { useSessionHasPendingModificationRequest } from "../../../../../hooks/recordingSessionHooks/useSessionHasPendingModificationRequest";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";
import {
  BookingRequestStepText,
  MasteringProjectWorkflowStepText,
  MixingProjectWorkflowStepText,
  ProjectType,
  ProjectWorkflowSteps,
  ProjectWorkflowStepText,
  ProjectWorkflowStepTextType,
} from "../../../../../store/models/project";
import {
  RecordingSession,
  SessionCancellationWorkflowStepText,
  SessionModificationWorkflowStepText,
  SessionProjectWorkflowStepText,
  SessionWorkflowSteps,
  SessionWorkflowStepText,
} from "../../../../../store/models/recordingSession";
import { ScheduledProject } from "../../../../../store/models/scheduledproject";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { ProjectChatButton } from "../../../ChatModal/ProjectChatButton";
import { ProjectWorkflowPanelVariant } from "../../ProjectWorkflowPanel";
import { ProjectStepperWrapper } from "../../ProjectWorkflowPanel.styles";
import {
  ProjectWorkflowStage,
  ProjectWorkflowStageVariant,
} from "./ProjectWorkflowStage";
import {
  ProjectWorkflowStagesRow,
  stageContainerWidth,
} from "./ProjectWorkflowStepper.styles";

export interface ProjectWorkflowStepperProps {
  isCancelledSession?: boolean;
  isProjectComplete?: boolean;
  isRefunded?: boolean;
  projectId?: number | null | undefined;
  projectType: ProjectType;
  recordingSession?: RecordingSession | null;
  recordingSessionBookingDetails?: Pick<
    RecordingSession,
    "recording_session_request_id" | "users"
  > | null;
  scheduledProject?: ScheduledProject | null;
  variant?: ProjectWorkflowPanelVariant;
  workflowStep: ProjectWorkflowSteps | SessionWorkflowSteps;
}

export const ProjectWorkflowStepper = ({
  isCancelledSession = false,
  isProjectComplete = false,
  isRefunded = false,
  projectId,
  projectType,
  recordingSession,
  recordingSessionBookingDetails,
  scheduledProject,
  variant,
  workflowStep,
}: ProjectWorkflowStepperProps) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const sessionModificationPending = useSessionHasPendingModificationRequest(
    recordingSession || null,
  );

  // get the text object for each stage based on the type of project
  const projectStageText = useMemo(() => {
    if (variant === ProjectWorkflowPanelVariant.BOOKING_REQUESTED) {
      return BookingRequestStepText;
    }
    if (sessionModificationPending) {
      return SessionModificationWorkflowStepText;
    }
    if (isCancelledSession) {
      return SessionCancellationWorkflowStepText;
    }
    switch (projectType) {
      case ProjectType.RECORDING:
        return SessionWorkflowStepText;
      case ProjectType.MASTERING:
        return MasteringProjectWorkflowStepText;
      case ProjectType.MIXING:
      default:
        return MixingProjectWorkflowStepText;
    }
  }, [projectType, variant, sessionModificationPending]);

  const title = useMemo(() => {
    if (isCancelledSession) {
      return SessionProjectWorkflowStepText.SESSION_CANCELLED;
    }
    if (isRefunded) {
      return ProjectWorkflowStepText.CANCELLED;
    }
    if (sessionModificationPending) {
      return SessionProjectWorkflowStepText.SESSION_MODIFICATION;
    }
    if (
      (scheduledProject &&
        !scheduledProject?.accepted &&
        scheduledProject?.users?.length > 1) || // project is a Generated Booking if there is only one user
      (recordingSession && recordingSession?.pending_booking_acceptance) ||
      variant === ProjectWorkflowPanelVariant.BOOKING_REQUESTED
    ) {
      return ProjectWorkflowStepText.BOOKING_REQUESTED;
    }
    if (variant === ProjectWorkflowPanelVariant.SESSION_REQUESTED) {
      return SessionProjectWorkflowStepText.SESSION_UPCOMING;
    }
    if (projectType === ProjectType.RECORDING) {
      return SessionWorkflowStepText[workflowStep as SessionWorkflowSteps];
    }
    return (projectStageText as ProjectWorkflowStepTextType)[
      workflowStep as ProjectWorkflowSteps
    ];
  }, [
    isCancelledSession,
    isRefunded,
    sessionModificationPending,
    scheduledProject,
    recordingSession,
    variant,
    projectType,
    projectStageText,
    workflowStep,
  ]);

  const projectStageTexts:
    | SessionProjectWorkflowStepText[]
    | ProjectWorkflowStepText[] = Object.values(projectStageText);

  const scrollToEndSteps = [
    ProjectWorkflowSteps.MIX_FINISHED,
    ProjectWorkflowSteps.MIX_REVIEW,
    SessionWorkflowSteps.SESSION_COMPLETE,
  ];

  const isMainFlow =
    variant === ProjectWorkflowPanelVariant.MAIN_FLOW ||
    variant === ProjectWorkflowPanelVariant.SESSION_MAIN_FLOW;

  useEffect(() => {
    if (
      scrollRef.current &&
      isMobile &&
      scrollToEndSteps.includes(workflowStep)
    ) {
      scrollRef.current.scrollLeft += scrollRef.current.scrollWidth;
    }
  }, [workflowStep, isMobile]);

  return (
    <div>
      <ProjectStepperWrapper>
        <Text variant={TextStyleVariant.H4}>{title}</Text>
        {(recordingSession ||
          scheduledProject ||
          recordingSessionBookingDetails) && (
          <ProjectChatButton
            projectId={String(projectId)}
            recordingSession={
              recordingSession || recordingSessionBookingDetails || undefined
            }
            scheduledProject={scheduledProject || undefined}
          />
        )}
      </ProjectStepperWrapper>
      <ProjectWorkflowStagesRow $selectedIndex={workflowStep} ref={scrollRef}>
        {projectStageTexts.map((text, index) => {
          const currentOrNextVariant =
            isMainFlow && index === workflowStep && !isRefunded
              ? ProjectWorkflowStageVariant.CURRENT
              : ProjectWorkflowStageVariant.NEXT;
          const cancelledSessionVariant: ProjectWorkflowStageVariant =
            isCancelledSession && index < workflowStep
              ? ProjectWorkflowStageVariant.INCOMPLETE
              : currentOrNextVariant;
          return (
            <ProjectWorkflowStage
              key={`workflow-stage-${index}`}
              hideSeparator={index === projectStageTexts.length - 1}
              text={text}
              variant={
                isCancelledSession
                  ? cancelledSessionVariant
                  : isProjectComplete || index < workflowStep
                    ? ProjectWorkflowStageVariant.COMPLETE
                    : currentOrNextVariant
              }
              width={stageContainerWidth[projectType]}
              isCancelledSession={isCancelledSession}
              modificationPending={sessionModificationPending}
            />
          );
        })}
      </ProjectWorkflowStagesRow>
    </div>
  );
};
