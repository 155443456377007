import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { storeAnonymousId } from "../../../store/actions/accountInfo";
import { applyPromoCode } from "../../../store/actions/marketing";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AutoCompleteTypeEnum } from "../../../store/models/autocomplete";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { SearchFilterContainer } from "../../components/SearchFilters/SearchFilters";
import { SearchResultsHOCScreen } from "../SearchResultHOCScreen/SearchResultsHOCScreen";

export interface SearchScreenProps {
  disableUserSearch: boolean;
  disableStudioSearch: boolean;
  children?: ReactNode;
  showQuickFilters?: boolean;
  mapView?: boolean;
  updateSearchView?: () => void;
  showResultsViewOptions?: boolean;
}

export const SearchScreen = ({
  children,
  disableStudioSearch,
  disableUserSearch,
  showQuickFilters = true,
  mapView = false,
  updateSearchView,
  showResultsViewOptions = true,
}: SearchScreenProps) => {
  const { search } = useLocation();
  const { anonymousId } = useAppSelector((state) => state.accountInfo);

  const [isSearchFilterVisible, setIsSearchFilterVisible] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const trackAnonymousId = useCallback(() => {
    if (window.analytics.user) {
      const segmentAnonymousId = window.analytics.user().anonymousId();
      dispatch(storeAnonymousId(segmentAnonymousId));
    }
  }, [dispatch]);

  const openFilterOptions = () => {
    emitAnalyticsTrackingEvent("open_search_filters", {});
    setIsSearchFilterVisible(true);
  };

  useEffect(() => {
    if (anonymousId) return;
    trackAnonymousId();
  }, [anonymousId, trackAnonymousId, dispatch]);

  const promoCode = useMemo(() => {
    return new URLSearchParams(search).get("promocode");
  }, [search]);

  useEffect(() => {
    emitAnalyticsTrackingEvent("search", {});
  }, []);

  useEffect(() => {
    if (promoCode) {
      void dispatch(
        applyPromoCode({
          promocode: promoCode,
        }),
      );
    }
  }, [dispatch, promoCode]);

  return (
    <>
      <SearchResultsHOCScreen
        placeholder={
          isMobile
            ? ""
            : !disableUserSearch && !disableStudioSearch
              ? "Search by name, location, verified credits and more"
              : !disableStudioSearch && disableUserSearch
                ? "Search by name, location, equipment and more"
                : "Search by name, location, verified credits and more"
        }
        autoCompleteTypeFilter={
          !disableUserSearch && disableStudioSearch
            ? AutoCompleteTypeEnum.USER
            : !disableStudioSearch && disableUserSearch
              ? AutoCompleteTypeEnum.STUDIO
              : undefined
        }
        showQuickFilters={showQuickFilters}
        toggleFilters={openFilterOptions}
        updateSearchView={updateSearchView}
        mapView={mapView}
        showResultsViewOptions={showResultsViewOptions}
      >
        {children}
      </SearchResultsHOCScreen>
      <SearchFilterContainer
        showStudioFilterOptions={!disableStudioSearch}
        showEngineerFilterOptions={!disableUserSearch}
        isVisible={isSearchFilterVisible}
        onClose={() => setIsSearchFilterVisible(false)}
        mapView={mapView}
      />
    </>
  );
};
