import styled from "styled-components";
import { Button } from "../../core-ui/components/Button/Button";

export const ProjectPanelHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const ProjectPanelArtistImage = styled.img`
  border-radius: 50%;
  height: 32px;
  object-fit: cover;
  width: 32px;
`;

export const ProjectPanelTrackHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const ProjectPanelTrackButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  min-width: fit-content;

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

export const ProjectPanelTrackButton = styled(Button)`
  font-size: ${({ theme }) => theme.textSizeXs};
  gap: 4px;
  min-height: 0;
`;

export const ProjectPanelSeparator = styled.hr<{ $margin?: string }>`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.separatorColor};
  margin: ${({ $margin }) => $margin ?? "12px 0"};
  width: 100%;
`;

export const ProjectPanelTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 12px;
  & a {
    text-decoration: none;
  }
`;

export const ProjectPanelTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;

export const ProjectPanelContentContainer = styled.div`
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
`;

export const ProjectStepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const WORKFLOW_SIDE_PANEL_PADDING = "24px";

export const ProjectWorkflowStickyFooter = styled.div<{
  $noSidePanel?: boolean;
  $navHeight?: number;
}>`
  position: sticky;
  bottom: ${({ $noSidePanel, $navHeight }) =>
    $noSidePanel ? `${$navHeight || 0}px` : `-${WORKFLOW_SIDE_PANEL_PADDING}`};
  left: ${({ $noSidePanel }) =>
    $noSidePanel ? 0 : `-${WORKFLOW_SIDE_PANEL_PADDING}`};
  z-index: ${({ theme, $noSidePanel }) =>
    $noSidePanel
      ? theme.zIndexes.selectDropdownMenuContent
      : theme.zIndexes.projectSidePanelContent};
`;
