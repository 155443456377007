import { useMemo } from "react";

export const useEnteredUMGEmail = (email: string) => {
  return useMemo(() => {
    const formattedEmail = email?.toLowerCase().trim() ?? "";
    return (
      formattedEmail.includes("@umusic.com") ||
      formattedEmail.includes("@umgconsult.com")
    );
  }, [email]);
};
