import queryString from "query-string";
import { TRACKING_EVENTS_NAME } from "../../../../constants/trackingEvents";
import { Genre } from "../../../../store/models/genres";
import { ProjectType } from "../../../../store/models/project";
import User from "../../../../store/models/user";
import { makeBackendGetCallWithJsonResponse } from "../../../../store/utils/fetch";
import { SEARCH_USER_API } from "../../../../store/utils/routes";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { promiseEngineEarsHandler } from "../../../helpers";

interface SearchUsersResponse {
  data: User[];
  page: number;
  num_pages: number;
  limit: number;
  count: number;
}

export type SearchEngineersParams = {
  service_types?: ProjectType[];
  simple_budget_selected?: string[];
  latitude?: number;
  longitude?: number;
  max_distance?: number;
  genres?: Genre[];
  up_and_coming_eng?: boolean;
  min_rate?: number;
  max_rate?: number;
  promocode?: string | null;
  page: number;
  search?: string;
  search_origin?: number;
  artist_credit?: string;
  location_string?: string;
  payment_deposits?: boolean;
  anonymous_id?: string;
};

const searchEngineers = async (
  searchEngineerParams: SearchEngineersParams,
  signal: AbortSignal,
) => {
  const params = queryString.stringify(searchEngineerParams, {
    arrayFormat: "comma",
    skipNull: true,
    skipEmptyString: true,
  });

  emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.USER_SEARCH, {
    query_params: params,
  });
  return makeBackendGetCallWithJsonResponse<SearchUsersResponse>(
    SEARCH_USER_API,
    params ? `?${params}` : "",
    signal ? { signal } : undefined,
  ).then(promiseEngineEarsHandler);
};

export default searchEngineers;
