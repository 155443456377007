import queryString from "query-string";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { AmenityType } from "../../../store/models/amenity";
import { StudioRoom } from "../../../store/models/studio";
import { WEEKDAYS } from "../../../store/models/workingHours";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { STUDIO_ROOM_SEARCH_API } from "../../../store/utils/routes";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { promiseEngineEarsHandler } from "../../helpers";

interface StudioRoomSearchResponse {
  data: StudioRoom[];
  page: number;
  num_pages: number;
  limit: number;
  count: number;
  local_studios_present: boolean;
}

export type SearchStudioRoomsParams = {
  page: number;
  allow_no_engineer?: boolean;
  simple_budget_selected?: string[];
  max_rate?: number;
  min_rate?: number;
  max_distance?: number;
  latitude?: number;
  longitude?: number;
  amenities?: AmenityType[];
  days_available?: WEEKDAYS[];
  duration?: number;
  search?: string;
  search_origin?: number;
  promocode?: string | null;
  payment_deposits?: boolean;
  anonymous_id?: string;
};

const searchStudioRooms = async (
  searchStudioRoomsParams: SearchStudioRoomsParams,
  signal: AbortSignal,
) => {
  const params = `${queryString.stringify(searchStudioRoomsParams, {
    arrayFormat: "comma",
    skipNull: true,
    skipEmptyString: true,
  })}`;

  emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.STUDIO_ROOM_SEARCH, {
    query_params: params,
  });
  return makeBackendGetCallWithJsonResponse<StudioRoomSearchResponse>(
    STUDIO_ROOM_SEARCH_API,
    params ? `?${params}` : "",
    signal ? { signal } : undefined,
  ).then(promiseEngineEarsHandler);
};

export default searchStudioRooms;
