import { SlimPrivateActiveUser } from "../store/models/user";

export const getPhoneFromActiveUser = (activeUser?: SlimPrivateActiveUser) => {
  if (!activeUser) {
    return "";
  }
  const phoneNumber = activeUser.phone_number;
  if (!phoneNumber) {
    return "";
  }
  if (!phoneNumber.phone_number) {
    return "";
  }
  return phoneNumber.phone_number;
};
