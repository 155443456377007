import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useResizeObserver } from "usehooks-ts";
import { useDebounceFn } from "ahooks";
import { TabsV2 } from "../../core-ui/components/TabsV2/TabsV2";
import { DEFAULT_TAB_WIDTH } from "../../core-ui/components/TabsV2/TabsV2.styles";

export interface ProjectOverviewTabsProps {
  contentContainer: React.RefObject<HTMLDivElement>;
  headers: (string | undefined)[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

export const ProjectOverviewTabs = ({
  contentContainer,
  headers,
  selectedTab,
  setSelectedTab,
}: ProjectOverviewTabsProps) => {
  const [tabWidth, setTabWidth] = useState(DEFAULT_TAB_WIDTH);
  const numberOfTabs = headers.length;

  const calculateTabWidth = useCallback(() => {
    if (!contentContainer.current) {
      return setTabWidth(DEFAULT_TAB_WIDTH);
    }
    const containerTabWidth =
      contentContainer.current.offsetWidth / numberOfTabs;

    setTabWidth(Math.min(containerTabWidth, DEFAULT_TAB_WIDTH));
  }, [numberOfTabs, contentContainer]);

  const { run: onResize } = useDebounceFn(calculateTabWidth, { wait: 100 });

  useResizeObserver({ ref: contentContainer, onResize });

  const headersMap: Record<string, ReactNode> = {};
  headers.forEach((header) => {
    if (header) {
      headersMap[header] = header;
    }
  });

  return (
    <TabsV2
      tabs={headersMap}
      onChange={(_, index) => setSelectedTab(index)}
      selectedTab={selectedTab}
      sx={{ maxWidth: "fit-content" }}
      TabsProps={{ allowScrollButtonsMobile: false }}
      TabProps={{
        sx: { width: tabWidth },
      }}
      TextProps={{
        sx: {
          textAlign: "center",
          whiteSpace: "normal !important",
        },
      }}
    />
  );
};
