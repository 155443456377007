import { unwrapResult } from "@reduxjs/toolkit";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import markFinalAssetsApproved from "../../../store/actions/projects/markFinalAssetsApproved";
import artistMasteringTransitions from "../../../store/actions/projects/artistMasteringTransitions";
import artistMixingTransitions from "../../../store/actions/projects/artistMixingTransitions";
import { toggleAssetReviewModal } from "../../../store/actions/userInterface";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectType,
} from "../../../store/models/project";
import { getServiceFromServiceType } from "../../../store/utils/serviceUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import "./AssetReviewModal.css";
import { AssetReviewModalContent } from "./AssetReviewModal.styles";

export enum AssetApprovalOptions {
  MARK_ASSET_APPROVAL,
  APPROVE_ASSETS,
  REJECT_ASSETS,
}

const assetReviewOptions: OptionType[] = [
  {
    label: "Select Option",
    value: AssetApprovalOptions.MARK_ASSET_APPROVAL,
  },
  {
    label: "Approve Assets",
    value: AssetApprovalOptions.APPROVE_ASSETS,
  },
  {
    label: "Request re-upload",
    value: AssetApprovalOptions.REJECT_ASSETS,
  },
];

export interface AssetReviewModalProps {
  code: string;
}

export const AssetReviewModal = ({ code }: AssetReviewModalProps) => {
  const dispatch = useAppDispatch();
  const assetReview = useAppSelector(
    (state) => state.userInterface.assetReview,
  );
  const [selected, setSelected] = useState(
    AssetApprovalOptions.MARK_ASSET_APPROVAL,
  );
  const [requestingReupload, setRequestingReupload] = useState<boolean>(false);
  const [qcApproverEmail, setQcApproverEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const value = event.target.value;
      setQcApproverEmail(value);
    },
    [],
  );

  const handleQCSubmission = useCallback(() => {
    if (selected === AssetApprovalOptions.APPROVE_ASSETS) {
      setLoading(true);
      dispatch(
        markFinalAssetsApproved({
          project_id: assetReview.project?.id,
          code: code,
          qc_approver_email: qcApproverEmail,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again.");
          setLoading(false);
        });
    } else if (selected === AssetApprovalOptions.REJECT_ASSETS) {
      setRequestingReupload(true);
    }
  }, [selected, assetReview.project, code, dispatch, qcApproverEmail]);

  const requestAssetReupload = () => {
    if (assetReview.project?.service_type === ProjectType.MASTERING) {
      dispatch(
        artistMasteringTransitions({
          project_id: `${assetReview.project?.id}`,
          transition: MasteringTransitions.REQUEST_ASSET_REUPLOAD,
          final_asset_file_notes: notes,
          code: code,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again.");
        });
    } else {
      dispatch(
        artistMixingTransitions({
          project_id: `${assetReview.project?.id}`,
          transition: MixingTransitions.REQUEST_ASSET_REUPLOAD,
          final_asset_file_notes: notes,
          code: code,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          toast.error("Something went wrong. Please try again.");
        });
    }
  };

  const assetReviewButtonLabel = useMemo(() => {
    switch (selected) {
      case AssetApprovalOptions.APPROVE_ASSETS:
        return "Approve Assets";
      case AssetApprovalOptions.REJECT_ASSETS:
        return "Request Reupload";
      default:
        return "Select Option";
    }
  }, [selected]);

  if (!assetReview.project) return null;
  return (
    <BaseModal
      loading={requestingReupload || loading}
      header={"Approve or reject final assets"}
      setOpen={() => {
        dispatch(
          toggleAssetReviewModal({
            showAssetReviewModal: false,
          }),
        );
      }}
      confirmButtonDisabled={
        selected === AssetApprovalOptions.MARK_ASSET_APPROVAL
      }
      confirmText={assetReviewButtonLabel}
      open={assetReview.showAssetReviewModal}
      onConfirm={
        selected === AssetApprovalOptions.APPROVE_ASSETS
          ? handleQCSubmission
          : requestAssetReupload
      }
      showModalFooter={true}
    >
      <AssetReviewModalContent>
        <Text variant={TextStyleVariant.H6}>
          {`Title: ${assetReview.project.title}`}
        </Text>
        <Text>
          {`Service: ${getServiceFromServiceType(assetReview.project.service_type)} project`}
        </Text>
        <DropdownSelector
          options={assetReviewOptions}
          value={assetReviewOptions.find((opt) => opt.value === selected)}
          onChange={(val) => {
            const option = val as OptionType;
            const { value } = option;
            setSelected(value as AssetApprovalOptions);
          }}
          placeholder={"Select Option"}
        />

        {selected === AssetApprovalOptions.APPROVE_ASSETS && (
          <>
            <Text>
              This will mark the project as fully complete with the files ready
              for archiving.
            </Text>
            <input
              value={qcApproverEmail}
              onChange={handleEmailChange}
              placeholder="QC Approver Email"
              type={"text"}
              className="asset-review-modal-input"
            />
          </>
        )}
        {selected === AssetApprovalOptions.REJECT_ASSETS && (
          <>
            <Text>
              Send a request to the engineer to re-upload final assets
            </Text>
            <EditableTextArea
              width={"100%"}
              placeholder="What would you like them to change about their upload? (required)"
              initialValue={""}
              editMode={true}
              onChange={setNotes}
            />
          </>
        )}
      </AssetReviewModalContent>
    </BaseModal>
  );
};
