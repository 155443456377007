import React, { useState } from "react";
import { toast } from "react-toastify";
import { workingHoursParam } from "../../../store/actions/workingHours";
import {
  convertAvailabilityToTimes,
  convertBinaryArrayToBinaryString,
  convertTimeToHour,
  FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE,
  fillInAvailability,
  fillInUnavailability,
  getDefaultWeekdayTimes,
  INVALID_AM_PM,
  NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  parseWeekdays,
  WEEKDAYS,
  WeekdayTime,
  WorkingHours,
} from "../../../store/models/workingHours";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { WeekdayPicker } from "../WeekdayPicker/WeekdayPicker";
import "./SelectWorkingHoursComponent.css";
import { useCreateWorkingHours } from "../../../hooks/profileScreenHooks/useCreateWorkingHours";
import { useGetWorkingHours } from "../../../hooks/profileScreenHooks/useGetWorkingHours";

export interface SelectWorkingHoursComponentPropsV1 {
  onSuccessfulSubmit?: () => void;
  setShowWorkingHoursSetup?: (show: boolean) => void;
  studioRoomId?: number;
  studioRoomName?: string;
}

export const SelectWorkingHoursComponentV1 = ({
  onSuccessfulSubmit,
  setShowWorkingHoursSetup,
  studioRoomId,
  studioRoomName,
}: SelectWorkingHoursComponentPropsV1) => {
  const [updatingAvailability, setUpdatingAvailability] = useState(false);
  const [weeklyWorkDates, setWeeklyWorkDates] = useState<WEEKDAYS[]>([
    WEEKDAYS.MONDAY,
    WEEKDAYS.TUESDAY,
    WEEKDAYS.WEDNESDAY,
    WEEKDAYS.THURSDAY,
    WEEKDAYS.FRIDAY,
    WEEKDAYS.SATURDAY,
    WEEKDAYS.SUNDAY,
  ]);
  const [weekdayTimes, setWeekdayTimes] = useState<Record<number, WeekdayTime>>(
    {
      ...getDefaultWeekdayTimes(),
    },
  );
  const handleWeekdayTimeChange = (
    day: number,
    times: {
      start: OptionType<number>;
      end: OptionType<number>;
      startAMPM: OptionType<number>;
      endAMPM: OptionType<number>;
    },
  ) => {
    setWeekdayTimes((prevTimes) => ({ ...prevTimes, [day]: times }));
  };

  const { isPending: isCreatingWorkingHours, mutateAsync: createWorkingHours } =
    useCreateWorkingHours();

  const handleCreateWorkingHours = async () => {
    const workingHours: workingHoursParam = {
      [WEEKDAYS.MONDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.TUESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.WEDNESDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.THURSDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.FRIDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SATURDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
      [WEEKDAYS.SUNDAY]: Array(NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY)
        .fill(FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE.toString())
        .join(""),
    };

    for (const weekday of weeklyWorkDates) {
      const { start, end, startAMPM, endAMPM } = weekdayTimes[weekday];
      const startHour = convertTimeToHour(start.value, startAMPM.value);
      const endHour = convertTimeToHour(end.value, endAMPM.value);
      if (startHour === INVALID_AM_PM || endHour === INVALID_AM_PM) {
        toast.error('Please select a valid start and end time."');
        return;
      }
      if (startHour < endHour) {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInUnavailability(startHour, endHour),
        );
      } else {
        workingHours[weekday] = convertBinaryArrayToBinaryString(
          fillInAvailability(endHour, startHour),
        );
      }
    }

    try {
      await createWorkingHours({
        working_hours: workingHours,
        studio_room_id: studioRoomId,
      });
      toast.success("Your availability preferences have been saved!");
      setUpdatingAvailability(false);
      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }
    } catch (error) {
      toast.error(
        "There was an error updating your availability. Please try again later.",
      );
      setUpdatingAvailability(false);
    }
  };

  const updateWeekdayTimes = (workingHours: WorkingHours[]) => {
    const weekdayTimes = getDefaultWeekdayTimes();

    workingHours.forEach((workingHour) => {
      const { day_of_week, availability } = workingHour;
      weekdayTimes[day_of_week] = convertAvailabilityToTimes(availability);
    });

    return weekdayTimes;
  };

  const { isFetching: isWorkingHoursLoading } = useGetWorkingHours({
    onSuccess: (result) => {
      if (result.length === 0) {
        return;
      }

      const workingDays = parseWeekdays(result);
      setWeeklyWorkDates(workingDays);
      setWeekdayTimes(updateWeekdayTimes(result));
    },
    staleTime: undefined,
    studio_room_id: studioRoomId,
  });

  if (isWorkingHoursLoading)
    return <SoundWaveLoader whiteLoader={false} width={120} height={20} />;

  return (
    <div>
      <p className="label-semi-bold mb-2">
        {studioRoomId
          ? `Select ${studioRoomName} Hours`
          : "Generally Available Recording Hours"}
      </p>
      <WeekdayPicker
        weekdaysInput={weeklyWorkDates}
        onChange={setWeeklyWorkDates}
        enableTimeSelection={true}
        weekdayTimes={weekdayTimes}
        onTimeChange={handleWeekdayTimeChange}
      />
      <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        {setShowWorkingHoursSetup && (
          <Button
            loading={updatingAvailability}
            onClick={() => setShowWorkingHoursSetup(false)}
            variant={ButtonVariant.OUTLINED}
            fullWidth
          >
            Go to Previous Step
          </Button>
        )}
        <Button
          disabled={weeklyWorkDates.length === 0}
          loading={updatingAvailability || isCreatingWorkingHours}
          onClick={handleCreateWorkingHours}
          variant={ButtonVariant.PRIMARY}
          fullWidth
        >
          Finish Setup
        </Button>
      </div>
    </div>
  );
};
