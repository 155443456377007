import { keepPreviousData, useQuery } from "@tanstack/react-query";
import searchEngineers, {
  SearchEngineersParams,
} from "../../api/accounts/user/engineer/searchEngineers";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { SearchOrigin } from "../../store/actions/userSearch";
import { useAppSelector } from "../../store/hooks";
import {
  applyArtistCreditToSearchParams,
  applyMaxDistanceToSearchParams,
  applyRateFiltersToSearchParams,
  omitKeys,
  QUERY_KEY_EXCLUDED_ENTRIES,
} from "../../utils/searchFiltersUtils";
import { useSearchFilters } from "../searchHooks/useSearchFilters";

interface useSearchUsersQueryParams {
  page?: number;
  enabled?: boolean;
  search: string;
  searchOrigin?: SearchOrigin;
}

const useSearchUsersQuery = ({
  page = 1,
  enabled = true,
  search,
  searchOrigin = SearchOrigin.EXPLORE_SCREEN,
}: useSearchUsersQueryParams) => {
  const { autocompleteSuggestions, latitude, longitude } = useAppSelector(
    (state) => state.userSearch,
  );
  const { anonymousId } = useAppSelector((state) => state.accountInfo);

  const {
    maxRate,
    minRate,
    simpleBudgetsSelected,
    serviceTypes,
    maxDistance,
    genres,
    upAndComingEngineer,
    promoCode,
    paymentDeposits,
  } = useSearchFilters();

  const searchEngineersParams: SearchEngineersParams = {
    page,
    up_and_coming_eng: upAndComingEngineer || undefined,
    promocode: promoCode,
    search,
    search_origin: searchOrigin,
    payment_deposits: paymentDeposits || undefined,
    anonymous_id: anonymousId,
    service_types: serviceTypes,
    genres,
  };

  applyArtistCreditToSearchParams(
    searchEngineersParams,
    autocompleteSuggestions,
  );

  applyRateFiltersToSearchParams(
    searchEngineersParams,
    maxRate,
    minRate,
    simpleBudgetsSelected,
  );

  applyMaxDistanceToSearchParams(
    searchEngineersParams,
    autocompleteSuggestions,
    maxDistance,
    latitude,
    longitude,
    true,
  );

  return useQuery({
    queryKey: [
      QUERY_KEYS.ENGINEER_SEARCH,
      omitKeys(searchEngineersParams, QUERY_KEY_EXCLUDED_ENTRIES),
    ],
    queryFn: ({ signal }) => searchEngineers(searchEngineersParams, signal),
    enabled,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};

export default useSearchUsersQuery;
