import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ArtistSuggestion,
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
  LocationSuggestion,
} from "../models/autocomplete";
import { Genre } from "../models/genres";
import { ProjectType } from "../models/project";

export enum SearchOrigin {
  UNDEFINED_SEARCH_ORIGIN,
  EXPLORE_SCREEN,
  ENGINEER_SEARCH_SCREEN,
  STUDIO_ROOM_SEARCH_SCREEN,
}

interface userSearchState {
  page: number;
  latitude: number;
  longitude: number;
  autocompleteSuggestions: AutocompleteSuggestion[];
  requestingLatLong: boolean;
}

interface SetAutoCompleteSuggestionsPayload {
  autocompleteSuggestion: AutocompleteSuggestion;
  genres: Genre[];
  serviceTypes: ProjectType[];
  updateGenresFilter: (genres: Genre[]) => void;
  updateServiceTypesFilter: (serviceTypes: ProjectType[]) => void;
}

export const initialState: userSearchState = {
  page: 1,
  latitude: 0,
  longitude: 0,
  autocompleteSuggestions: [],
  requestingLatLong: false,
};

const updateGenres = (
  genres: Genre[],
  index: number,
  allGenresIndex: number,
  genre: Genre,
) => {
  if (index === -1) {
    // If "All" is selected as a genre filter, remove it
    if (allGenresIndex >= 0) {
      genres.splice(allGenresIndex, 1);
    }
    genres.push(genre);
  } else {
    genres.splice(index, 1);
    if (genres.length === 0) {
      // Add "All" as a selected genre filter if no other filters active
      genres.push(Genre.NO_GENRE);
    }
  }
};

const updateServiceTypes = (
  serviceTypes: ProjectType[],
  index: number,
  allServiceTypesIndex: number,
  projectType: ProjectType,
) => {
  if (index === -1) {
    // If "All" is selected as a service type filter, remove it
    if (allServiceTypesIndex >= 0) {
      serviceTypes.splice(allServiceTypesIndex, 1);
    }
    serviceTypes.push(projectType);
  } else {
    serviceTypes.splice(index, 1);
    if (serviceTypes.length === 0) {
      // Add "All" as a selected service type filter if no other filters active
      serviceTypes.push(ProjectType.NO_TYPE);
    }
  }
};

const userSearchSlice = createSlice({
  name: "userSearch",
  initialState,
  reducers: {
    toggleRequestingLatLong: (state) => {
      state.requestingLatLong = !state.requestingLatLong;
    },
    requestLatLong: (state) => {
      state.requestingLatLong = true;
    },
    updateUserSearchPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    removeAutoCompleteSuggestions: (
      state,
      action: PayloadAction<AutocompleteSuggestion>,
    ) => {
      state.page = 1;
      const suggestion = action.payload;
      switch (suggestion.type) {
        case AutoCompleteTypeEnum.ARTIST_CREDIT:
          const artistIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) =>
              suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
          );
          if (artistIndex !== -1) {
            state.autocompleteSuggestions.splice(artistIndex, 1);
          }
          break;
        case AutoCompleteTypeEnum.LOCATION:
          const locationIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
          );
          if (locationIndex !== -1) {
            state.autocompleteSuggestions.splice(locationIndex, 1);
          }
          break;
        default:
          break;
      }
    },
    setAutoCompleteSuggestions: (
      state,
      action: PayloadAction<SetAutoCompleteSuggestionsPayload>,
    ) => {
      state.page = 1;
      const {
        genres,
        serviceTypes,
        autocompleteSuggestion: suggestion,
        updateGenresFilter,
        updateServiceTypesFilter,
      } = action.payload;
      switch (suggestion.type) {
        case AutoCompleteTypeEnum.ARTIST_CREDIT:
          const artistSuggestions = suggestion;
          const artistIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) =>
              suggestion.type === AutoCompleteTypeEnum.ARTIST_CREDIT,
          );
          if (artistIndex === -1) {
            state.autocompleteSuggestions.push(artistSuggestions);
          } else {
            const currentArtist = state.autocompleteSuggestions[
              artistIndex
            ] as ArtistSuggestion;
            if (currentArtist.label === artistSuggestions.label) {
              state.autocompleteSuggestions.splice(artistIndex, 1);
            } else {
              state.autocompleteSuggestions.splice(artistIndex, 1);
              state.autocompleteSuggestions.push(artistSuggestions);
            }
          }
          break;
        case AutoCompleteTypeEnum.LOCATION:
          const locationSuggestions = suggestion;
          const locationIndex = state.autocompleteSuggestions.findIndex(
            (suggestion) => suggestion.type === AutoCompleteTypeEnum.LOCATION,
          );
          if (locationIndex === -1) {
            state.autocompleteSuggestions.push(locationSuggestions);
          } else {
            const currentLocation = state.autocompleteSuggestions[
              locationIndex
            ] as LocationSuggestion;
            if (
              currentLocation.latitude === locationSuggestions.latitude &&
              currentLocation.longitude === locationSuggestions.longitude
            ) {
              state.autocompleteSuggestions.splice(locationIndex, 1);
            } else {
              state.autocompleteSuggestions.splice(locationIndex, 1);
              state.autocompleteSuggestions.push(locationSuggestions);
            }
          }
          break;
        case AutoCompleteTypeEnum.GENRE:
          const genreSuggestions = suggestion;
          const genreIndex = genres.findIndex(
            (genre) => genre === genreSuggestions.value,
          );
          const allGenresIndex = genres.findIndex(
            (genre) => genre === Genre.NO_GENRE,
          );
          const genresCopy = [...genres];
          updateGenres(
            genresCopy,
            genreIndex,
            allGenresIndex,
            genreSuggestions.value,
          );
          updateGenresFilter(genresCopy);
          break;
        case AutoCompleteTypeEnum.SERVICE_TYPE:
          const serviceTypeSuggestions = suggestion;
          const allServiceTypesIndex = serviceTypes.findIndex(
            (serviceType) => serviceType === ProjectType.NO_TYPE,
          );

          let serviceTypesCopy = [...serviceTypes];
          switch (serviceTypeSuggestions.value) {
            case ProjectType.NO_TYPE:
              serviceTypesCopy = [ProjectType.NO_TYPE];
              break;
            case ProjectType.MIXING:
              const mixingIndex = serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.MIXING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                mixingIndex,
                allServiceTypesIndex,
                ProjectType.MIXING,
              );
              break;
            case ProjectType.MASTERING:
              const masteringIndex = serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.MASTERING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                masteringIndex,
                allServiceTypesIndex,
                ProjectType.MASTERING,
              );
              break;
            case ProjectType.TWO_TRACK_MIXING:
              const twoTrackMixingIndex = serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.TWO_TRACK_MIXING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                twoTrackMixingIndex,
                allServiceTypesIndex,
                ProjectType.TWO_TRACK_MIXING,
              );
              break;
            case ProjectType.ATMOS_MIXING:
              const atmosMixingIndex = serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.ATMOS_MIXING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                atmosMixingIndex,
                allServiceTypesIndex,
                ProjectType.ATMOS_MIXING,
              );
              break;
            case ProjectType.RECORDING:
              const recordingIndex = serviceTypes.findIndex(
                (serviceType) => serviceType === ProjectType.RECORDING,
              );
              updateServiceTypes(
                serviceTypesCopy,
                recordingIndex,
                allServiceTypesIndex,
                ProjectType.RECORDING,
              );
              break;
            default:
              break;
          }
          updateServiceTypesFilter(serviceTypesCopy);
          break;
        default:
          break;
      }
    },
    setUserLatLngForUserSearch: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.page = 1;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
  },
});

export default userSearchSlice.reducer;
export const {
  setUserLatLngForUserSearch,
  setAutoCompleteSuggestions,
  removeAutoCompleteSuggestions,
  updateUserSearchPage,
  toggleRequestingLatLong,
  requestLatLong,
} = userSearchSlice.actions;
