import queryString from "query-string";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { browserName } from "react-device-detect";
import type { AppDispatch, QueryParamsType } from "../";
import downloadTrackByPath from "../../api/files/audio";
import { Alt } from "../models/alts";
import { FileVersion, SonicMatchUpload } from "../models/fileVersion";
import { makeBackendGetCallWithJsonResponse, readStream } from "../utils/fetch";
import {
  ARTIST_SIGNED,
  ENGINEER_SIGNED,
  FETCH_TRACK,
  FETCH_TRACK_SNIPPET,
  LIST_REFERENCE_DETAILS,
  SONIC_MATCH_SIGNED,
} from "../utils/routes";
import { Error, receiveErrors } from "./errorStore";
import { loadProjectParams } from "./projects/types";

export interface signedURLParams {
  file: File;
  project_id: string;
  reference?: boolean;
  alt?: Alt;
}

export interface trackBucketParams {
  song_index: number;
  project_id: string;
  progressHandler?: (progress: number) => void;
  code?: string;
}

const getParams = ({ file, project_id }: signedURLParams): string => {
  const objectName = encodeURIComponent(file.name);
  return "?project_id="
    .concat(project_id)
    .concat("&object_name=")
    .concat(objectName)
    .concat("&browser=")
    .concat(browserName)
    .concat("&content_type=")
    .concat(file.type)
    .concat("&file_size=")
    .concat(file.size.toString());
};

const getSonicMatchParams = ({ file }: sonicMatchSignedUrlParams): string => {
  const objectName = encodeURIComponent(file.name);
  return "?object_name="
    .concat(objectName)
    .concat("&browser=")
    .concat(browserName)
    .concat("&content_type=")
    .concat(file.type);
};

interface signedArtistUrl extends signedURLParams {
  code: string | null;
}

export interface PresignedUrlResponse {
  signedUrl: string;
  multipartUploadId: string;
  multipartUploadUrls: string[];
  multipartUploadChunkSize: number;
  file: FileVersion;
  bucket: string;
}

export const getArtistSignedUrl = createAsyncThunk(
  ARTIST_SIGNED,
  async ({ file, project_id, code, alt }: signedArtistUrl, thunkAPI) => {
    let params = getParams({
      file,
      project_id,
    });
    if (code) {
      params = params.concat("&code=" + code);
    }
    if (alt !== undefined) {
      params.concat(`&alt=${alt}`);
    }

    const response =
      await makeBackendGetCallWithJsonResponse<PresignedUrlResponse>(
        ARTIST_SIGNED,
        params,
      );

    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface sonicMatchSignedUrlParams {
  file: File;
}

interface GetSonicMatchSignedURLResponse {
  signed_url: string;
  sonic_match_upload: SonicMatchUpload;
}

export const getSonicMatchSignedUrl = createAsyncThunk(
  SONIC_MATCH_SIGNED,
  async ({ file }: sonicMatchSignedUrlParams, thunkAPI) => {
    const params = getSonicMatchParams({ file });
    const response =
      await makeBackendGetCallWithJsonResponse<GetSonicMatchSignedURLResponse>(
        SONIC_MATCH_SIGNED,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getEngineerSignedUrl = createAsyncThunk(
  ENGINEER_SIGNED,
  async ({ file, project_id, reference, alt }: signedURLParams, thunkAPI) => {
    let params = getParams({
      file,
      project_id,
    });
    if (reference !== undefined) {
      params = params.concat(`&reference=${reference}`);
    }
    if (alt !== undefined) {
      params = params.concat(`&alt=${alt}`);
    }

    const response =
      await makeBackendGetCallWithJsonResponse<PresignedUrlResponse>(
        ENGINEER_SIGNED,
        params,
      );

    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface GetReferenceTrackDetails {
  reference: string;
}

export const getReferenceTrackDetails = createAsyncThunk(
  LIST_REFERENCE_DETAILS,
  async ({ project_id }: loadProjectParams, thunkAPI) => {
    const params = `?project_id=${project_id}`;
    const response =
      await makeBackendGetCallWithJsonResponse<GetReferenceTrackDetails>(
        LIST_REFERENCE_DETAILS,
        params,
      );

    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface fetchTrackParams {
  fileVersionId?: number;
  code?: string;
  projectId?: number;
}

export const downloadFileVersionTrack = (
  dispatch: AppDispatch,
  onlyMP3: boolean,
  fileVersionId?: number,
  code?: string,
  projectId?: number,
  onBoardingInvalidator?: () => Promise<void>,
) => {
  void dispatch(
    downloadGeneratedMP3Track({
      fileVersionId,
      code,
      projectId,
    }),
  )
    .unwrap()
    .then((data) => {})
    .catch((e) => {})
    .finally(() => {
      onBoardingInvalidator?.();
    });
  if (onlyMP3) {
    return;
  }
  void dispatch(
    downloadTrack({
      fileVersionId,
      code,
      projectId,
    }),
  )
    .unwrap()
    .finally(async () => {
      await onBoardingInvalidator?.();
    });
};
export const downloadGeneratedMP3Track = createAsyncThunk(
  FETCH_TRACK + "-generated-mp3",
  async ({ fileVersionId, code, projectId }: fetchTrackParams, thunkAPI) => {
    const fetchTrackObject: QueryParamsType = {};
    fetchTrackObject.use_generated_mp3 = true;
    if (fileVersionId) {
      fetchTrackObject.file_version_id = fileVersionId;
      if (code) {
        fetchTrackObject.code = code;
      }
    } else if (projectId) {
      fetchTrackObject.project_id = projectId;
      if (code) {
        fetchTrackObject.code = code;
      }
    }
    const params = `?${queryString.stringify(fetchTrackObject)}`;
    try {
      const { url, content_type } = await downloadTrackByPath({ params });
      const blob = await readStream(url, undefined, content_type);
      return URL.createObjectURL(blob);
    } catch (e) {
      const errors = { errors: e as Error };
      thunkAPI.dispatch(receiveErrors(errors));
      return thunkAPI.rejectWithValue(errors);
    }
  },
);

export const downloadTrack = createAsyncThunk(
  FETCH_TRACK,
  async (
    {
      fileVersionId,
      code,
      projectId,
      onProgress,
    }: fetchTrackParams & { onProgress?: (progress: number) => void },
    thunkAPI,
  ) => {
    const fetchTrackObject: QueryParamsType = {};
    if (fileVersionId) {
      fetchTrackObject.file_version_id = fileVersionId;
      if (code) {
        fetchTrackObject.code = code;
      }
    } else if (projectId) {
      if (code) {
        fetchTrackObject.code = code;
      }
      fetchTrackObject.project_id = projectId;
    }
    const params = `?${queryString.stringify(fetchTrackObject)}`;
    try {
      const { url, content_type } = await downloadTrackByPath({ params });
      const blob = await readStream(url, onProgress, content_type);
      return URL.createObjectURL(blob);
    } catch (e) {
      const errors = { errors: e as Error };
      thunkAPI.dispatch(receiveErrors(errors));
      return thunkAPI.rejectWithValue(errors);
    }
  },
);

interface fetchTrackSnippetParams {
  fileVersionId: number;
}

export const downloadTrackSnippet = createAsyncThunk(
  FETCH_TRACK_SNIPPET,
  async ({ fileVersionId }: fetchTrackSnippetParams, thunkAPI) => {
    const params = `?file_version_id=${fileVersionId}`;

    try {
      const { url, content_type } = await downloadTrackByPath({
        isSnippet: true,
        params,
      });
      const blob = await readStream(url, undefined, content_type);
      return URL.createObjectURL(blob);
    } catch (e) {
      const errors = { errors: e as Error };
      thunkAPI.dispatch(receiveErrors(errors));
      return thunkAPI.rejectWithValue(errors);
    }
  },
);
