import { useCallback, useEffect } from "react";
import { useMediaQueryBreakpoint } from "../useMediaQuery";
import { AuthFlows } from "../../constants/authSteps";
import { SCREENS } from "../../routes/screens";
import { useAtom, useSetAtom } from "jotai/index";
import {
  authModalOpenAtom,
  currentFlowAtom,
} from "../../stories/components/Auth/atoms";
import { authModalEmailFieldAtom } from "../../atoms/auth/authModalEmailField";
import { useHistory, useLocation } from "react-router-dom";
import { authRedirectPath } from "../../atoms/auth/authRedirectPath";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue } from "jotai";

const useAuthHandler = () => {
  const { isMobile } = useMediaQueryBreakpoint();
  const setFlow = useSetAtom(currentFlowAtom);
  const setAuthModalValue = useSetAtom(authModalOpenAtom);
  const setAuthModalEmailField = useSetAtom(authModalEmailFieldAtom);
  const [authRedirectValue, setAuthRedirect] = useAtom(authRedirectPath);
  const history = useHistory();
  const location = useLocation();

  const user = useAtomValue(activeUserAtom);

  useEffect(() => {
    if (user && authRedirectValue) {
      setAuthModalValue(false);
      setAuthModalEmailField("");
    }
  }, [setAuthModalEmailField, setAuthModalValue, user]);

  const initializeAuth = useCallback(
    (email: string) => {
      setFlow(AuthFlows.EMAIL_LOGIN);
      setAuthModalEmailField(email);
      if (isMobile) {
        history.push(SCREENS.AUTH_SCREEN);
        setAuthRedirect(location.pathname);
      } else {
        setAuthModalValue(true);
      }
    },
    [
      setFlow,
      setAuthModalEmailField,
      isMobile,
      history,
      setAuthRedirect,
      location.pathname,
      setAuthModalValue,
    ],
  );

  const handleRedirect = useCallback(() => {
    if (authRedirectValue) {
      const path = `${authRedirectValue}`;
      setAuthModalEmailField("");
      setAuthRedirect(undefined);
      history.push(path);
    }
  }, [authRedirectValue, history, setAuthModalEmailField, setAuthRedirect]);

  return {
    initializeAuth,
    handleRedirect,
  };
};

export default useAuthHandler;
