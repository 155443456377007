import List, { ListProps } from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Box, Grid2, styled } from "@mui/material";
import {
  getUserPlatform,
  PLATFORM,
  PlatformOrKeyToIcon,
  PlatformToKey,
  POWER_USER_CONTROLS,
  powerControlKeysDataByCategoryAtom,
} from "../AppState/hooks/usePowerUserControls";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond } from "@fortawesome/pro-solid-svg-icons";

const MetaKeyIcon = ({ platform = "" }: { platform?: string }) => {
  const icon = PlatformOrKeyToIcon[platform] || faDiamond;
  return <FontAwesomeIcon icon={icon} />;
};

const KeyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "1em",
  height: "1em",
  alignItems: "center",
  gap: theme.spacing(1),
  justifyContent: "center",
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.text.secondary}`,
}));

const MetaKeyControl = ({
  showKey = false,
  showIcon = false,
}: {
  showKey?: boolean;
  showIcon?: boolean;
}) => {
  const platform = getUserPlatform();
  const userPlatformKey = useMemo(() => {
    if (!platform) return "";
    if (platform.includes("mac")) return PLATFORM.MAC;
    if (platform.includes("win")) return PLATFORM.WINDOWS;
    if (platform.includes("ios")) return PLATFORM.IOS;
    return "";
  }, [platform]);
  return (
    <>
      {showIcon && <MetaKeyIcon platform={userPlatformKey} />}
      {showIcon && showKey && " "}
      {showKey && (PlatformToKey[userPlatformKey] || "Meta")}
    </>
  );
};

const StyledList = styled(List)(({ theme }) => ({
  bgcolor: "background.paper",
  position: "relative",
  overflow: "auto",
  "& ul": { padding: 0 },
}));

interface ControlKeyListProps extends Omit<ListProps, "subheader"> {}

export const ControlKeyList = ({ ...props }: ControlKeyListProps) => {
  const keyData = useAtomValue(powerControlKeysDataByCategoryAtom);
  const listItems = useMemo(() => {
    return Object.entries(keyData).map(([key, values]) => {
      return (
        <li key={`category-${key}`}>
          <ul>
            <ListSubheader>{`${key}`}</ListSubheader>
            {values.map((item, index) => (
              <ListItem
                sx={(theme) => ({
                  backgroundColor:
                    index % 2 ? theme.palette.background.default : undefined,
                })}
                divider
                key={`item-${key}-${item.key}`}
              >
                <Grid2 container width={"100%"}>
                  <Grid2 size={8}>
                    <ListItemText
                      sx={(theme) => ({
                        color: theme.palette.text.primary,
                      })}
                      primary={`${item.value.label}`}
                    />
                  </Grid2>
                  <Grid2 display={"flex"} gap={1} size={4}>
                    {item.value.keys.map((k) => {
                      const icon = PlatformOrKeyToIcon[k];
                      if (!icon) return k;

                      if (k === POWER_USER_CONTROLS.META)
                        return (
                          <KeyBox key={k}>
                            <MetaKeyControl
                              key={k}
                              showKey={false}
                              showIcon={true}
                            />
                          </KeyBox>
                        );
                      return (
                        <KeyBox key={k}>
                          <FontAwesomeIcon key={k} icon={icon} />
                        </KeyBox>
                      );
                    })}
                  </Grid2>
                </Grid2>
              </ListItem>
            ))}
          </ul>
        </li>
      );
    });
  }, []);
  return (
    <StyledList subheader={<li />} {...props}>
      {listItems}
    </StyledList>
  );
};
