import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAccountStatus } from "../../../hooks/accountHooks";
import {
  resendEmailVerification,
  updateProfile,
  verifyAccountPhoneNumber,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  isCurrentUserEngineerOrStudioManagerSelector,
  selectCurrentUser,
} from "../../../store/selectors/userInfoSelectors";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { CheckBox } from "../../core-ui/components/CheckBox/CheckBox";
import animationData from "../../lotties/success.json";
import { ColorPalette } from "../../theme";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import "react-phone-number-input/style.css";
import "./ContactInfoModal.css";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { getDocUrl } from "../../../constants/googleStorage";
import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";
import { TextField, useTheme } from "@mui/material";

export const defaultOptions = {
  loop: false,
  autoplay: false,
  duration: 1000,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const MISSING_PHONE_NUMBER_MESSAGE =
  "Add a phone number to your account before booking to receive relevant notifications.";
export const MISSING_EMAIL_VERIFICATION_MESSAGE =
  "Verify your email address before booking to receive relevant notifications.";

export interface OnUpdateArgs {
  phoneNumberVerified: boolean;
  contactInfoUpdated: boolean;
}

export interface ContactInfoModalProps {
  overwriteShowModal?: boolean;
  onClose?: (args: OnUpdateArgs) => void;
  customHeading?: string;
  disableDismiss?: boolean;
  closeOnPhoneVerified?: boolean;
}

export const ContactInfoModal = ({
  overwriteShowModal = false,
  onClose,
  customHeading,
  disableDismiss = false,
  closeOnPhoneVerified = false,
}: ContactInfoModalProps) => {
  const theme = useTheme();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
  const isEngineerOrStudioManager = useAppSelector(
    isCurrentUserEngineerOrStudioManagerSelector,
  );
  const [openModal, setOpen] = useState<boolean>(overwriteShowModal);
  const { emailVerified, phoneVerified, missingAccountInfo } =
    useAccountStatus(openModal);
  const user = useAppSelector(selectCurrentUser);
  const email = user?.email ?? "";
  const darkMode = useAtomValue(darkModeAtom);
  const [showEdit, setShowEdit] = useState(false);
  const [errorMessage] = useState("");
  const [code, setCode] = useState("");
  const [firstName, setFirstName] = useState(user?.first_name ?? "");
  const [lastName, setLastName] = useState(user?.last_name ?? "");
  const [phoneNumber, setPhoneNumber] = useState(
    user?.phone_number?.phone_number ?? "",
  );
  const [agreeToTerms, setAgreeToTerms] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resendClicked, setResendClicked] = useState(false);
  const [status, setStatus] = useState<OnUpdateArgs>({
    phoneNumberVerified: false,
    contactInfoUpdated: false,
  });

  const disableButton = useMemo(() => {
    return (
      firstName.length == 0 || lastName.length == 0 || phoneNumber.length == 0
    );
  }, [firstName, lastName, phoneNumber]);

  useEffect(() => {
    if (!isEngineerOrStudioManager) {
      return;
    }
    if (!phoneVerified || !emailVerified) {
      setOpen(true);
    } else if (closeOnPhoneVerified) {
      setOpen(false);
    }
  }, [isEngineerOrStudioManager, phoneVerified, closeOnPhoneVerified]);

  const dispatch = useAppDispatch();

  const resendVerificationCode = useCallback(() => {
    setLoading(true);
    if (!user?.phone_number?.phone_number) {
      toast.error("No phone number to send code to");
      return;
    }
    void dispatch(
      updateProfile({ phone_number: user?.phone_number?.phone_number }),
    )
      .then(() => {
        setLoading(false);
        setResendClicked(true);
        toast.success("Verification code sent to your phone number");
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch, user]);

  const verifyPhoneNumber = useCallback(() => {
    setLoading(true);
    emitAnalyticsTrackingEvent("verify_phone_number", {}, user?.id);
    void dispatch(
      verifyAccountPhoneNumber({
        verification: code,
      }),
    )
      .unwrap()
      .then((updatedUser) => {
        if (updatedUser.phone_number?.verified) {
          setLoading(false);
          toast.success("Phone number verified");
          setStatus({
            phoneNumberVerified: true,
            contactInfoUpdated: true,
          });
          if (onClose && !disableDismiss) {
            onClose({
              phoneNumberVerified: true,
              contactInfoUpdated: true,
            });
            setOpen(false);
          }
          setLoading(false);
          return;
        }
        setLoading(false);
      })
      .catch(() => {
        setStatus({ contactInfoUpdated: false, phoneNumberVerified: false });
        setLoading(false);
      });
  }, [code, loading, dispatch, onClose]);

  const handleSubmit = useCallback(() => {
    emitAnalyticsTrackingEvent(
      "submit_updated_contact_info",
      {
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
      },
      user?.id,
    );
    setLoading(true);
    void dispatch(
      updateProfile({
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
      }),
    )
      .unwrap()
      .then((updatedUser) => {
        setShowEdit(false);
        setLoading(false);
        if (updatedUser.phone_number?.verified) {
          toast.success("Contact info updated, phone number verified");
          setStatus({
            phoneNumberVerified: true,
            contactInfoUpdated: true,
          });
          if (onClose && !disableDismiss) {
            onClose({
              phoneNumberVerified: true,
              contactInfoUpdated: true,
            });
            setOpen(false);
          }
        } else {
          toast.success("Contact info updated");
          setStatus({
            phoneNumberVerified: false,
            contactInfoUpdated: true,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    phoneNumber,
    firstName,
    lastName,
    loading,
    dispatch,
    user?.id,
    onClose,
    disableDismiss,
  ]);

  const contactInfoForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (disableButton) {
            toast.error("Please fill out all form fields");
            return;
          }
          if (!agreeToTerms) {
            toast.error("Please agree to the terms");
            return;
          }
          handleSubmit();
        }}
        className={"contact-info-form"}
      >
        <TextField
          size={"medium"}
          value={firstName}
          onChange={(event) => {
            event.preventDefault();
            const value = event.target.value;
            setFirstName(value);
          }}
          label="First name"
          className={"contact-info-text-fields"}
        />
        <TextField
          size={"medium"}
          onChange={(event) => {
            event.preventDefault();
            const value = event.target.value;
            setLastName(value);
          }}
          value={lastName}
          label="Last name"
          className={"contact-info-text-fields"}
        />
        <PhoneInput
          className={
            "contact-modal-input input-text mb-2 contact-info-text-fields"
          }
          isValidPhoneNumber={isValidPhoneNumber}
          defaultCountry={"US"}
          value={phoneNumber}
          onChange={(value: string) => {
            setPhoneNumber(value);
            setIsValidPhoneNumber(matchIsValidTel(value));
          }}
        />
        <CheckBox
          label={
            "I agree to receive notifications via text message from EngineEars at the phone number provided above. Message and data rates may apply. Reply STOP to opt out."
          }
          checked={agreeToTerms}
          onClick={() => {
            setAgreeToTerms(!agreeToTerms);
          }}
          style={{ color: theme.palette.text.primary }}
        />
        <Button
          className={"my-2"}
          fullWidth={true}
          disabled={disableButton || !agreeToTerms}
          loading={loading}
          variant={ButtonVariant.PRIMARY}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <p>
          <a
            style={{ color: darkMode ? "white" : "black", fontSize: "12px" }}
            href={getDocUrl("EngineEarsTermsOfUse.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
          <span
            style={{ color: darkMode ? "white" : "black", fontSize: "12px" }}
          >
            &nbsp;|&nbsp;
          </span>
          <a
            style={{ color: darkMode ? "white" : "black", fontSize: "12px" }}
            href={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </form>
    );
  };

  const phoneNumberVerificationForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (code.length === 0) {
            toast.error("Please enter the verification code");
            return;
          }
          verifyPhoneNumber();
        }}
        className={"contact-info-form"}
      >
        <input
          onChange={(event) => {
            event.preventDefault();
            const value = event.target.value;
            setCode(value);
          }}
          value={code}
          placeholder="enter verification code"
          type={"text"}
          className="textfield my-2"
        />
        <p
          onClick={() => {
            setShowEdit(true);
          }}
          className="label2 resend-verify-text-code-text mb-2"
          style={{ color: ColorPalette.DeepBlue300 }}
        >
          update phone number
        </p>
        <p
          onClick={resendVerificationCode}
          className="label2 resend-verify-text-code-text mb-2"
          style={{ color: ColorPalette.BoomyOrange400 }}
        >
          {loading ? "sending code ..." : "re-send verification code"}
        </p>
        {resendClicked && (
          <p
            onClick={() => {
              setStatus({
                contactInfoUpdated: true,
                phoneNumberVerified: true,
              });
              if (onClose && !disableDismiss) {
                onClose({
                  contactInfoUpdated: true,
                  phoneNumberVerified: true,
                });
              } else {
                setOpen(false);
              }
            }}
            className="label2-semi-bold resend-verify-text-code-text mb-2"
            style={{ color: ColorPalette.DarkGrey }}
          >
            text not received? skip for now
          </p>
        )}
        <p
          onClick={resendVerificationCode}
          className={"label2 resend-verify-error-text resend-verify-text-code-text mb-2".concat(
            errorMessage.length > 0 ? "show" : "",
          )}
        >
          {errorMessage}
        </p>
        <Button
          fullWidth={true}
          loading={loading}
          disabled={loading || code.length === 0}
          variant={ButtonVariant.PRIMARY}
          onClick={verifyPhoneNumber}
        >
          Verify
        </Button>
      </form>
    );
  };

  const contactInfoFormView = () => {
    if (!emailVerified) {
      return (
        <div>
          <p className={"b1 mb-4"}>
            {`Before continuing, please verify your email address ${email} by clicking on the link in the email titled 'Email verification link for EngineEars'`}
          </p>
          <Button
            fullWidth={true}
            loading={loading}
            variant={ButtonVariant.OUTLINED}
            onClick={async () => {
              setLoading(true);
              await dispatch(
                resendEmailVerification({ resend_verification_code: false }),
              );
              setLoading(false);
            }}
          >
            Resend verification email
          </Button>
        </div>
      );
    }
    if (missingAccountInfo || showEdit) {
      return contactInfoForm();
    }
    if (!phoneVerified) {
      return phoneNumberVerificationForm();
    }
    return contactInfoForm();
  };

  let heading = isEngineerOrStudioManager
    ? "Get notified when you are booked and for other platform notifications."
    : "Get notified when your project is accepted and for other platform notifications.";
  if (customHeading) {
    heading = customHeading;
  }
  return (
    <BaseModal
      open={openModal}
      setOpen={() => {
        if (!disableDismiss) {
          /* Used when onClose is provided in props.
             This way we properly update the modal state from parent
             component along whether the user updated contact info or not */
          if (onClose) {
            onClose({
              contactInfoUpdated: status.contactInfoUpdated,
              phoneNumberVerified: status.phoneNumberVerified,
            });
          }
          setOpen(false);
        }
      }}
      header={"Contact Info"}
      showCloseButton={!disableDismiss}
    >
      <header className={"contact-info-modal-header"}>
        <p
          className={"h7-semi-bold"}
          style={{ color: theme.palette.text.primary }}
        >
          {heading}
        </p>
      </header>
      {contactInfoFormView()}
    </BaseModal>
  );
};
