import { GUEST_LOGIN_REQUEST } from "../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import queryString from "query-string";
import { AuthResponse } from "../../store/actions/accountInfo";

export interface GetGuestVerificationCodeParams {
  email?: string;
  phone_number?: string;
  resend_for_user_id?: string;
}

export interface UserIdResponse {
  user_id: string;
  created_account: boolean;
}

export const getGuestVerificationCode = async (
  args: GetGuestVerificationCodeParams,
) => {
  const params = `?${queryString.stringify(args, {
    skipEmptyString: true,
    skipNull: true,
  })}`;
  return await makeBackendGetCallWithJsonResponse<UserIdResponse>(
    GUEST_LOGIN_REQUEST,
    params,
  );
};
