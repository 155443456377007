import {
  emptySessionBookingText,
  SessionDetail,
  useMissingBookingSessionDetails,
} from "../../../hooks/bookingHooks/useIsPendingSessionDataBookable";
import { useTransactionRedirect } from "../../../hooks/bookingHooks/useTransactionRedirect";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { getApplicableDiscountBookingLinkData } from "../../../store/selectors/bookingSelectors";
import { ApplyDiscount } from "../../components/ApplyDiscount/ApplyDiscount";
import ApplyPromoCode from "../../components/GenerateBooking/ApplyPromoCode";
import EstimatedDeliveryDatePicker from "../../components/GenerateBooking/EstimatedDeliveryDatePicker";
import { OrderBreakdown } from "../../components/OrderBreakdown/OrderBreakdown";
import { PaymentGatewayModule } from "../../components/PaymentGatewayModule/PaymentGatewayModule";
import { ShareBookingLink } from "../../components/ShareBookingLink/ShareBookingLink";
import { Text } from "../../core-ui/components/Text/Text";
import { TextColor } from "../../core-ui/components/Text/TextUtils";
import { SuccessLottie } from "../../elements/SuccessLottie/SuccessLottie";
import "./TransactionBookingScreen.css";

interface TransactionBookingOrderDetailsProps {
  isLoggedInUserGeneratingBooking: boolean;
}

const TransactionBookingOrderDetails = ({
  isLoggedInUserGeneratingBooking,
}: TransactionBookingOrderDetailsProps) => {
  const {
    activeServiceType,
    activeServiceId,
    activeStudioId,
    scheduledProjectId,
  } = useAppSelector((state) => state.generateBookingStore);
  const { transactionData } = useAppSelector((state) => state.transactionStore);
  const { inProgressProject } = useAppSelector(
    (state) => state.mixMasterCartsStore.cart,
  );

  const validDiscountBookingLinkData = useAppSelector(
    getApplicableDiscountBookingLinkData(activeServiceId),
  );
  const { isDesktop } = useMediaQueryBreakpoint();

  const isRecordingServiceTypeActive =
    activeServiceType == ProjectType.RECORDING;
  const {
    orderSummary: mixMasterOrderSummary,
    isLoading: isOrderSummaryLoading,
  } = useAppSelector((state) => state.mixMasterCartsStore);
  const {
    orderSummary: recordingOrderSummary,
    isLoading: isRecordingCartsLoading,
  } = useAppSelector((state) => state.recordingCartsStore);
  const showApplyPromoCode =
    !isRecordingServiceTypeActive || Boolean(activeStudioId);

  const cartHasContent = Boolean(
    mixMasterOrderSummary || recordingOrderSummary,
  );
  const cssPrefix = "transaction-booking-screen_";
  const redirectPath = useTransactionRedirect(
    isRecordingServiceTypeActive,
    transactionData,
  );

  const missingSessionDetail = useMissingBookingSessionDetails();
  const disabledText =
    missingSessionDetail !== SessionDetail.NONE
      ? emptySessionBookingText[missingSessionDetail]
      : "Please select a service";

  return (
    <>
      <div className={`${cssPrefix}item-container`}>
        <OrderBreakdown
          orderSummary={recordingOrderSummary ?? mixMasterOrderSummary}
          isLoading={isOrderSummaryLoading || isRecordingCartsLoading}
          showLabelToggle={
            !isRecordingServiceTypeActive && isLoggedInUserGeneratingBooking
          }
          emptyStateText={
            isRecordingServiceTypeActive
              ? emptySessionBookingText[missingSessionDetail]
              : "Please select a service"
          }
          showAssumeFeesToggle={isLoggedInUserGeneratingBooking}
          showShareArtistContactInfoToggle={
            isRecordingServiceTypeActive && !isLoggedInUserGeneratingBooking
          }
        />
      </div>
      {isLoggedInUserGeneratingBooking && (
        <div className={`${cssPrefix}item-container`}>
          <ApplyDiscount />
        </div>
      )}
      {!isRecordingServiceTypeActive && (
        <div className={`${cssPrefix}item-container`}>
          <EstimatedDeliveryDatePicker
            isLoggedInUserGeneratingBooking={isLoggedInUserGeneratingBooking}
          />
        </div>
      )}
      {isLoggedInUserGeneratingBooking && (
        <>
          {missingSessionDetail !== SessionDetail.NONE && (
            <Text
              bold
              color={TextColor.ERROR}
              style={{ textAlign: "right", width: "100%" }}
            >
              {emptySessionBookingText[missingSessionDetail]}
            </Text>
          )}
          <div className={`${cssPrefix}item-container`}>
            <ShareBookingLink
              cartHasContent={cartHasContent}
              disabledText={disabledText}
              transactionId={transactionData?.id}
              inProgressProject={inProgressProject}
              scheduledProjectId={scheduledProjectId}
              recordingSessions={transactionData?.recording_sessions}
            />
          </div>
        </>
      )}
      {!isLoggedInUserGeneratingBooking && transactionData && (
        <>
          {!validDiscountBookingLinkData && showApplyPromoCode && (
            <div className={`${cssPrefix}item-container`}>
              <ApplyPromoCode transactionCode={transactionData.code} />
            </div>
          )}
          <div className={`${cssPrefix}item-container`}>
            <PaymentGatewayModule
              disabledText={disabledText}
              transaction={transactionData}
              isMobile={!isDesktop}
              redirectPath={redirectPath}
              minimumDepositPercentage={
                transactionData?.deposit_percentage_required
              }
              serviceType={activeServiceType}
            >
              <SuccessLottie />
            </PaymentGatewayModule>
          </div>
        </>
      )}
    </>
  );
};

export default TransactionBookingOrderDetails;
