import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { assertEngineEarsErrorType, displayEngineEarsError } from "../helpers";
import { ProductTransactionResponse } from "./models";
import { updateProductTransaction } from "./productTransactionsAPI";

export const useUpdateTransactionForProductMutation = (
  transactionCode?: string,
) => {
  return useMutation({
    mutationFn: async (transactionCode: string) => {
      const response = await updateProductTransaction(transactionCode);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ProductTransactionResponse;
    },
    mutationKey: [QUERY_KEYS.UPDATE_PRODUCT_TRANSACTION, transactionCode],
    onError: (error: EngineEarsError) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
  });
};
