import { createAsyncThunk } from "@reduxjs/toolkit";
import { ScheduledProjectPaywallTypes } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { START_IN_PROGRESS_PROJECT } from "../../utils/routes";
import { receiveErrors } from "../errorStore";

export interface startInProgressProjectParams {
  scheduled_project_id: number;
  default_paywall_option: ScheduledProjectPaywallTypes;
}

const startInProgressProject = createAsyncThunk(
  START_IN_PROGRESS_PROJECT,
  async (params: startInProgressProjectParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<object>(
      START_IN_PROGRESS_PROJECT,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default startInProgressProject;
