import { AuthFlows } from "../../../constants/authSteps";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SignInForm } from "../SignInForm/SignInForm";
import { authModalOpenAtom, currentFlowAtom } from "./atoms";
import { CloseButton, FormContainer, SignUpFooter } from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import { Box, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { useAppSelector } from "../../../store/hooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { authModalEmailFieldAtom } from "../../../atoms/auth/authModalEmailField";
import { useEffect } from "react";

export const EmailAuth = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const theme = useTheme();
  const [flow, setFlow] = useAtom(currentFlowAtom);
  const [, setAuthModalOpen] = useAtom(authModalOpenAtom);
  const isRegistering = flow === AuthFlows.START_SIGNUP;
  const { isDesktop } = useMediaQueryBreakpoint();
  const [email, setEmail] = useAtom(authModalEmailFieldAtom);

  useEffect(() => {
    //on unmount set the email field to empty
    return () => {
      setEmail("");
    };
  }, [setEmail]);

  return (
    <FormContainer>
      <AuthNavigation />
      {isDesktop && (
        <CloseButton>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            color={theme.palette.text.primary}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                isRegistering
                  ? "auth_email_signup_clicked_on_exit"
                  : "auth_login_clicked_on_exit",
                {},
                user?.id,
              );
              setAuthModalOpen(false);
            }}
          />
        </CloseButton>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        <Text variant={TextStyleVariant.H6}>
          {isRegistering ? "Create a new account" : "Sign in to your account"}
        </Text>
        <Text>
          {isRegistering ? "Already a member?" : "Don’t have an account?"}
          <Button
            style={{
              textDecoration: "underline",
              display: "inline",
              marginLeft: "4px",
              color: theme.palette.text.primary,
            }}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              if (isRegistering) {
                emitAnalyticsTrackingEvent(
                  "auth_switched_to_login",
                  {},
                  user?.id,
                );
                setFlow(AuthFlows.LOGIN);
              } else {
                emitAnalyticsTrackingEvent(
                  "auth_switched_to_signup",
                  {},
                  user?.id,
                );
                setFlow(AuthFlows.START_SIGNUP);
              }
            }}
          >
            {isRegistering ? "Sign in" : "Sign up"}
          </Button>
        </Text>
      </Box>
      <SignInForm
        defaultValueEmail={email}
        register={isRegistering}
        key={isRegistering ? "register" : "login"}
      />
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
