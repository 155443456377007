import { useSetAtom } from "jotai/index";
import { checkoutAuthInProgressAtom } from "../../atoms/auth/checkoutAuthInProgress";
import { authModalOpenAtom } from "../../stories/components/Auth/atoms";
import { useCallback, useEffect } from "react";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useEnteredUMGEmail } from "./useEnteredUMGEmail";
import { useAtomValue } from "jotai";
import { authModalEmailFieldAtom } from "../../atoms/auth/authModalEmailField";
import useAuthHandler from "./useAuthHandler";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";

export const useDisplayUMGAuth = (
  email: string,
  otpCheckoutEnabled: boolean,
) => {
  const setCheckoutAuth = useSetAtom(checkoutAuthInProgressAtom);
  const setAuthModalEmailField = useSetAtom(authModalEmailFieldAtom);
  const authModalOpen = useAtomValue(authModalOpenAtom);
  const enteredUMGEmail = useEnteredUMGEmail(email);
  const { initializeAuth } = useAuthHandler();
  const user = useAtomValue(activeUserAtom);

  useEffect(() => {
    if (user) {
      return;
    }
    if (!otpCheckoutEnabled && !authModalOpen) {
      setCheckoutAuth(false);
    }
    if (otpCheckoutEnabled || authModalOpen) {
      setCheckoutAuth(true);
    }
  }, [otpCheckoutEnabled, authModalOpen, setCheckoutAuth, user]);

  const handleUMGAuth = useCallback(() => {
    if (!enteredUMGEmail) return;
    emitAnalyticsTrackingEvent(
      "open_umg_login",
      {
        email,
      },
      undefined,
    );
    initializeAuth(email);
  }, [enteredUMGEmail, email, initializeAuth]);

  useEffect(() => {
    if (!enteredUMGEmail) return;
    if (!authModalOpen) {
      setAuthModalEmailField("");
    } else {
      setAuthModalEmailField(email);
    }
  }, [email, enteredUMGEmail, authModalOpen, setAuthModalEmailField]);

  useEffect(() => {
    if (user) {
      return;
    }
    handleUMGAuth();
  }, [handleUMGAuth, setAuthModalEmailField, user]);

  return { enteredUMGEmail, handleUMGAuth };
};
