import { useMemo } from "react";
import { usePendingPurchaseOrders } from "../../../hooks/partialPaymentHooks";
import { useAppSelector } from "../../../store/hooks";
import { FileVersion, UploadType } from "../../../store/models/fileVersion";
import {
  ProjectById,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import {
  getMixingMasteringProject,
  getProjectWorkflowStep,
  getUserTypeFromProject,
} from "../../../utils/projectUtils";

/**
 * @returns boolean - true if downloads are disabled, false otherwise
 * This is used for the Download All Files button
 */
export const useDisabledDownloads = (
  isArtist: boolean,
  projectWorkflowStep: ProjectWorkflowSteps,
  reference: boolean,
  project: ProjectById,
) => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const { pendingBillingInfo } = usePendingPurchaseOrders();

  if (reference) {
    return false;
  }

  const mixingMasteringProject = getMixingMasteringProject(project);
  if (!mixingMasteringProject) return false;

  const engineerEnabledDownloads =
    mixingMasteringProject.enable_artist_review_wav_file_download;
  const isNotEngineer = isArtist || !isAuthenticated;

  // If the project is pending billing info, downloads are disabled
  if (isNotEngineer && pendingBillingInfo) return true;

  return (
    isNotEngineer &&
    [ProjectWorkflowSteps.IN_MIX, ProjectWorkflowSteps.MIX_REVIEW].some(
      (step) => step === projectWorkflowStep,
    ) &&
    !engineerEnabledDownloads &&
    !reference
  );
};

/**
 * This is meant to be a more comprehensive check for whether a file can be downloaded
 * based on whether it is an MP3 or WAV and whether the engineer has enabled these permissions
 */
export const useDisabledDownloadFiles = (
  project: ProjectById,
  files: FileVersion[],
) => {
  const { user } = useAppSelector((state) => state.accountInfo);
  const { pendingBillingInfo, pendingBudgetApproval } =
    usePendingPurchaseOrders();
  const isPendingBillingInfo = Boolean(pendingBillingInfo);
  const isPendingBudgetApproval = Boolean(pendingBudgetApproval);
  const mixingMasteringProject = getMixingMasteringProject(project);
  const allFilesNotDisabled = useMemo(() => files.map(() => false), [files]);
  if (!mixingMasteringProject) return allFilesNotDisabled;

  const currentUserType = getUserTypeFromProject(user, project);
  const isEngineer = currentUserType === ProjectUserType.ENGINEER;
  const isSuperUser = Boolean(user?.is_superuser);
  // engineers and super users can always download files
  if (isEngineer || isSuperUser) return allFilesNotDisabled;

  const workflowStep = getProjectWorkflowStep(
    project.service_type,
    mixingMasteringProject.step,
  );
  // If the project is finished, all files can be downloaded
  const isProjectFinished = workflowStep === ProjectWorkflowSteps.MIX_FINISHED;
  if (isProjectFinished && !isPendingBillingInfo) return allFilesNotDisabled;

  // Check each file to see if it can be downloaded
  return files.map((file) => {
    if (file.reference) return false;

    switch (file.upload_type) {
      // zip files cannot be downloaded if the project is pending billing info
      case UploadType.ZIP:
        return isPendingBillingInfo;
      case UploadType.AUDIO:
        return (
          !mixingMasteringProject.enable_artist_review_wav_file_download &&
          (!isProjectFinished ||
            isPendingBillingInfo ||
            isPendingBudgetApproval)
        );
      case UploadType.GENERATED_MP3:
        return (
          !mixingMasteringProject.enable_artist_review_mp3_file_download &&
          (!isProjectFinished ||
            isPendingBillingInfo ||
            isPendingBudgetApproval)
        );
      default:
        return true;
    }
  });
};
