import { Box, styled } from "@mui/material";

export const WeekdayPickerContainer = styled(Box)<{
  $enableTimeSelection: boolean;
}>(({ $enableTimeSelection }) => ({
  display: "flex",
  flexDirection: $enableTimeSelection ? "column" : "row",
  justifyContent: $enableTimeSelection ? "center" : "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: "20px",
  gap: $enableTimeSelection ? "24px" : "0",
}));

export const TimeSelectionContainer = styled(Box)<{
  $enableTimeSelection: boolean;
}>(({ $enableTimeSelection, theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "24px",

  [theme.breakpoints.down("md")]: {
    flexDirection: $enableTimeSelection ? "column" : "row",
  },
}));

export const TimeDropdownContainer = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
}));
