import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { displayEngineerPromoModalAtom } from "../../../atoms/modals/promoModals";
import useOptedInUnverifiedEngineersQuery from "../../../hooks/engineerHooks/useOptedInUnverifiedEngineersQuery";
import {
  SearchScreenParamKeys,
  useUpdateSearchFilters,
} from "../../../hooks/searchHooks/useSearchFilters";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes/screens";
import { useAppSelector } from "../../../store/hooks";
import User from "../../../store/models/user";
import { isCurrentUserArtistSelector } from "../../../store/selectors/userInfoSelectors";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { getUserProfileCard } from "../ProfileCard/ProfileCard";
import {
  StyledEngineerPromoListButton,
  StyledEngineerPromoListCardContainer,
  StyledEngineerPromoListContainer,
  StyledEngineerPromoListFooter,
  StyledEngineerPromoListSubtitle,
} from "./EngineerPromoListModal.styles";

export const UNVERIFIED_ENG_PROMO_CODE = "NEWEE50";
const MOBILE_LENGTH = 2;

export interface EngineerPromoListModalProps {}

export const EngineerPromoListModal = ({}: EngineerPromoListModalProps) => {
  const { data, isLoading, isSuccess } = useOptedInUnverifiedEngineersQuery();
  const history = useHistory();
  const { state: historyState } = history.location;
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const loggedInUserIsArtist = useAppSelector(isCurrentUserArtistSelector);
  const [showModal, setShowModal] = useAtom(displayEngineerPromoModalAtom);
  const updateSearchFilters = useUpdateSearchFilters();

  const { isMobile } = useMediaQueryBreakpoint();

  const engineerUsers = useMemo(() => {
    if (isMobile) {
      return data?.slice(0, MOBILE_LENGTH);
    }
    return data;
  }, [data, isMobile]);

  const handleProfileCardClick = (prevEngineers?: User[]) => {
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSeeMore = () => {
    setShowModal(false);
    emitAnalyticsTrackingEvent(
      "clicked_see_opted_in_unverified_engineers",
      {},
      loggedInUser?.id,
    );

    updateSearchFilters(
      {
        [SearchScreenParamKeys.UpAndComingEngineer]: true,
        [SearchScreenParamKeys.PromoCode]: UNVERIFIED_ENG_PROMO_CODE,
      },
      SCREENS.SEARCH,
    );
  };

  useEffect(() => {
    const hS = historyState as { showEngineerPromoListModal: boolean };
    if (hS && loggedInUserIsArtist) {
      hS.showEngineerPromoListModal && setShowModal(true);
    }
  }, [historyState, setShowModal, loggedInUserIsArtist]);

  // Close self if there are no engineers
  useEffect(() => {
    if (isSuccess && engineerUsers && engineerUsers.length === 0) {
      setShowModal(false);
    }
  }, [setShowModal, isSuccess, engineerUsers]);

  return (
    <BaseModal
      header="Welcome to EngineEars"
      open={showModal && Boolean(engineerUsers?.length)}
      setOpen={
        showModal
          ? handleClose
          : () => {
              setShowModal(true);
            }
      }
      centerModalHeader
      style={{
        maxWidth: "100%",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <StyledEngineerPromoListContainer>
        <StyledEngineerPromoListSubtitle>
          Get 50% off your first booking with any of these engineers!
        </StyledEngineerPromoListSubtitle>
        <StyledEngineerPromoListCardContainer>
          {isLoading ? (
            <SoundWaveLoader width={100} height={100} />
          ) : (
            engineerUsers?.map((engineerUser, key) => {
              const path = getProfileScreenRoute(engineerUser.username).concat(
                "?promocode=",
                UNVERIFIED_ENG_PROMO_CODE,
              );
              return getUserProfileCard(engineerUser, key, path, () =>
                handleProfileCardClick(engineerUsers),
              );
            })
          )}
        </StyledEngineerPromoListCardContainer>
        <StyledEngineerPromoListFooter>
          <StyledEngineerPromoListButton onClick={handleSeeMore}>
            See More
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ marginLeft: "8px" }}
            />
          </StyledEngineerPromoListButton>
        </StyledEngineerPromoListFooter>
      </StyledEngineerPromoListContainer>
    </BaseModal>
  );
};
