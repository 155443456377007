import { useAppSelector } from "../../../../store/hooks";
import { ProjectById, ProjectUserType } from "../../../../store/models/project";
import { getUserTypeFromProject } from "../../../../utils/projectUtils";
import { NudgeButton } from "../../../elements/NudgeButton/NudgeButton";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { InProgressButtonRow } from "../InProgressTransitionView.styles";
import { ApproveMixButton } from "./ApproveMixButton";
import { RequestRevisionButton } from "./RequestRevisionButton";
import { CompletePaymentButton } from "./CompletePaymentButton";

interface InProgressArtistButtonRowProps {
  isInProgressStep: boolean;
  project: ProjectById;
  allowTrackApproval?: boolean;
  code?: string;
  isPartiallyPaid?: boolean;
  isCompletePaymentPopoverOpen: boolean;
  setIsCompletePaymentPopoverOpen: (status: boolean) => void;
  closeCompletePaymentPopover: () => void;
  outstandingBalance?: number;
  isInProgressProject?: boolean;
}

/**
 * Displays the buttons for the Artist in the Mix/Master In Progress step and the Mix/Master Review step.
 */
export const InProgressArtistButtonRow = ({
  isInProgressStep,
  project,
  code,
  allowTrackApproval = true,
  isPartiallyPaid = false,
  isCompletePaymentPopoverOpen,
  setIsCompletePaymentPopoverOpen,
  closeCompletePaymentPopover,
  outstandingBalance,
  isInProgressProject = false,
}: InProgressArtistButtonRowProps) => {
  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const currentUserType = getUserTypeFromProject(currentUser, project);

  return (
    <MainProjectWorkflowButtonRow>
      <InProgressButtonRow>
        {isInProgressStep ? (
          <NudgeButton
            iconOnly={false}
            projectId={project.id}
            userType={currentUserType}
            isInProgressProject={isInProgressProject}
          />
        ) : (
          <RequestRevisionButton
            project={project}
            isArtist={currentUserType === ProjectUserType.ARTIST}
            disabled={!allowTrackApproval}
            allowTrackApproval={allowTrackApproval}
            isInProgressProject={isInProgressProject}
          />
        )}
        {allowTrackApproval ? (
          <ApproveMixButton
            project={project}
            isArtist={currentUserType === ProjectUserType.ARTIST}
            isInProgressStep={isInProgressStep}
            isInProgressProject={isInProgressProject}
          />
        ) : (
          <CompletePaymentButton
            project={project}
            code={code}
            isPartiallyPaid={isPartiallyPaid}
            isCompletePaymentPopoverOpen={isCompletePaymentPopoverOpen}
            setIsCompletePaymentPopoverOpen={setIsCompletePaymentPopoverOpen}
            closeCompletePaymentPopover={closeCompletePaymentPopover}
            outstandingBalance={outstandingBalance}
          />
        )}
      </InProgressButtonRow>
    </MainProjectWorkflowButtonRow>
  );
};
