import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIProjectListenLink } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { CREATE_LISTEN_LINK } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { createListingLinkParams } from "./types";

const createListenLink = createAsyncThunk(
  CREATE_LISTEN_LINK,
  async (args: createListingLinkParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<
      APIProjectListenLink[]
    >(CREATE_LISTEN_LINK, args);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default createListenLink;
