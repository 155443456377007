import { StripeAddressElement, StripeElements } from "@stripe/stripe-js";
import { AddressElement } from "@stripe/react-stripe-js";

interface StripeAddress {
  name: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  phone?: string | undefined;
}

const validateAddressElementComplete = async (
  addressElement?: StripeAddressElement,
): Promise<[boolean, StripeAddress | undefined]> => {
  try {
    const addressElementValues = await addressElement?.getValue();
    if (!addressElementValues?.complete) {
      return [false, undefined];
    }
    const address: StripeAddress = addressElementValues.value;
    const phoneIsPresent = Boolean(address.phone);
    if (!phoneIsPresent) {
      return [false, undefined];
    }
    return [true, address];
  } catch {
    return [false, undefined];
  }
};

const isAddressFieldComplete = async (
  elements: StripeElements,
): Promise<[boolean, StripeAddress | undefined]> => {
  const addressElement = elements.getElement(AddressElement);
  if (!addressElement) {
    return [false, undefined];
  }
  return await validateAddressElementComplete(addressElement);
};

export { isAddressFieldComplete };
