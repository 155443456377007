import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { activeUserAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { fetchActiveStudios } from "../../api/recording/studio/fetchActiveStudios";
import { activeUserStudiosAtom } from "../../atoms/user/activeUserStudiosAtom";
import { assertEngineEarsErrorType } from "../../api/helpers";

const useActiveStudiosQuery = () => {
  const activeUser = useAtomValue(activeUserAtom);
  const setActiveUserStudios = useSetAtom(activeUserStudiosAtom);
  return useQuery({
    queryKey: [QUERY_KEYS.LOAD_STUDIOS, activeUser?.id],
    queryFn: async () => {
      return fetchActiveStudios()
        .then((activeStudios) => {
          setActiveUserStudios(activeStudios);
          return activeStudios;
        })
        .catch(() => {
          setActiveUserStudios([]);
        });
    },
    retry: false,
    enabled: Boolean(activeUser?.is_studio_manager),
  });
};

export default useActiveStudiosQuery;
