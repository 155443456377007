import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ResetPassword } from "../AccountSettingsModal/ResetPassword";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { Button } from "../../core-ui/components/Button/Button";
import { useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import { authModalOpenAtom } from "./atoms";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { AuthNavigation } from "./AuthNavigation";

export const AddPassword = () => {
  const theme = useTheme();
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Add a password</Text>
        <Text variant={TextStyleVariant.P1}>
          Add a password to secure your account and log in.
        </Text>
        <ResetPassword showHeader={false} onComplete={() => nextStep()} />
        <Button
          variant={ButtonVariant.UNSTYLED}
          style={{
            textDecoration: "underline",
            alignSelf: "flex-end",
            color: theme.palette.text.primary,
          }}
          onClick={() => {
            nextStep();
          }}
        >
          Remind me later
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
