import { Release, ReleaseType } from "../../../store/models/release";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import { createReleaseFromScheduledProject } from "../releaseAPI";
import {
  assertEngineEarsErrorType,
  displayEngineEarsError,
} from "../../helpers";

export const useReleaseBulkCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_RELEASE_FROM_SCHEDULED_PROJECT],
    mutationFn: async ({
      releaseType,
      scheduledProjectId,
    }: {
      releaseType: ReleaseType;
      scheduledProjectId: number;
    }) => {
      const response = await createReleaseFromScheduledProject(
        releaseType,
        scheduledProjectId,
      );
      if (!response.success) {
        throw response.resultJson;
      }
      // returning scheduledProjectId allows it to be used in onSuccess
      return { release: response.resultJson as Release, scheduledProjectId };
    },
    onError: (error) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
    onSuccess: async ({ scheduledProjectId }) => {
      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE,
          scheduledProjectId,
        ],
      });
    },
  });
};
