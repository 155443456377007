import { useAppSelector } from "../../../../store/hooks";
import { DetailedPurchaseOrder } from "../../../../store/models/project";
import { ScheduledProject } from "../../../../store/models/scheduledproject";
import {
  ContentRow,
  InnerContainer,
  OuterContainer,
  ProjectHeaderInnerContainerHeader,
  ProjectOverviewText,
} from "./ProjectHeader.styles";
import { useProjectHeaderHooks } from "./hooks/useProjectHeaderHooks";
import ProjectHeaderInfoBadge from "./ProjectHeaderInfoBadge";
import ProjectHeaderBreadCrumb from "./ProjectHeaderBreadCrumb";
import ProjectHeaderContent from "./ProjectHeaderContent";
import { ColoredSpan } from "../../FeaturedArtistRelease/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faLock } from "@fortawesome/pro-regular-svg-icons";
import { Text, TEXT_SIZE } from "../../../core-ui/components/Text/Text";
import { TextColor } from "../../../core-ui/components/Text/TextUtils";
import { Link } from "react-router-dom";
import { useFetchScheduledProjectReleaseQuery } from "../../../../api/releases/hooks/useFetchReleaseByScheduledProjectIdQuery";
import { getReleaseScreenRoute } from "../../../../store/utils/routeGetters";
import { useTheme } from "@mui/material";

interface ProjectHeaderProps {
  scheduledProject: ScheduledProject;
  purchaseOrder?: DetailedPurchaseOrder;
  code?: string;
}

export const ProjectHeader = ({
  scheduledProject,
  purchaseOrder,
  code,
}: ProjectHeaderProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const {
    isMobile,
    isTablet,
    serviceTypeSet,
    isPartiallyPaid,
    isProjectCancelled,
    redirectToPurchaseProject,
    isInProgressProject,
    userIsEngineerOnScheduledProject,
    userIsArtistOnScheduledProject,
    userIsCollaborator,
    disableDownloadAssets,
    disableSharingActions,
    numberOfCompletedTracks,
    completedTracksPercentage,
    estimatedCompletionDate,
    projectDuration,
  } = useProjectHeaderHooks(scheduledProject, user, code);
  const theme = useTheme();
  const { data: release } = useFetchScheduledProjectReleaseQuery(
    scheduledProject?.id,
  );

  return (
    <OuterContainer>
      <ProjectHeaderBreadCrumb
        scheduledProject={scheduledProject}
        code={code}
      />
      <InnerContainer>
        <ProjectHeaderInnerContainerHeader>
          <ProjectOverviewText>Project Overview</ProjectOverviewText>
          {release ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={getReleaseScreenRoute(release.id)}
            >
              <ColoredSpan
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                  minWidth: "unset",
                }}
                $backgroundColor={
                  release.published_at
                    ? theme.palette.standardColor.Green[200]
                    : theme.palette.standardColor.Gray[200]
                }
              >
                <Text
                  size={TEXT_SIZE.XS}
                  color={
                    release.published_at
                      ? TextColor.SUCCESS
                      : TextColor.SECONDARY
                  }
                >
                  {release.published_at ? "Public" : "Private"}
                </Text>
                <FontAwesomeIcon
                  size={"xs"}
                  color={
                    release.published_at
                      ? theme.palette.standardColor.Green[500]
                      : theme.palette.standardColor.Gray[500]
                  }
                  icon={release.published_at ? faLink : faLock}
                />
              </ColoredSpan>
            </Link>
          ) : null}
          <ProjectHeaderInfoBadge
            isInProgressProject={isInProgressProject}
            isPartiallyPaid={isPartiallyPaid}
            scheduledProject={scheduledProject}
            userIsEngineerOnScheduledProject={userIsEngineerOnScheduledProject}
            redirectToPurchaseProject={redirectToPurchaseProject}
          />
        </ProjectHeaderInnerContainerHeader>
        <ContentRow>
          <ProjectHeaderContent
            scheduledProject={scheduledProject}
            purchaseOrder={purchaseOrder}
            isProjectCancelled={isProjectCancelled}
            isInProgressProject={isInProgressProject}
            isPartiallyPaid={isPartiallyPaid}
            code={code}
            disableDownloadAssets={disableDownloadAssets}
            disableSharingActions={disableSharingActions}
            userIsCollaborator={userIsCollaborator}
            userIsArtistOnScheduledProject={userIsArtistOnScheduledProject}
            serviceTypeSet={serviceTypeSet}
            numberOfCompletedTracks={numberOfCompletedTracks}
            completedTracksPercentage={completedTracksPercentage}
            isTablet={isTablet}
            estimatedCompletionDate={estimatedCompletionDate}
            projectDuration={projectDuration}
            isMobile={isMobile}
          />
        </ContentRow>
      </InnerContainer>
    </OuterContainer>
  );
};
