import {
  DetailAndStatusRow,
  DetailsAndBudgetRow,
  ProjectHeaderAccordion,
  VerticalSeparator,
} from "./ProjectHeader.styles";
import DetailsBlockComponent from "./DetailsBlockComponent";
import StatusBlockComponent from "./StatusBlockComponent";
import ProgressBlockComponent from "./ProgressBlockComponent";
import BudgetBlockComponent from "./BudgetBlockComponent";
import { ScheduledProject } from "../../../../store/models/scheduledproject";
import { DetailedPurchaseOrder } from "../../../../store/models/project";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { Text } from "../../../core-ui/components/Text/Text";
import { useAtom } from "jotai";
import { projectHeaderExpandedAtom } from "../../../../atoms/projectHeaderExpanded";

interface ProjectContentProps {
  scheduledProject: ScheduledProject;
  purchaseOrder?: DetailedPurchaseOrder;
  isProjectCancelled: boolean;
  isInProgressProject: boolean;
  isPartiallyPaid: boolean;
  code?: string;
  disableDownloadAssets: boolean;
  disableSharingActions: boolean;
  userIsCollaborator: boolean;
  userIsArtistOnScheduledProject: boolean;
  serviceTypeSet: Set<string>;
  numberOfCompletedTracks: number;
  completedTracksPercentage: number;
  estimatedCompletionDate: string;
  projectDuration: string;
  isTablet: boolean;
  isMobile: boolean;
}

const ProjectHeaderContent = ({
  scheduledProject,
  purchaseOrder,
  isProjectCancelled,
  isInProgressProject,
  isPartiallyPaid,
  code,
  disableDownloadAssets,
  disableSharingActions,
  userIsCollaborator,
  userIsArtistOnScheduledProject,
  serviceTypeSet,
  numberOfCompletedTracks,
  completedTracksPercentage,
  estimatedCompletionDate,
  projectDuration,
  isTablet,
  isMobile,
}: ProjectContentProps) => {
  const [projectHeaderExpanded, setProjectHeaderExpanded] = useAtom(
    projectHeaderExpandedAtom,
  );

  if (isMobile) {
    return (
      <>
        <ProjectHeaderAccordion
          renderHeader={() => {
            return (
              <Text bold variant={TextStyleVariant.P1}>
                Project Details
              </Text>
            );
          }}
          expanded={projectHeaderExpanded}
          onChange={() => {
            setProjectHeaderExpanded(!projectHeaderExpanded);
          }}
        >
          <DetailsBlockComponent
            isProjectCancelled={isProjectCancelled}
            scheduledProject={scheduledProject}
            userIsCollaborator={userIsCollaborator}
          />
          <DetailsAndBudgetRow>
            <ProgressBlockComponent
              serviceTypeSet={serviceTypeSet}
              numberOfCompletedTracks={numberOfCompletedTracks}
              totalTracks={scheduledProject.projects.length}
              refunded={Boolean(scheduledProject.refunded)}
              completedTracksPercentage={completedTracksPercentage}
              estimatedCompletionDate={estimatedCompletionDate}
              projectDuration={projectDuration}
            />
            {purchaseOrder && (
              <>
                <VerticalSeparator />
                <BudgetBlockComponent purchaseOrder={purchaseOrder} />
              </>
            )}
          </DetailsAndBudgetRow>
        </ProjectHeaderAccordion>
        <StatusBlockComponent
          userIsArtistOnScheduledProject={userIsArtistOnScheduledProject}
          scheduledProject={scheduledProject}
          isProjectCancelled={isProjectCancelled}
          isInProgressProject={isInProgressProject}
          isPartiallyPaid={isPartiallyPaid}
          code={code}
          disableDownloadAssets={disableDownloadAssets}
          disableSharingActions={disableSharingActions}
        />
      </>
    );
  }

  if (isTablet) {
    return (
      <>
        <DetailAndStatusRow
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DetailsBlockComponent
            isProjectCancelled={isProjectCancelled}
            scheduledProject={scheduledProject}
            userIsCollaborator={userIsCollaborator}
          />
          <StatusBlockComponent
            userIsArtistOnScheduledProject={userIsArtistOnScheduledProject}
            scheduledProject={scheduledProject}
            isProjectCancelled={isProjectCancelled}
            isInProgressProject={isInProgressProject}
            isPartiallyPaid={isPartiallyPaid}
            code={code}
            disableDownloadAssets={disableDownloadAssets}
            disableSharingActions={disableSharingActions}
          />
        </DetailAndStatusRow>
        <DetailsAndBudgetRow>
          <ProgressBlockComponent
            serviceTypeSet={serviceTypeSet}
            numberOfCompletedTracks={numberOfCompletedTracks}
            totalTracks={scheduledProject.projects.length}
            refunded={Boolean(scheduledProject.refunded)}
            completedTracksPercentage={completedTracksPercentage}
            estimatedCompletionDate={estimatedCompletionDate}
            projectDuration={projectDuration}
          />
          {purchaseOrder && (
            <>
              <VerticalSeparator />
              <BudgetBlockComponent purchaseOrder={purchaseOrder} />
            </>
          )}
        </DetailsAndBudgetRow>
      </>
    );
  }

  return (
    <>
      <DetailsBlockComponent
        isProjectCancelled={isProjectCancelled}
        scheduledProject={scheduledProject}
        userIsCollaborator={userIsCollaborator}
      />
      <DetailsAndBudgetRow>
        <ProgressBlockComponent
          serviceTypeSet={serviceTypeSet}
          numberOfCompletedTracks={numberOfCompletedTracks}
          totalTracks={scheduledProject.projects.length}
          refunded={Boolean(scheduledProject.refunded)}
          completedTracksPercentage={completedTracksPercentage}
          estimatedCompletionDate={estimatedCompletionDate}
          projectDuration={projectDuration}
        />
        {purchaseOrder && (
          <>
            <VerticalSeparator />
            <BudgetBlockComponent purchaseOrder={purchaseOrder} />
          </>
        )}
      </DetailsAndBudgetRow>
      <StatusBlockComponent
        userIsArtistOnScheduledProject={userIsArtistOnScheduledProject}
        scheduledProject={scheduledProject}
        isProjectCancelled={isProjectCancelled}
        isInProgressProject={isInProgressProject}
        isPartiallyPaid={isPartiallyPaid}
        code={code}
        disableDownloadAssets={disableDownloadAssets}
        disableSharingActions={disableSharingActions}
      />
    </>
  );
};

export default ProjectHeaderContent;
