import { useAtomValue } from "jotai";
import {
  ChangeEvent,
  EventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { queryStringAtom } from "../../../../atoms/searchQueryAtom";
import useSearch from "../../../../hooks/searchHooks/useSearch";
import { SCREENS } from "../../../../routes/screens";
import { NavSearchBar } from "../../../components/Navigation/NavSearchBar/NavSearchBar";
import { HomeSearchBar } from "../../../screens/HomeScreen/components/SearchPanel/HomeSearchBar";

export enum SitewideSearchBarVariant {
  NAVBAR = "navbar",
  HOMEPAGE = "homepage",
}

interface NavSearchResultsDropDownProps {
  variant?: SitewideSearchBarVariant;
}

/**
 * Contains logic for fetching site-wide search results including Engineers and
 * Studios.
 * This also contains logic for handling similarly controlled Search Bar
 * components with attached dropdown autocomplete search results. For example
 * the Search Bar in the Navigation Header and the Home Page.
 */
export const SitewideSearchBar = ({
  variant = SitewideSearchBarVariant.NAVBAR,
}: NavSearchResultsDropDownProps) => {
  const {
    searchTerm,
    setSearchTerm,
    autocompleteSuggestions,
    isFetchingAutocomplete,
    isFetchingMapOnlyDataOnEmptyList,
  } = useSearch({
    enabled: true,
  });
  const [showResultsDropDown, setShowResultsDropDown] = useState(false);
  const history = useHistory();
  const searchQueryString = useAtomValue(queryStringAtom);

  const onInputFocus = useCallback(() => {
    setShowResultsDropDown(true);
  }, [setShowResultsDropDown]);

  const onInputBlur = useCallback(() => {
    setTimeout(() => setShowResultsDropDown(false), 100);
  }, [setShowResultsDropDown]);

  const handleSearchQuery: EventHandler<SyntheticEvent<HTMLFormElement>> = (
    event,
  ) => {
    event.preventDefault();
    setShowResultsDropDown(false);
    history.push(`${SCREENS.SEARCH}?${searchQueryString}`);
  };

  const handleSearchOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchQuery = event.currentTarget.value;
      setSearchTerm(searchQuery);
      if (!searchQuery) {
        setShowResultsDropDown(false);
      } else {
        setShowResultsDropDown(true);
      }
    },
    [setSearchTerm, setShowResultsDropDown],
  );

  const onListItemClick = useCallback(() => {
    setShowResultsDropDown(false);
  }, [setShowResultsDropDown]);

  return variant === SitewideSearchBarVariant.NAVBAR ? (
    <NavSearchBar
      autocompleteSuggestions={autocompleteSuggestions}
      handleSearchQuery={handleSearchQuery}
      isFetchingAutocomplete={
        isFetchingAutocomplete || isFetchingMapOnlyDataOnEmptyList
      }
      onBlur={onInputBlur}
      onChange={handleSearchOnChange}
      onFocus={onInputFocus}
      onListItemClick={onListItemClick}
      searchTerm={searchTerm}
      setShowResultsDropDown={setShowResultsDropDown}
      showResultsDropDown={showResultsDropDown}
    />
  ) : (
    <HomeSearchBar
      autocompleteSuggestions={autocompleteSuggestions}
      handleSearchQuery={handleSearchQuery}
      isFetchingAutocomplete={
        isFetchingAutocomplete || isFetchingMapOnlyDataOnEmptyList
      }
      onBlur={onInputBlur}
      onChange={handleSearchOnChange}
      onFocus={onInputFocus}
      onListItemClick={onListItemClick}
      searchTerm={searchTerm}
      showResultsDropDown={showResultsDropDown}
    />
  );
};
