export enum TRACKING_EVENTS_NAME {
  USER_CLICK_FAVORITED_ACCOUNT = "user_click_favorited_account",
  USER_CLICK_SAVE_PROFILE = "user_click_save_profile",
  USER_OPEN_DIRECT_MESSAGE = "user_open_direct_message",
  USER_CLICK_PROFILE_CARD = "user_click_profile_card",
  USER_UPDATE_COVER_PHOTO = "user_update_cover_photo",
  USER_CLICK_PRODUCT_CHECKOUT = "user_click_product_checkout",
  USER_CLICK_PLAY_PROJECT = "user_click_play_project",
  USER_CLICK_SHARE_RELEASE = "user_click_share_release",
  USER_CLICK_OPEN_ACCOUNT_SETTINGS = "user_click_open_account_settings",
  USER_CLICK_SOCIAL_MEDIA_LINK = "user_click_social_media_link",
  USER_CLICK_EDIT_SOCIAL_MEDIA_LINKS = "user_click_edit_social_media_links",
  USER_CLICK_GENERATE_BOOKING = "user_click_generate_booking",
  USER_CLICK_BOOK_NOW = "user_click_book_now_click",
  USER_GENERATING_TRANSACTION = "user_generating_transaction",
  USER_CLICK_ADD_OR_EDIT_SERVICE = "user_click_add_or_edit_service",
  USER_CLICK_PAYWALL_OPTION = "user_click_paywall_option",
  USER_CLICK_SAVE_ASSET = "user_click_save_asset",
  USER_CLICK_THUMBNAIL_ASSET = "user_click_thumbnail_asset",
  USER_CLICK_CANCEL_ASSET = "user_click_cancel_asset",
  SYSTEM_SUBMIT_FORM = "system_submit_form",
  USER_CLOSE_DIALOG = "user_close_dialog",
  USER_VISIT_PROFILE = "user_visit_profile",
  OPEN_UMG_LOGIN = "open_umg_login",
  MARK_PAID_REVISION = "mark_paid_revision",
  MARK_PAID_SESSION_EXTENSION = "mark_paid_session_extension",
  MARK_PAID_SCHEDULED_PROJECT = "mark_paid_scheduled_project",
  USER_SEARCH = "user_search",
  STUDIO_ROOM_SEARCH = "studio_room_search",
  USER_SEARCH_QUICK_FILTER_SELECTION = "search_quick_filter_selection",
  APPLY_SEARCH_FILTERS = "apply_search_filters",
  CLICK_TRENDING_ENGINEERS_GET_STARTED = "clicked_trending_engineers_get_started",
  CLICK_TRENDING_STUDIO_ROOMS_JOIN_NOW = "clicked_trending_studio_rooms_join_now",
  CLICK_HOME_POPULAR_SEARCH_TRENDING_ENGINEERS = "homepage_popular_search_trending_engineers_click",
  CLICK_HOME_POPULAR_SEARCH_STUDIOS = "homepage_popular_search_studios_click",
  CLICK_HOME_POPULAR_SEARCH_DOLBY_ATMOS = "homepage_popular_search_dolby_atmos_click",
  CLICK_HOME_POPULAR_SERVICE_RECORDING_STUDIOS = "homepage_popular_service_recording_studios_click",
  CLICK_HOME_POPULAR_SERVICE_RECORDING_ENGINEERS = "homepage_popular_service_recording_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_MIXING_ENGINEERS = "homepage_popular_service_mixing_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_MASTERING_ENGINEERS = "homepage_popular_service_mastering_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_DOLBY_ATMOS_ENGINEERS = "homepage_popular_service_atmos_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_MADE_ON_ENGINEEARS = "homepage_popular_service_made_on_engineears_click",
  CLICK_HOME_PERSONA_PAGE_LINK = "clicked_homepage_persona_page_link",
  CLICK_HOME_TRENDING_ENGINEERS_VIEW_MORE = "clicked_homepage_trending_engineers_view_more",
  CLICK_HOME_TRENDING_STUDIO_ROOMS_VIEW_MORE = "clicked_homepage_trending_studio_rooms_view_more",
  CLICK_HOME_PLATINUM_LEARN_MORE = "clicked_homepage_platinum_learn_more",
  CLICK_HOME_PLATINUM_GET_STARTED = "clicked_home_platinum_get_started",
  CLICK_HOME_MADE_ON_ENGINEEARS_GET_STARTED = "clicked_home_made_on_engineears_get_started",
  CLICK_HOME_MADE_ON_ENGINEEARS_FIND_MY_ENGINEER = "clicked_home_made_on_engineers_find_my_engineer",
  CLICK_HOME_MADE_ON_ENGINEEARS_SCHEDULE_CONSULTATION = "landing_screen_open_artist_calendly_link",
  CLICK_HOME_MADE_ON_ENGINEEARS_TRACK_CARD = "clicked_home_made_on_engineers_track_card",
}
