import { GET_ACTIVE_STUDIOS } from "../../../store/utils/routes";
import { ProfileType } from "../../../store/models/base";
import { SlimActiveStudio } from "../../../store/models/studio";
import { makeBackendGetCallWithJsonResponse } from "../../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../../helpers";

export const fetchActiveStudios = async () => {
  return makeBackendGetCallWithJsonResponse<SlimActiveStudio[]>(
    GET_ACTIVE_STUDIOS,
    "",
  )
    .then(promiseEngineEarsHandler)
    .then((resultJson) => {
      const transformedData: SlimActiveStudio[] = resultJson.map((value) => {
        return { ...value, type: ProfileType.STUDIO };
      });
      return transformedData;
    });
};
