import { FC, useRef, useState } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { useAppSelector } from "../../../store/hooks";
import { FileType } from "../../../store/models/fileVersion";
import {
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectById,
  ProjectType,
  ProjectUserType,
} from "../../../store/models/project";
import { SessionSteps } from "../../../store/models/recordingSession";
import {
  isCurrentUserProjectArtist,
  isCurrentUserProjectEngineer,
} from "../../../store/selectors/projectSelector";
import { isProjectInReviewStepOrBeyond } from "../../../utils/projectUtils";
import threeDots from "../../assets/threeDotsVertical.svg";
import ArtistReviewFileDownloadButton from "../ArtistReviewFileDownload/ArtistReviewFileDownloadButton";
import { ListenLinkGenerator } from "../ListenLinkGenerator/ListenLinkGenerator";
import { StyledTrackTableOptionsContainer } from "./TrackTableOptions.styles";
import "./TrackTableOptionsButton.css";

export interface TrackTableOptionsButtonProps {
  project?: ProjectById;
  setShowPreviousMixes: () => void;
  showPreviousMixes: boolean;
  previousMixesAvailable: boolean;
  step?: MixingProjectSteps | MasteringProjectSteps | SessionSteps;
  userType?: ProjectUserType | null;
  handleUpload:
    | ((reference: boolean, uploadType: FileType.MP4 | FileType.WAV) => void)
    | undefined;
  code?: string | null;
  canAddComment?: boolean;
}

export const TrackTableOptionsButtons: FC<TrackTableOptionsButtonProps> = ({
  project,
  setShowPreviousMixes,
  showPreviousMixes,
  previousMixesAvailable,
  step,
  userType,
  handleUpload,
  code,
  canAddComment = true,
}) => {
  const isDolbyMix = project?.service_type === ProjectType.ATMOS_MIXING;
  const containerRef = useRef<HTMLDivElement>(null);
  const [showPopOver, setShowPopOver] = useState<boolean>(false);
  const isEngineer =
    useAppSelector(isCurrentUserProjectEngineer(project?.id ?? -1)) ||
    userType === ProjectUserType.ENGINEER;
  const isArtist =
    useAppSelector(isCurrentUserProjectArtist(project?.id ?? -1)) ||
    userType === ProjectUserType.ARTIST;
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const isSuperUser = Boolean(loggedInUser?.is_superuser);
  const showUploadOptions = isEngineer || isSuperUser;

  useClickOutside(containerRef, () => setShowPopOver(false));
  const canShareMix = isArtist || isEngineer || code;
  if (!isArtist && !isEngineer && !isSuperUser && !code) return null;
  if (!canAddComment && !isSuperUser && !code) return null;
  if (!handleUpload && !isSuperUser && !code) return null;

  if (
    canShareMix &&
    isProjectInReviewStepOrBeyond(project?.service_type, step)
  ) {
    return (
      <StyledTrackTableOptionsContainer>
        <ArtistReviewFileDownloadButton
          project={project}
          step={step}
          code={code}
        />
        <ListenLinkGenerator project={project} step={step} />
      </StyledTrackTableOptionsContainer>
    );
  }

  if (!handleUpload) return null;

  return (
    <div
      ref={containerRef}
      onClick={() => setShowPopOver(!showPopOver)}
      className="track-table-options-container"
    >
      <div className={"pop-over-relative-container"}>
        <img src={threeDots} alt={"track table options"} />
        <div
          className={"track-table-menu card-container ".concat(
            showPopOver ? "show" : "hide",
          )}
        >
          <div className="track-table-option">
            <ListenLinkGenerator project={project} />
          </div>
          {showUploadOptions && (
            <div
              className="track-table-option"
              onClick={() => {
                //   handle reference upload
                handleUpload(true, FileType.WAV);
              }}
            >
              <p className="b1-semi-bold">Upload Reference</p>
            </div>
          )}
          {showUploadOptions && (
            <div
              className="track-table-option"
              onClick={() => {
                handleUpload(false, FileType.WAV);
              }}
            >
              <p className="b1-semi-bold">Upload Main</p>
            </div>
          )}
          {showUploadOptions && isDolbyMix && (
            <div
              className="track-table-option"
              onClick={() => {
                handleUpload(false, FileType.MP4);
              }}
            >
              <p className="b1-semi-bold">Re-Upload Main MP4</p>
            </div>
          )}
          {showUploadOptions && previousMixesAvailable && (
            <div className="track-table-option" onClick={setShowPreviousMixes}>
              <p className="b1-semi-bold">{`${showPreviousMixes ? "Hide" : `Show`} previous versions`}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
