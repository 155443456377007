import { Box, styled } from "@mui/material";

export const SelectSubscriptionScreenContainer = styled(Box)<{
  $isAuthenticated: boolean;
}>(($isAuthenticated) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "64px",
  marginTop: $isAuthenticated ? "48px" : "0px",
  boxSizing: "border-box",
  overflow: "hidden",
}));

export const SelectSubscriptionScreenHeaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: "24px",

  ".MuiTypography-root": {
    textAlign: "center",
  },
}));

export const SelectSubscriptionCardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: "32px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const StickyButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "175px",
  marginLeft: "310px",

  // Using custom breakpoint temporarily until table is redone and can figure out how to align easier
  [theme.breakpoints.down(992)]: {
    gap: "50px",
    marginLeft: "250px",
  },

  [theme.breakpoints.down("md")]: {
    justifyContent: "space-evenly",
    gap: "0",
    marginLeft: "0",
  },
}));
