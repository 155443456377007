import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import engMasteringTransition from "../../../../../../store/actions/projects/engMasteringTransition";
import engMixTransition from "../../../../../../store/actions/projects/engMixTransition";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectType,
} from "../../../../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../../../core-ui/components/BasePopover/PopoverContainerContext";
import { PopConfirm } from "../../../../../core-ui/components/PopConfirm/PopConfirm";
import { DropdownContext } from "../../../../../core-ui/components/Select/Select";
import { DropdownMenuItem } from "../../../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown.styles";

export interface ProjectActionSkipFileUploadProps {
  projectId: number | null | undefined;
  projectType: ProjectType;
}

/**
 * Action Option to skip the file upload step during a Mixing or Mastering project workflow
 */
export const ProjectActionSkipFileUpload = ({
  projectId,
  projectType,
}: ProjectActionSkipFileUploadProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.accountInfo.user?.id);
  const dropdownContext = useContext(DropdownContext);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const skipUploadStep = useCallback(async () => {
    emitAnalyticsTrackingEvent("engineer_skip_upload", {}, userId);

    // This shouldn't happen, but just in case
    if (!projectId) {
      toast.error("Project ID is missing");
      return;
    }
    const params = { project_id: projectId.toString() };

    if (projectType === ProjectType.MASTERING) {
      // Mastering Transition
      await dispatch(
        engMasteringTransition({
          ...params,
          transition: MasteringTransitions.ENGINEER_SKIP_UPLOAD_FILES,
        }),
      )
        .unwrap()
        .catch(() => toast.error("Failed to skip upload files"));
    } else {
      // Mixing Transition
      await dispatch(
        engMixTransition({
          ...params,
          transition: MixingTransitions.ENGINEER_SKIP_UPLOAD_FILES,
        }),
      )
        .unwrap()
        .catch(() => toast.error("Failed to skip upload files"));
    }

    dropdownContext?.closeDropdownMenu();
    return;
  }, [projectType, dispatch, projectId, userId, dropdownContext]);

  return (
    <PopConfirm
      side="top"
      title="Are you sure?"
      description="This will notify the client and move the project status to 'In Progress'."
      onConfirm={skipUploadStep}
      wrapperElement={containerElement}
    >
      <DropdownMenuItem onSelect={(e: Event) => e.preventDefault()}>
        I already have project files
      </DropdownMenuItem>
    </PopConfirm>
  );
};
