import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { usePaymentPlanStatus } from "../../../hooks/partialPaymentHooks";
import useModal from "../../../hooks/useModal";
import {
  manageScheduledProject,
  ProjectManagementActions,
} from "../../../store/actions/booking";
import downloadAllFilesForScheduledProject, {
  downloadAllFilesParams,
} from "../../../store/actions/projects/downloadAllFilesForScheduledProject";
import { getTransactionInvoiceUrl } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getMyBookingsRoute } from "../../../store/utils/routeGetters";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Select } from "../../core-ui/components/Select/Select";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { BaseModal as OldModal } from "../BaseModal/BaseModal";
import { FeedbackModal } from "../FeedbackModal/FeedbackModal";
import { ShareLinkModal } from "../ShareLinkModal/ShareLinkModal";
import { DeleteModalText } from "../ShareOrDeleteInProgressProject/ShareOrDeleteInProgressProject.styles";
import { DropdownMenuItem } from "./ScheduledProjectActionsDropdown.styles";
import { EditReleaseModal } from "../ProjectOverviewHeaderReleaseButton/EditReleaseModal";
import { Release } from "../../../store/models/release";

interface ScheduledProjectActionsDropdownProps {
  scheduledProjectId: number;
  shareLinkCode?: string;
  disablePageSharing: boolean;
  disableDownloadInvoice: boolean;
  disableDownloadAllFiles: boolean;
  isInProgressProject?: boolean;
  isPartiallyPaid?: boolean;
  showCompletePaymentOption?: boolean;
  showOptionsText?: boolean;
  showEditRelease?: boolean;
  release?: Release | null;
}

export const ScheduledProjectActionsDropdown = ({
  scheduledProjectId,
  shareLinkCode,
  disablePageSharing,
  disableDownloadInvoice,
  disableDownloadAllFiles,
  showCompletePaymentOption = false,
  isInProgressProject = false,
  isPartiallyPaid = false,
  showOptionsText = false,
  showEditRelease = false,
  release,
}: ScheduledProjectActionsDropdownProps) => {
  const {
    isOpen: isPageSharingModalOpen,
    closeModal: closePageSharingModal,
    openModal: openPageSharingModal,
  } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const {
    isOpen: isCancelProjectModalOpen,
    openModal: openCancelProjectModal,
    closeModal: closeCancelProjectModal,
  } = useModal();
  const {
    isOpen: isFeedbackModalOpen,
    openModal: openFeedbackModal,
    closeModal: closeFeedbackModal,
  } = useModal();
  const {
    isOpen: isShowingEditReleaseModal,
    setIsOpen: setEditReleaseModalOpen,
  } = useModal();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { downloadingAllTracks: downloadingAllTracksLoading } = useAppSelector(
    (state) => state.projectsStore,
  );
  const { getTransactionInvoiceUrlLoading } = useAppSelector(
    (state) => state.transactionStore,
  );
  const scheduledProject = useAppSelector(
    (state) => state.scheduledProjectsStore.scheduledProject,
  );
  const isProjectRequestAccepted = Boolean(scheduledProject?.accepted);
  const isAnyProjectComplete =
    scheduledProject?.projects.some((project) => Boolean(project.completed)) ??
    false;

  const { redirectToPurchaseProject } = usePaymentPlanStatus(
    shareLinkCode,
    scheduledProjectId,
    undefined,
    isPartiallyPaid,
  );

  const deleteInProgressProject = () => {
    setLoading(true);
    dispatch(
      manageScheduledProject({
        action: ProjectManagementActions.DeleteInProgressProject,
        scheduled_project_id: scheduledProjectId,
      }),
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        closeDeleteModal();
        history.replace(getMyBookingsRoute("projects"));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDownloadAllFiles = () => {
    toast.info(
      "Your download request has been initiated. Please wait a few moments!",
    );
    const args: downloadAllFilesParams = {
      scheduledProjectId: scheduledProjectId.toString(),
    };
    if (shareLinkCode) {
      args.shareLinkCode = shareLinkCode;
    }

    dispatch(downloadAllFilesForScheduledProject(args)).catch(() => {
      toast.error(
        "Something went wrong. Reach out to us for support with your project download.",
      );
    });
  };

  const onClickDownloadInvoice = () => {
    dispatch(
      getTransactionInvoiceUrl({
        scheduled_project_id: scheduledProjectId,
        share_link_code: shareLinkCode,
      }),
    )
      .unwrap()
      .then(({ hosted_invoice_url: hostedInvoiceUrl }) => {
        window.open(hostedInvoiceUrl, "_blank");
      })
      .catch(() => {
        toast.error(
          "Something went wrong. Reach out to us for support with your invoice download.",
        );
      });
  };

  return (
    <>
      <Select
        triggerButton={
          <Button
            labelIcon={
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                size={showOptionsText ? undefined : "lg"}
              />
            }
            variant={ButtonVariant.OUTLINED}
          >
            {showOptionsText ? "Options" : null}
          </Button>
        }
      >
        {showEditRelease && (
          <DropdownMenuItem
            disabled={!release}
            onSelect={() => {
              setEditReleaseModalOpen(true);
            }}
          >
            Edit Release
          </DropdownMenuItem>
        )}
        {!isInProgressProject && !shareLinkCode && (
          <DropdownMenuItem
            onSelect={openPageSharingModal}
            disabled={disablePageSharing}
          >
            Share this page
          </DropdownMenuItem>
        )}
        {showCompletePaymentOption && (
          <DropdownMenuItem onSelect={redirectToPurchaseProject}>
            Complete payment
          </DropdownMenuItem>
        )}
        {isInProgressProject && !shareLinkCode && (
          <DropdownMenuItem
            disabled={!isInProgressProject}
            onSelect={openDeleteModal}
          >
            Delete Project
          </DropdownMenuItem>
        )}
        {isAnyProjectComplete && (
          <DropdownMenuItem
            disabled={disableDownloadAllFiles}
            onSelect={handleDownloadAllFiles}
          >
            <span>Download assets</span>
            {downloadingAllTracksLoading && (
              <SoundWaveLoader width={50} height={12} />
            )}
          </DropdownMenuItem>
        )}
        {!shareLinkCode && (
          <DropdownMenuItem
            disabled={disableDownloadInvoice}
            onSelect={onClickDownloadInvoice}
          >
            <span>Download invoice</span>
            {getTransactionInvoiceUrlLoading && (
              <SoundWaveLoader width={50} height={12} />
            )}
          </DropdownMenuItem>
        )}
        {!isInProgressProject && !shareLinkCode && (
          <DropdownMenuItem onSelect={openCancelProjectModal}>
            <span>
              {`Cancel ${isProjectRequestAccepted ? "project" : "request"}`}
            </span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onSelect={openFeedbackModal}>
          Report user
        </DropdownMenuItem>
      </Select>
      {isCancelProjectModalOpen && (
        <FeedbackModal onClose={closeCancelProjectModal} cancelProject />
      )}
      <OldModal
        modalIsOpen={isPageSharingModalOpen}
        closeModal={closePageSharingModal}
        label={"share mix modal"}
      >
        <ShareLinkModal
          scheduledProjectId={scheduledProjectId.toString()}
          closeModal={closePageSharingModal}
        />
      </OldModal>
      <BaseModal
        open={isDeleteModalOpen}
        setOpen={closeDeleteModal}
        header="Delete In-Progress Project"
        onConfirm={deleteInProgressProject}
        onCancel={closeDeleteModal}
        loading={loading}
        showModalFooter
      >
        <DeleteModalText>
          Are you sure you want to delete this in-progress project? This action
          cannot be undone.
        </DeleteModalText>
      </BaseModal>
      {release && (
        <EditReleaseModal
          release={release}
          open={isShowingEditReleaseModal}
          scheduledProjectId={scheduledProjectId}
          setOpen={setEditReleaseModalOpen}
        />
      )}
      {isFeedbackModalOpen && <FeedbackModal onClose={closeFeedbackModal} />}
    </>
  );
};
