import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { Project, ProjectById } from "../models/project";
import { selectCurrentUser } from "./userInfoSelectors";

const selectProjectStore = (state: RootState) => state.projectsMapStore;
const selectRecordingStore = (state: RootState) =>
  state.recordingSessionReducer;

const getProject = (project_id: number) =>
  createDraftSafeSelector(selectProjectStore, (projectStore) => {
    return projectStore.projects[project_id];
  });

export const isCurrentUserProjectEngineer = (project_id: number) =>
  createDraftSafeSelector(
    selectCurrentUser,
    getProject(project_id),
    (user, project) => {
      return user?.id === project?.engineer?.id;
    },
  );

export const isCurrentUserProjectArtist = (project_id: number) =>
  createDraftSafeSelector(
    selectCurrentUser,
    getProject(project_id),
    (user, project) => {
      if (!user) return false;
      if (!project) return false;
      if (project.mastering_project) {
        return user?.id === project.mastering_project.artist_id;
      }
      if (project.mixing_project) {
        return user?.id === project.mixing_project.artist_id;
      }
    },
  );

export const getProjectCollaborator = (project_id: number) =>
  createDraftSafeSelector(
    selectCurrentUser,
    getProject(project_id),
    (user, project) => {
      const users = project?.users || [];
      return users.find((u) => u.id !== user?.id);
    },
  );

export const getRecordingSessionIfNotMixMaster = (
  mixOrMaster: Project | ProjectById | undefined,
) =>
  createDraftSafeSelector(selectRecordingStore, (recordingStore) => {
    if (mixOrMaster) return null;
    return recordingStore.recordingSession;
  });
