import { Elements } from "@stripe/react-stripe-js";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTransactionTotalAsPennies } from "../../../../hooks/transactionHook";
import useModal from "../../../../hooks/useModal";
import { checkoutRevisions } from "../../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProjectById } from "../../../../store/models/project";
import { Transaction } from "../../../../store/models/transaction";
import { PennyDollarFormatter } from "../../../../store/utils/formatUtils";
import { stripePromise } from "../../../../store/utils/stripe";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { LoginRequiredPopover } from "../../../core-ui/components/LoginRequiredPopover/LoginRequiredPopover";
import { Text } from "../../../core-ui/components/Text/Text";
import {
  StripePaymentForm,
  StripePaymentFormHandles,
} from "../../StripePaymentForm/StripePaymentForm";
import { ApproveMixPopoverContent } from "../InProgressTransitionView.styles";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "../../../../atoms/user/darkModeAtom";

interface PurchaseRevisionButtonProps {
  project: ProjectById;
  disabled?: boolean;
  isArtist: boolean;
  isInProgressProject?: boolean;
}

/**
 * Updated version of the `ArtistApproveRejectMix` component.
 * Updated version of the `PurchaseRevisionButton` component.
 * Updated version of the `RevisionsStripeCheckout` component.
 */
export const PurchaseRevisionButton = ({
  project,
  disabled = false,
  isArtist,
  isInProgressProject = false,
}: PurchaseRevisionButtonProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingTransaction, setPendingTransaction] =
    useState<Transaction | null>(null);
  const amount = useTransactionTotalAsPennies(pendingTransaction);
  const amountInDollars = amount / 100;
  const darkMode = useAtomValue(darkModeAtom);
  const stripePaymentFormRef = useRef<StripePaymentFormHandles>(null);
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  enum PurchaseRevisionPopoverVariant {
    PAYMENT,
    PURCHASE,
  }
  const [popoverVariant, setPopoverVariant] =
    useState<PurchaseRevisionPopoverVariant>(
      PurchaseRevisionPopoverVariant.PURCHASE,
    );

  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  const handlePrepareCheckout = () => {
    setIsLoading(true);
    dispatch(
      checkoutRevisions({
        project_id: project.id,
        pay_with_purchase_order: false,
        number_of_revisions: 1,
      }),
    )
      .unwrap()
      .then((transaction) => setPendingTransaction(transaction))
      .catch(() =>
        toast.error("Something went wrong, reach out to customer support"),
      )
      .finally(() => {
        emitAnalyticsTrackingEvent("clicked_buy_extra_revision", {}, user?.id);
        setIsLoading(false);
        setPopoverVariant(PurchaseRevisionPopoverVariant.PAYMENT);
      });
  };

  const handleSubmitPayment = async () => {
    try {
      setIsLoading(true);
      await stripePaymentFormRef.current?.handleSubmit();
      toast.success("Revision purchased successfully");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while processing your payment");
      }
    } finally {
      setIsLoading(false);
      closePopover();
    }
  };

  const popoverProps = {
    [PurchaseRevisionPopoverVariant.PAYMENT]: {
      title: "Purchase revision",
      buttonText: "Purchase revision",
      content: (
        <ApproveMixPopoverContent>
          <Elements
            stripe={stripePromise}
            options={{
              mode: "payment",
              currency: "usd",
              amount,
              paymentMethodTypes: ["card"],
              appearance: {
                theme: darkMode ? "night" : "stripe",
              },
            }}
          >
            {pendingTransaction && (
              <StripePaymentForm
                ref={stripePaymentFormRef}
                transaction={pendingTransaction}
                isRevisionPurchase
              />
            )}
          </Elements>
        </ApproveMixPopoverContent>
      ),
      onCancel: () => {
        emitAnalyticsTrackingEvent(
          "clicked_back_from_buying_extra_revision",
          {},
          user?.id,
        );
        setPopoverVariant(PurchaseRevisionPopoverVariant.PURCHASE);
      },
      onConfirm: handleSubmitPayment,
      okProps: {
        disabled: isLoading,
        loading: isLoading,
      },
      okText: `Checkout (${PennyDollarFormatter().format(amountInDollars)})`,
    },
    [PurchaseRevisionPopoverVariant.PURCHASE]: {
      title: "You've run out of included revisions!",
      buttonText: "Purchase revision",
      content: (
        <ApproveMixPopoverContent>
          <Text>
            If you&apos;d like another revision, each extra revision is{" "}
            {`${PennyDollarFormatter().format(project.extra_revision_price)}`}.
            Confirm to enter your payment information.
          </Text>
        </ApproveMixPopoverContent>
      ),
      onCancel: undefined,
      onConfirm: handlePrepareCheckout,
      okProps: {
        disabled: isLoading,
        loading: isLoading,
      },
      okText: "Confirm",
    },
  };

  if (user && !isArtist && !isInProgressProject) {
    return (
      <Button
        variant={ButtonVariant.DISABLED}
        fullWidth
        onClick={() => {
          toast.error(
            `Only the account that booked the project can officially purchase a revision`,
          );
        }}
      >
        {popoverProps[popoverVariant].buttonText}
      </Button>
    );
  }

  return (
    <LoginRequiredPopover
      isOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      closePopover={closePopover}
      disableAutoClose
      side="top"
      title={popoverProps[popoverVariant].title}
      description=""
      additionalContent={popoverProps[popoverVariant].content}
      okButtonProps={popoverProps[popoverVariant].okProps}
      okText={popoverProps[popoverVariant].okText}
      onCancel={popoverProps[popoverVariant].onCancel}
      onConfirm={popoverProps[popoverVariant].onConfirm}
      wrapperElement={containerElement}
      authConfirmDescription="In order to officially purchase a revision, please sign in to the account that originally booked the project."
    >
      <Button
        fullWidth
        disabled={isLoading || disabled}
        loading={isLoading}
        variant={ButtonVariant.OUTLINED}
      >
        {popoverProps[popoverVariant].buttonText}
      </Button>
    </LoginRequiredPopover>
  );
};
