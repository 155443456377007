import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faClock,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import {
  OverviewHeaderDropDownSection,
  StatusBlock,
} from "./ProjectHeader.styles";
import { ScheduledProject } from "../../../../store/models/scheduledproject";
import { ShareToClientButton } from "../../ShareToClientButton/ShareToClientButton";
import { ProjectChatPanel } from "../../ProjectChatPanel/ProjectChatPanel";
import { ScheduledProjectActionsDropdown } from "../../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown";
import ProjectOverviewHeaderReleaseButton from "../../ProjectOverviewHeaderReleaseButton/ProjectOverviewHeaderReleaseButton";
import { useTotalNumberOfSongsToDetermineReleaseTypeNotCountingPrerequisiteProjects } from "../../../../store/models/release";
import { useFetchScheduledProjectReleaseQuery } from "../../../../api/releases/hooks/useFetchReleaseByScheduledProjectIdQuery";
import { Text } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import { useTheme } from "@mui/material";

interface StatusBlockComponentProps {
  scheduledProject: ScheduledProject;
  isInProgressProject: boolean;
  isPartiallyPaid: boolean;
  isProjectCancelled: boolean;
  code?: string;
  disableDownloadAssets: boolean;
  disableSharingActions: boolean;
  userIsArtistOnScheduledProject: boolean;
}

const StatusBlockComponent = ({
  scheduledProject,
  isInProgressProject,
  isPartiallyPaid,
  isProjectCancelled,
  code,
  disableDownloadAssets,
  disableSharingActions,
  userIsArtistOnScheduledProject,
}: StatusBlockComponentProps) => {
  const { data: release } = useFetchScheduledProjectReleaseQuery(
    scheduledProject?.id,
  );
  const totalSongs =
    useTotalNumberOfSongsToDetermineReleaseTypeNotCountingPrerequisiteProjects(
      scheduledProject,
    );
  const { completed, accepted, refunded, id } = scheduledProject;
  const isCompleted = useMemo(() => Boolean(completed), [completed]);
  const isAccepted = useMemo(() => Boolean(accepted), [accepted]);
  const theme = useTheme();
  const projectStatus = useMemo(() => {
    let statusString = isInProgressProject
      ? "Project not funded"
      : isPartiallyPaid
        ? "Pending balance"
        : isCompleted
          ? "Project completed"
          : "Project in progress";

    if (refunded) {
      statusString = "Refunded";
    }
    if (!isAccepted) {
      statusString = "Pending Acceptance";
    }
    if (isProjectCancelled) {
      statusString = "Project cancelled";
    }

    return statusString;
  }, [
    isInProgressProject,
    isPartiallyPaid,
    isCompleted,
    refunded,
    isAccepted,
    isProjectCancelled,
  ]);

  const projectStatusIcon = useMemo(() => {
    if (isProjectCancelled) return faBan;
    if (isInProgressProject || isPartiallyPaid) return faTriangleExclamation;
    if (isCompleted) return faCheck;

    return faClock;
  }, [isInProgressProject, isPartiallyPaid, isCompleted, isProjectCancelled]);

  const showShareToClientButton = useMemo(
    () => isInProgressProject && !code,
    [isInProgressProject, code],
  );

  const showProjectChatButton = useMemo(
    () => !isInProgressProject && !code,
    [isInProgressProject, code],
  );

  const showOption = useMemo(() => {
    return (
      !isInProgressProject &&
      !code &&
      !userIsArtistOnScheduledProject &&
      !showShareToClientButton
    );
  }, [
    code,
    isInProgressProject,
    showShareToClientButton,
    userIsArtistOnScheduledProject,
  ]);

  const showReleaseButton = useMemo(() => {
    return !code && userIsArtistOnScheduledProject && isCompleted;
  }, [code, userIsArtistOnScheduledProject, isCompleted]);

  return (
    <StatusBlock $completed={Boolean(scheduledProject.completed)}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <Text color={TextColor.BLACK} bold variant={TextStyleVariant.H6}>
          {` ${projectStatus}`}
        </Text>
        <FontAwesomeIcon
          size={"lg"}
          color={
            isCompleted
              ? theme.palette.standardColor.Green["600"]
              : theme.palette.text.primary
          }
          icon={projectStatusIcon}
        />
      </div>
      {!refunded && (
        <OverviewHeaderDropDownSection>
          {showShareToClientButton && !showReleaseButton && (
            <ShareToClientButton scheduledProjectId={id} />
          )}
          {showProjectChatButton && !showReleaseButton && (
            <ProjectChatPanel
              scheduledProject={scheduledProject}
              loading={false}
            />
          )}
          {showReleaseButton && (
            <ProjectOverviewHeaderReleaseButton
              release={release}
              numOfSongs={totalSongs}
              scheduledProjectId={scheduledProject?.id}
            />
          )}

          <ScheduledProjectActionsDropdown
            showOptionsText={showOption}
            scheduledProjectId={id}
            shareLinkCode={code}
            disablePageSharing={disableSharingActions}
            disableDownloadInvoice={disableSharingActions}
            disableDownloadAllFiles={disableDownloadAssets}
            isInProgressProject={isInProgressProject}
            isPartiallyPaid={isPartiallyPaid}
            showCompletePaymentOption={disableSharingActions}
            showEditRelease={showReleaseButton}
            release={release}
          />
        </OverviewHeaderDropDownSection>
      )}
    </StatusBlock>
  );
};

export default StatusBlockComponent;
