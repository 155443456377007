import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useIsArtistReviewStep } from "../../../hooks/projectStepHooks";
import useModal from "../../../hooks/useModal";
import downloadReviewTracks from "../../../store/actions/projects/downloadReviewTracks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectById,
  ProjectType,
} from "../../../store/models/project";
import { SessionSteps } from "../../../store/models/recordingSession";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { isUserArtistForProject } from "../../../utils/projectUtils";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../core-ui/components/BasePopover/PopoverContainerContext";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./ArtistReviewFileDownloadButton.css";

interface ArtistReviewFileDownloadButtonProps {
  project?: ProjectById;
  step?: MixingProjectSteps | MasteringProjectSteps | SessionSteps;
  code?: string | null;
}

const ArtistReviewFileDownloadButton = ({
  project,
  step,
  code,
}: ArtistReviewFileDownloadButtonProps) => {
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();
  const user = useAppSelector((state) => state.accountInfo.user);
  const isSuperUser = Boolean(user?.is_superuser);
  const isArtist = isUserArtistForProject(project, user);
  const isArtistReviewStep = useIsArtistReviewStep(
    project?.id,
    step,
    project?.service_type,
  );
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pathname } = location;
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );

  const handleDownload = useCallback(() => {
    if (!project) return;
    emitAnalyticsTrackingEvent(
      "artist_review_download_track_modal_confirm_clicked",
      {
        projectId: project.id,
      },
      user?.id,
    );

    void dispatch(downloadReviewTracks({ project_id: project.id, code: code }))
      .unwrap()
      .finally(closePopover);
  }, [project, user?.id, dispatch, closePopover]);
  if (!project) return null;

  if (!code && (!isArtist || !isArtistReviewStep) && !isSuperUser) {
    return null;
  }
  const projectType = project.service_type;
  const specificProject =
    project.service_type === ProjectType.MASTERING
      ? project?.mastering_project
      : project?.mixing_project;
  // Engineer hasn't enabled either mp3 or wav download
  if (
    !specificProject?.enable_artist_review_mp3_file_download &&
    !specificProject?.enable_artist_review_wav_file_download &&
    !isSuperUser
  ) {
    return null;
  }

  return (
    <>
      <BasePopover
        side="top"
        title={`Download Latest Main ${
          projectType === ProjectType.MASTERING ? "Master" : "Mix"
        } File`}
        wrapperElement={containerElement}
        description={`By downloading the latest ${
          projectType === ProjectType.MASTERING ? "master" : "mix"
        } from the engineer, I am waiving my right to a refund for this project.`}
        onConfirm={handleDownload}
        onCancel={closePopover}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        setIsPopoverOpen={setIsPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            variant={ButtonVariant.GHOST}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "open_artist_review_download_track_modal",
                {
                  projectId: project.id,
                },
                user?.id,
              );
              if (!isPopoverOpen) setIsPopoverOpen(true);
            }}
            className="artist-review-file-download-button-container"
          >
            <p className="b2-semi-bold artist-review-download-text">Download</p>
            <FontAwesomeIcon
              icon={faDownload}
              color="var(--medium-grey)"
              style={{ width: 16, height: 16 }}
            />
          </Button>
        </PopoverTrigger>
      </BasePopover>
    </>
  );
};

export default ArtistReviewFileDownloadButton;
