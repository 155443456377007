import { SlimPrivateActiveUser } from "../store/models/user";

export const DEFAULT_EMAIL = "noreply@engineears.com";

export const getEmailFromActiveUser = (activeUser?: SlimPrivateActiveUser) => {
  if (!activeUser) {
    return "";
  }
  const email = activeUser.email;
  if (!email) {
    return "";
  }
  return email !== DEFAULT_EMAIL ? email : "";
};
