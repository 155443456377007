import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIProjectUploadLink } from "../../models/project";
import { makeBackendPostCallWithJsonResponse } from "../../utils/fetch";
import { CREATE_UPLOAD_LINK } from "../../utils/routes";
import { receiveErrors } from "../errorStore";
import { loadProjectParams } from "./types";

const createUploadLink = createAsyncThunk(
  CREATE_UPLOAD_LINK,
  async (args: loadProjectParams, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<APIProjectUploadLink>(
        CREATE_UPLOAD_LINK,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export default createUploadLink;
