import { keepPreviousData, useQuery } from "@tanstack/react-query";
import searchStudioRooms, {
  SearchStudioRoomsParams,
} from "../../api/recording/studio/searchStudioRooms";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { SearchOrigin } from "../../store/actions/userSearch";
import { useAppSelector } from "../../store/hooks";
import {
  applyMaxDistanceToSearchParams,
  applyRateFiltersToSearchParams,
  omitKeys,
  QUERY_KEY_EXCLUDED_ENTRIES,
} from "../../utils/searchFiltersUtils";
import { useSearchFilters } from "../searchHooks/useSearchFilters";

interface useSearchStudioRoomsQueryProps {
  page?: number;
  searchOrigin?: SearchOrigin;
  search: string;
  enabled?: boolean;
}

export const useSearchStudioRoomsQuery = ({
  page = 1,
  searchOrigin = SearchOrigin.EXPLORE_SCREEN,
  search,
  enabled = true,
}: useSearchStudioRoomsQueryProps) => {
  const { anonymousId } = useAppSelector((state) => state.accountInfo);
  const { autocompleteSuggestions, longitude, latitude } = useAppSelector(
    (state) => state.userSearch,
  );

  const {
    allowNoEngineer,
    simpleBudgetsSelected,
    maxRate,
    minRate,
    maxDistance,
    amenities,
    daysAvailable,
    duration,
    promoCode,
    paymentDeposits,
  } = useSearchFilters();

  const searchStudioRoomsParams: SearchStudioRoomsParams = {
    search,
    page,
    days_available: daysAvailable,
    duration: duration || undefined,
    promocode: promoCode,
    payment_deposits: paymentDeposits || undefined,
    allow_no_engineer: allowNoEngineer,
    amenities,
    anonymous_id: anonymousId,
    search_origin: searchOrigin,
  };

  applyRateFiltersToSearchParams(
    searchStudioRoomsParams,
    maxRate,
    minRate,
    simpleBudgetsSelected,
  );

  applyMaxDistanceToSearchParams(
    searchStudioRoomsParams,
    autocompleteSuggestions,
    maxDistance,
    latitude,
    longitude,
  );

  return useQuery({
    queryKey: [
      QUERY_KEYS.STUDIO_ROOM_SEARCH,
      omitKeys(searchStudioRoomsParams, QUERY_KEY_EXCLUDED_ENTRIES),
    ],
    enabled,
    queryFn: ({ signal }) => searchStudioRooms(searchStudioRoomsParams, signal),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};
