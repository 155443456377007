import { faSearch, faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom, useAtomValue } from "jotai";
import React, { useCallback, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  queryStringAtom,
  searchTermAtom,
} from "../../../atoms/searchQueryAtom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes/screens";
import { removeAutoCompleteSuggestions } from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
} from "../../../store/models/autocomplete";
import {
  useSelectStudioRoomSearchFiltersApplied,
  useSelectUserSearchFilterApplied,
} from "../../../store/selectors/searchSelectors";
import "./SearchResultOverlay.css";

export interface SearchResultOverlayProps {
  children?: React.ReactNode;
  inputfieldActive: boolean;
  setInputfieldActive: (active: boolean) => void;
  toggleFilters?: () => void;
  isEngineerSearch?: boolean;
  isStudioSearch?: boolean;
  placeholder?: string;
  mapView?: boolean;
}

export const SearchResultOverlay = ({
  inputfieldActive,
  setInputfieldActive,
  toggleFilters,
  isEngineerSearch = false,
  isStudioSearch = false,
  placeholder = "",
  mapView,
  // the search result list
  children,
}: SearchResultOverlayProps) => {
  const [searchQuery, setSearchQuery] = useAtom(searchTermAtom);
  const searchQueryString = useAtomValue(queryStringAtom);
  const studioRoomSearchFiltersApplied =
    useSelectStudioRoomSearchFiltersApplied();
  const userSearchFiltersApplied = useSelectUserSearchFilterApplied();
  const filtersApplied = useMemo(() => {
    if (isEngineerSearch) {
      return userSearchFiltersApplied;
    }
    if (isStudioSearch) {
      return studioRoomSearchFiltersApplied;
    }
    return userSearchFiltersApplied || studioRoomSearchFiltersApplied;
  }, [
    isStudioSearch,
    isEngineerSearch,
    studioRoomSearchFiltersApplied,
    userSearchFiltersApplied,
  ]);

  const history = useHistory();
  const { isDesktop } = useMediaQueryBreakpoint();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);

  const searchScreens: string[] = [
    SCREENS.SEARCH as string,
    SCREENS.PAGINATED_ENGINEERS as string,
    SCREENS.PAGINATED_STUDIOS as string,
  ];
  const currentPath = history.location.pathname;

  const handleEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }
    setInputfieldActive(false);
    if (searchScreens.includes(currentPath)) {
      return;
    }
    history.push(`${SCREENS.SEARCH}?${searchQueryString}`);
  };

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;
      setSearchQuery(text);
    },
    [setSearchQuery],
  );

  const handleOnBlur = () => {
    setTimeout(() => {
      setInputfieldActive(false);
    }, 500);
  };
  const autocompleteFilters = useAppSelector(
    (state) => state.userSearch.autocompleteSuggestions,
  );

  const handleOnFilterClick = (filter: AutocompleteSuggestion) => {
    dispatch(removeAutoCompleteSuggestions(filter));
  };

  const placeholderText = !mapView
    ? placeholder
    : "Search for location and artist credits";

  return (
    <div className="search-result-overlay">
      <div className="search-overlay-search-bar container">
        <FontAwesomeIcon
          icon={faSearch}
          color="var(--black)"
          style={{
            margin: "16px",
          }}
        />
        <p
          onClick={() => {
            if (ref?.current) {
              ref.current.focus();
            }
          }}
          className="b1-semi ee-company-name-search-input"
        >
          Search EngineEars
        </p>
        <input
          ref={ref}
          onFocus={() => setInputfieldActive(true)}
          onBlur={handleOnBlur}
          className="overlay-search-input"
          type="search"
          placeholder={!isDesktop ? "" : placeholderText}
          value={searchQuery}
          onChange={handleOnChange}
          onKeyDown={handleEnterPressed}
        />
        <div className="auto-complete-selected-filter-row">
          {autocompleteFilters.map((filter, index) => {
            if (!isDesktop) {
              return null;
            }
            switch (filter.type) {
              case AutoCompleteTypeEnum.ARTIST_CREDIT:
                if (history.location.pathname === SCREENS.PAGINATED_STUDIOS) {
                  return null;
                }
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOnFilterClick(filter);
                    }}
                    className="autocomplete-filter"
                    key={index}
                  >
                    <p className="b3">
                      has worked with <span>{filter.label}</span>
                    </p>
                  </div>
                );
              case AutoCompleteTypeEnum.LOCATION:
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOnFilterClick(filter);
                    }}
                    className="autocomplete-filter"
                    key={index}
                  >
                    <p className="b3">
                      in <span>{filter.label}</span>
                    </p>
                  </div>
                );
              default:
                return null;
            }
          })}
          <div
            style={
              filtersApplied ? { backgroundColor: "var(--mint-green)" } : {}
            }
            onClick={toggleFilters}
            className="search-result-overlay-filter-button"
          >
            <FontAwesomeIcon
              icon={faSliders}
              color={
                filtersApplied ? "var(--black-no-dark-theme)" : "var(--black)"
              }
            />
            <p
              style={
                filtersApplied ? { color: "var(--black-no-dark-theme)" } : {}
              }
            >
              Filters
            </p>
          </div>
          <Link
            to={(location) => ({
              ...location,
              pathname: SCREENS.SEARCH,
              search: `?${searchQueryString}`,
            })}
            className={
              searchScreens.includes(currentPath) ? "disable-search-icon" : ""
            }
          >
            <div className="search-result-overlay-button">
              <FontAwesomeIcon icon={faSearch} color="var(--white)" />
            </div>
          </Link>
        </div>
      </div>
      <div
        style={{ maxHeight: inputfieldActive ? "500px" : "0" }}
        className={"search-result-overlay-result-drop-down ".concat(
          inputfieldActive ? "active" : "",
        )}
      >
        <div className="container">{children}</div>
      </div>
    </div>
  );
};
