import { useCallback, useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import useModal from "../../../hooks/useModal";
import { optInToPlatformPromotion } from "../../../store/actions/marketing";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import "./CampaignHeader.css";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";

export const CampaignHeader = () => {
  const { campaignsOptedIn, optedIntoUnverifiedEngineerPromo } = useAppSelector(
    (state) => state.marketingDataStore,
  );
  const selectedStudioProfile = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const engineer = loggedInUser?.engineer;
  const dispatch = useAppDispatch();
  const { isOpen, openModal, closeModal } = useModal();
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

  const handleToggleCampaigns = useCallback(
    async (updateObj: {
      campaigns_opted_in?: boolean;
      competitions_opted_in?: boolean;
      opted_into_unverified_engineer_promotion?: boolean;
    }) => {
      await dispatch(
        optInToPlatformPromotion({
          ...updateObj,
          studio_id: selectedStudioProfile?.id,
        }),
      ).unwrap();
      await invalidateOnboardingProgress();
    },
    [dispatch, selectedStudioProfile],
  );

  const showUnverifiedEngPromoToggle = useMemo(() => {
    if (selectedStudioProfile) {
      return false;
    }

    if (engineer?.verified) {
      return false;
    }

    return true;
  }, [selectedStudioProfile, engineer]);

  const enableUnverifiedEngPromoToggle = useMemo(() => {
    if (!showUnverifiedEngPromoToggle || !engineer) {
      return false;
    }

    return true;
  }, [showUnverifiedEngPromoToggle, engineer]);

  const onDisableToggleClick = useCallback(() => {
    if (enableUnverifiedEngPromoToggle) {
      return;
    }

    emitAnalyticsTrackingEvent(
      "unverified_eng_promo_eligibility_modal_opened",
      {},
      loggedInUser?.id,
    );
    openModal();
  }, [enableUnverifiedEngPromoToggle, openModal, loggedInUser?.id]);

  return (
    <>
      <div className="promo-switch-container">
        <div className="engineear-campaign-header">
          <ToggleSwitch
            id="campaign-opt-in-toggle"
            label={"Opt in for platform wide promotions"}
            checked={campaignsOptedIn}
            onChange={(value) => {
              void handleToggleCampaigns({ campaigns_opted_in: value });
            }}
            updateCheckedLocally={false}
            size={TOGGLE_SWITCH_SIZE.SMALL}
          />
          <ToolTipTextArea
            text={"Enabling this will enroll you in platform-wide promotions"}
          />
        </div>
        {showUnverifiedEngPromoToggle && (
          <div
            className="engineear-campaign-header"
            onClick={onDisableToggleClick}
          >
            <ToggleSwitch
              id="unverified-eng-promo-opt-in-toggle"
              label={"Opt in for increased visibility by giving 50% off"}
              checked={optedIntoUnverifiedEngineerPromo}
              onChange={(value) => {
                emitAnalyticsTrackingEvent(
                  "unverified_eng_promo_toggle_change",
                  {
                    value,
                  },
                  loggedInUser?.id,
                );
                void handleToggleCampaigns({
                  opted_into_unverified_engineer_promotion: value,
                });
              }}
              updateCheckedLocally={false}
              disabled={!enableUnverifiedEngPromoToggle}
              size={TOGGLE_SWITCH_SIZE.SMALL}
            />
            <ToolTipTextArea
              text={
                "Enabling this will enroll you in our special promotion for engineers who are not yet verified."
              }
            />
          </div>
        )}
      </div>
      <BaseModal
        open={isOpen}
        setOpen={closeModal}
        header="Recently Onboarded Engineer Promotion"
      >
        Opt in for the recently onboarded engineer promotion is only available
        to engineers who are not yet verified.
      </BaseModal>
    </>
  );
};
