import {
  Query,
  QueryState,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { TransactionStatus } from "../../store/models/transaction";
import { ProductTransactionResponse } from "./models";
import { fetchProductTransaction } from "./productTransactionsAPI";

export const useProductTransaction = (
  transactionCode?: string,
): UseQueryResult<ProductTransactionResponse, Error> => {
  return useQuery({
    refetchInterval: (query: Query<ProductTransactionResponse>) => {
      const state: QueryState<ProductTransactionResponse, Error> = query.state;
      const data = state.data;
      if (!data) {
        return 3000;
      }
      const transaction = data.transaction;
      if (
        transaction.transaction_status ===
          TransactionStatus.PAYMENT_PROCESSING ||
        transaction.transaction_status === TransactionStatus.PENDING
      ) {
        return 3000;
      }
      return 3000;
    },
    enabled: Boolean(transactionCode),
    queryKey: [QUERY_KEYS.FETCH_PRODUCT_TRANSACTION, transactionCode],
    queryFn: async () => {
      const response = await fetchProductTransaction(transactionCode!);
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ProductTransactionResponse;
    },
  });
};
