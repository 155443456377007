import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import markFinalAssetsApproved from "../../../../store/actions/projects/markFinalAssetsApproved";
import { useAppDispatch } from "../../../../store/hooks";

export const useApproveProjectFiles = (projectId: number) => {
  const [approving, setApproving] = useState(false);
  const dispatch = useAppDispatch();

  const handleMarkApproved = useCallback(() => {
    setApproving(true);
    dispatch(markFinalAssetsApproved({ project_id: projectId }))
      .unwrap()
      .catch(() => {
        toast.error("Something went wrong. Please try again.");
      })
      .finally(() => {
        setApproving(false);
      });
  }, [projectId, dispatch]);

  return {
    approving,
    handleMarkApproved,
  };
};
