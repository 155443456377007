import { useAtom, useAtomValue } from "jotai";
import { RESET } from "jotai/utils";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ViewSlider, { ViewProps } from "react-view-slider";
import { sidePanelBottomDrawerContentAtom } from "../../../atoms/sidePanelAtoms";
import {
  useHasPendingPurchaseOrder,
  useIsInProgressProject,
  useIsLabelProject,
  useProjectPaywallStatus,
} from "../../../hooks/partialPaymentHooks";
import { SCREENS } from "../../../routes/screens";
import { getProjectById } from "../../../store/actions/projectsMap";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MixingProjectSteps,
  ProjectType,
  ProjectUserType,
} from "../../../store/models/project";
import {
  areDeliverablesUploaded,
  getArtistFromProject,
  getEngineerFromProject,
  getFinishedTaskMessage,
  getMixingMasteringProject,
  getPrereqProjectTitle,
  getUserTypeFromProject,
} from "../../../utils/projectUtils";
import { CompleteTransitionView } from "../CompleteTransitionView/CompleteTransitionView";
import { InProgressTransitionView } from "../InProgressTransitionView/InProgressTransitionView";
import {
  ProjectWorkflowPanel,
  ProjectWorkflowPanelVariant,
} from "../ProjectWorkflowPanel/ProjectWorkflowPanel";
import {
  getDisabledRowText,
  isUserOnProject,
} from "../ScheduledProjectTable/utils";
import { SidePanelBottomDrawer } from "../SidePanel/SidePanelBottomDrawer";
import { TransferFileTransitionView } from "../TransferFileTransitionView/TransferFileTransitionView";
import { MainWorkflowView, mainWorkflowViewAtom } from "./atoms";
import { MainProjectWorkflowBookingDetailsView } from "./MainProjectWorkflowBookingDetailsView";
import { MainProjectWorkflowPanelProvider } from "./MainProjectWorkflowPanelContext";
import { useIsAdmin } from "../../../hooks/useIsAdmin";

interface MainProjectWorkflowProps {
  isOpen: boolean;
  noSidePanel?: boolean;
  onClose: () => void;
  projectId: number | null;
  hideTrackButtons?: boolean;
  code?: string;
  albumTitleOverride?: string;
}

export const MainProjectWorkflowPanel = ({
  isOpen,
  noSidePanel,
  onClose,
  projectId,
  hideTrackButtons = false,
  code,
  albumTitleOverride,
}: MainProjectWorkflowProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { scheduledProject } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const { isLoading, projects } = useAppSelector(
    (state) => state.projectsMapStore,
  );
  const { user: currentUser, isAuthenticated } = useAppSelector(
    (state) => state.accountInfo,
  );
  const project = projectId ? projects[projectId] : null;
  const mixingMasteringProject = getMixingMasteringProject(project);
  const artist = getArtistFromProject(project);
  const engineer = getEngineerFromProject(project ?? undefined);
  const projectStep =
    mixingMasteringProject?.step ?? MixingProjectSteps.PROJECT_ACCEPTED;
  const projectType = project?.service_type ?? ProjectType.MIXING;
  const isProjectComplete = areDeliverablesUploaded(projectType, projectStep);
  const currentUserType = getUserTypeFromProject(currentUser, project);
  const { allowTrackPreview, allowTrackApproval } =
    useProjectPaywallStatus(scheduledProject);
  const isEngineerOnProject = currentUserType === ProjectUserType.ENGINEER;
  const isLabelProject = useIsLabelProject(scheduledProject ?? undefined);
  const { isInProgressProject } = useIsInProgressProject(project);
  const isProjectUser = isUserOnProject(scheduledProject, currentUser);
  const isAdmin = useIsAdmin(currentUser);
  const { activePaginatedScheduledProject } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );
  // activePaginatedScheduledProject is only being set when user is accessing the project via a code.
  // Therefore, if the activePaginatedScheduledProject is not set, the user should be either engineer or artist.
  const userIsPrimaryArtistOrEngineer = activePaginatedScheduledProject
    ? activePaginatedScheduledProject.user_is_primary_artist_or_engineer
    : true;
  const isSecondaryEngOrAdmin =
    (code && !isProjectUser && isAdmin) ||
    (isProjectUser && !userIsPrimaryArtistOrEngineer) ||
    currentUserType === ProjectUserType.SECONDARY_ENG_OR_ADMIN; // activatedPaginatedScheduledProject does not exist if page is refreshed

  const { isPendingBudgetApproval, isPendingBillingInfo } =
    useHasPendingPurchaseOrder(
      scheduledProject?.id,
      isLabelProject,
      isInProgressProject,
    );
  const [bottomDrawerContent, setBottomDrawerContent] = useAtom(
    sidePanelBottomDrawerContentAtom,
  );

  // Generates the message for the extra step after the engineer uploads the final files
  // This was unaccounted for in the original 4-step workflow
  const finalTaskMessage = getFinishedTaskMessage(
    currentUserType,
    projectType,
    projectStep,
  );
  const isPartiallyPaid = Boolean(
    scheduledProject && scheduledProject?.outstanding_balance > 0,
  );

  useEffect(() => {
    // Fetch ProjectById details
    if (!projectId) return;
    if (!isAuthenticated && !code) return;
    dispatch(getProjectById({ project_id: projectId, code }))
      .unwrap()
      .catch(() => toast.error("Failed to load project"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, code, isAuthenticated]);

  // If the active projectId isn't eligible to be opened/started,
  // close the side panel or return to the home page with an error toast.
  useEffect(() => {
    if (!project) {
      return;
    }
    // check that projectId is part of the scheduledProject.
    if (scheduledProject) {
      if (
        !scheduledProject.projects.some(
          (associatedProject) => associatedProject.id === project.id,
        )
      ) {
        toast.error(
          "Could not find project associated with this scheduled project.",
        );
        if (!noSidePanel) {
          onClose();
        } else {
          history.replace(SCREENS.SIGNED_OUT_HOME_SCREEN);
        }
      }
    }

    // check that the project's prereq project is completed.
    if (!project.prereq_project_is_completed) {
      toast.error(
        getDisabledRowText(
          getPrereqProjectTitle(
            project.prereq_project_id,
            scheduledProject?.projects,
          ),
        ),
      );
      if (!noSidePanel) {
        onClose();
      } else {
        history.replace(SCREENS.SIGNED_OUT_HOME_SCREEN);
      }
    }
  }, [project, scheduledProject, noSidePanel]);

  const scheduledProjectId = scheduledProject?.id ?? null;
  const mainWorkflowViewIndex = useAtomValue(mainWorkflowViewAtom);
  const renderView = ({ index }: ViewProps) => {
    if (index === MainWorkflowView.MAIN) {
      return (
        <>
          {project?.id && (
            <TransferFileTransitionView
              mixingProject={project.mixing_project}
              masteringProject={project.mastering_project}
              user={currentUser}
              projectId={project.id}
              code={code}
            />
          )}
          {project?.id && (
            <InProgressTransitionView
              project={project}
              projectStep={projectStep}
              projectType={projectType}
              userType={currentUserType}
              allowTrackPreview={allowTrackPreview || isEngineerOnProject}
              allowTrackApproval={allowTrackApproval || isEngineerOnProject}
              isPartiallyPaid={isPartiallyPaid}
              code={code}
              outstandingBalance={scheduledProject?.outstanding_balance}
            />
          )}
          {project?.id && (scheduledProject || currentUser?.is_superuser) && (
            <CompleteTransitionView
              project={project}
              code={code}
              projectType={projectType}
              user={currentUser}
              userType={currentUserType}
              isPendingBudgetApproval={
                isLabelProject && isPendingBudgetApproval
              }
              isPendingBillingInfo={isLabelProject && isPendingBillingInfo}
              isPartiallyPaid={Boolean(
                (scheduledProject?.outstanding_balance ?? 0) > 0,
              )}
              outstandingBalance={scheduledProject?.outstanding_balance}
            />
          )}
        </>
      );
    }
    if (index === MainWorkflowView.BOOKING_DETAILS) {
      return (
        <MainProjectWorkflowBookingDetailsView
          scheduledProjectId={scheduledProjectId}
          isInProgressProject={!project || isInProgressProject}
          userType={currentUserType}
        />
      );
    }
    return null;
  };

  return (
    <MainProjectWorkflowPanelProvider>
      <ProjectWorkflowPanel
        albumTitle={albumTitleOverride ?? scheduledProject?.title}
        collaborator={
          !isAuthenticated || isEngineerOnProject ? artist : engineer
        }
        isRefunded={Boolean(scheduledProject?.refunded ?? project?.refunded)}
        isLoading={isLoading}
        isOpen={isOpen}
        isProjectComplete={isProjectComplete}
        noSidePanel={noSidePanel}
        projectId={projectId}
        projectStep={projectStep}
        projectType={projectType}
        userType={currentUserType}
        variant={ProjectWorkflowPanelVariant.MAIN_FLOW}
        onClose={onClose}
        isInProgressProject={isInProgressProject}
        outstandingBalance={scheduledProject?.outstanding_balance}
        hideTrackButtons={hideTrackButtons}
        scheduledProject={scheduledProject}
        overrideTaskMessage={finalTaskMessage}
        isSecondaryEngOrAdmin={isSecondaryEngOrAdmin}
      >
        <ViewSlider
          keepViewsMounted={!noSidePanel}
          renderView={renderView}
          numViews={2}
          activeView={mainWorkflowViewIndex}
          animateHeight
          style={{ overflow: "visible" }} // allows TrackTableOptions to be visible
        />
        <SidePanelBottomDrawer
          isOpen={Boolean(bottomDrawerContent)}
          onClose={() => setBottomDrawerContent(RESET)}
        >
          {bottomDrawerContent}
        </SidePanelBottomDrawer>
      </ProjectWorkflowPanel>
    </MainProjectWorkflowPanelProvider>
  );
};
