import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MStep from "@mui/material/Step";
import MStepLabel from "@mui/material/StepLabel";
import MStepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  aandRSetupSteps,
  atmosAandRMixingDescriptions,
  engineerSetupSteps,
  masteringStepDescriptions,
  mixingStepDescriptions,
  recordingSteps,
} from "../../../store/models/project";
import {
  Text,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../stories/core-ui/components/Text/Text";
import "./ProjectStepper.css";

export interface ProjectStepInterface {
  step: number;
  dark?: boolean;
}

interface StepInstruction {
  title: string;
  body: string;
}

export enum ENGINEER_SETUP_STEPS {
  StripeSetup,
  AddSubscription,
  AddService,
}

export enum AandRSteps {
  SelectLabel,
  VerifyPhoneNumber,
  Congrats,
  Complete,
}

export const MixEngineerInstructionMap = new Map<number, StepInstruction>([
  [
    1,
    {
      title: "Waiting for artist to upload reference",
      body: "Project pending. Waiting for artist to upload a reference (demo) track.",
    },
  ],
  [
    2,
    {
      title: "Review song reference",
      body: "accept or request a re-upload for this song's reference track.",
    },
  ],
  [
    3,
    {
      title: "Waiting for file uploads",
      body: "waiting for [Name] to upload [Project Name]'s files.",
    },
  ],
  [
    4,
    {
      title: "Download and review the projects files",
      body: "Review the uploaded project files to make sure [Name] has uploaded everything you need.",
    },
  ],
  [
    5,
    {
      title: "Upload song mix",
      body: "[Name] is waiting for their mix. Use the project files to complete your mix",
    },
  ],
  [
    6,
    {
      title: "Your mix is in review",
      body: "[Name] is reviewing your mix for [Project Name].",
    },
  ],
  [
    7,
    {
      title:
        "Please upload the finished stems and working files for this project",
      body: "for [Name] to be able to download all the completed files.",
    },
  ],
  [
    8,
    {
      title: "Project complete",
      body: "You completed [Project Name]",
    },
  ],
]);

export const MixInstructionMap = new Map<number, StepInstruction>([
  [
    1,
    {
      title: "Upload your song",
      body: "Your request to have [Name] work on [Project Name] has been submitted. Please upload a .wav file of the song so the engineer can listen to it before responding to your request.",
    },
  ],
  [
    2,
    {
      title: "Your song reference is being reviewed",
      body: "[Name] is reviewing your song reference (demo).",
    },
  ],
  [
    3,
    {
      title: "Your Request Was Accepted",
      body: "Congratulations! [Name] accepted your request to work on [Project Name]. Please combine all the project files the engineer will need into one .zip file. Then upload it below.",
    },
  ],
  [
    4,
    {
      title: "Your Project Files Are Being Reviewed",
      body: "[Name] has the project files you uploaded. They are reviewing the files to make sure they have everything they need and will let you know if anything else is needed.",
    },
  ],
  [
    5,
    {
      title: "Your Project Is Being Worked On",
      body: "[Name] reviewed your files and has started working on your project. Once they’ve finished, you’ll be able to review their work and provide feedback for revisions, if necessary.",
    },
  ],
  [
    6,
    {
      title: "Review your project",
      body: "[Name] completed their work, and [Project Name] is ready for you to listen to. You can hear the latest version and compare it with the original reference track below. Simply place the cursor on the part of the track you want to hear and the two versions will automatically sync.",
    },
  ],
  [
    7,
    {
      title: "Your Completed Project is being uploaded",
      body: "[Name] is uploading all the finished stems and working files for your project. Once this is complete, you will be able to download all the completed files.",
    },
  ],
  [
    8,
    {
      title: "Your Completed Project is uploaded",
      body: "Provide feedback on your experience with [Name]",
    },
  ],
]);

export const MasteringInstructionMap = new Map<number, StepInstruction>([
  [
    1,
    {
      title: "Artist File Upload (Mastering)",
      body: "Your request to have [Name] work on [Project Name] has been submitted. Please upload a .wav file of the song so the engineer can listen to it before responding to your request. Additionally submit a zip file with your alts",
    },
  ],
  [
    2,
    {
      title: "Your request is being reviewed",
      body: "Your request to have [Name] work on [Project Name] has been submitted.",
    },
  ],
  [
    3,
    {
      title: "Your Project Is Being Worked On",
      body: "[Name] reviewed your files and has started working on your project. Once they’ve finished, you’ll be able to review their work and provide feedback for revisions, if necessary.",
    },
  ],
  [
    4,
    {
      title: "Review your project",
      body: "[Name] completed their work, and [Project Name] is ready for you to listen to. You can hear the latest version and compare it with the original reference track below. Simply place the cursor on the part of the track you want to hear and the two versions will automatically sync.",
    },
  ],
  [
    5,
    {
      title: "Your Completed Project is being uploaded",
      body: "[Name] is uploading all the finished stems and working files for your project. Once this is complete, you will be able to download all the completed files.",
    },
  ],
  [
    6,
    {
      title: "Your Completed Project is uploaded",
      body: "Provide feedback on your experience with [Name]",
    },
  ],
]);

export const MasteringEngineerInstructionMap = new Map<number, StepInstruction>(
  [
    [
      1,
      {
        title: "Waiting for artist to upload reference",
        body: "Project pending. Waiting for artist to upload a reference (demo) track so you can decide if you want to accept the project.",
      },
    ],
    [
      2,
      {
        title: "New Project Request",
        body: "[Name] has booked you for a mastering project for a total price of [Price].",
      },
    ],
    [
      3,
      {
        title: "Time to Master",
        body: "[Name] is waiting for you to master the project! Please download the project files and complete the master for the song.",
      },
    ],
    [
      4,
      {
        title: "Your master is in review",
        body: "[Name] is reviewing your master for [Project Name].",
      },
    ],
    [
      5,
      {
        title:
          "Please upload the finished alts and working files for this project",
        body: "for [Name] to be able to download all the completed files.",
      },
    ],
    [
      6,
      {
        title: "Project complete",
        body: "You completed [Project Name]",
      },
    ],
  ],
);

export const RecordingEngineerInstructionMap = new Map<number, StepInstruction>(
  [
    [
      1,
      {
        title: "Session Booked",
        body: "[Name] has booked you for a recording session.",
      },
    ],
    [
      2,
      {
        title: "Files Uploaded",
        body: "The session is now complete! Leave a review for the collaboration.",
      },
    ],
  ],
);

export const RecordingArtistInstructionMap = new Map<number, StepInstruction>([
  [
    1,
    {
      title: "Session Booked",
      body: "You have booked [Name] for a recording session.",
    },
  ],
  [
    2,
    {
      title: "Files Uploaded",
      body: "The session is now complete! Leave a review for the collaboration.",
    },
  ],
]);

const rootClasses = {
  root: "BaseStepper-root",
};

const Stepper = styled(MStepper)({
  ["& .BaseStepper-root"]: {
    backgroundColor: "transparent",
    padding: 0,
  },
});

const PREFIX_ICON = "IconComponent";

const classes = {
  circle: `${PREFIX_ICON}-circle`,
  activeCircle: `${PREFIX_ICON}-activeCircle`,
  completed: `${PREFIX_ICON}-completed`,
};

const IconContainer = styled("div")({
  [`& .${classes.circle}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "var(--gray)",
    color: "var(--text-secondary-color)",
    fontWeight: "bold",
  },
  [`& .${classes.activeCircle}`]: {
    backgroundColor: "var(--boomy-orange)",
    color: "var(--text-invert-color)",
  },
  [`& .${classes.completed}`]: {
    color: "var(--primary-color)",
  },
});

const labelClasses = {
  iconContainer: "StepLabel-iconContainer",
};

const StepLabel = styled(MStepLabel)({
  [`& .StepLabel-iconContainer`]: {
    height: "24px",
    width: "24px",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface BaseStepperProps {
  currentStep: number;
  stepTitles: string[];
  hideTextOnTablet?: boolean;
}

export const BaseStepper = ({
  currentStep,
  stepTitles,
  hideTextOnTablet = false,
}: BaseStepperProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();

  const IconComponent = ({
    completed,
    active,
    displayIndex,
  }: {
    completed: boolean;
    active: boolean;
    displayIndex: number;
  }) => {
    return (
      <IconContainer>
        {completed ? (
          <FontAwesomeIcon
            className={classes.completed}
            icon={faCheckCircle}
            size="lg"
          />
        ) : (
          <div
            className={classNames({
              [classes.circle]: true,
              [classes.activeCircle]: active,
            })}
          >
            {displayIndex}
          </div>
        )}
      </IconContainer>
    );
  };

  return (
    <Stepper
      className={rootClasses.root}
      alternativeLabel
      nonLinear
      activeStep={currentStep}
    >
      {stepTitles.map((title, index) => {
        return (
          <MStep key={index}>
            <StepLabel
              className={labelClasses.iconContainer}
              icon={
                <IconComponent
                  completed={index < currentStep}
                  active={index === currentStep}
                  displayIndex={index + 1}
                />
              }
            >
              {!hideTextOnTablet || isDesktop ? (
                <Text
                  size={TEXT_SIZE.SMALL}
                  weight={
                    index <= currentStep ? TEXT_WEIGHT.BOLD : TEXT_WEIGHT.MEDIUM
                  }
                  style={{ textAlign: "center" }}
                >
                  {title}
                </Text>
              ) : null}
            </StepLabel>
          </MStep>
        );
      })}
    </Stepper>
  );
};

const BaseProjectStepper = (props: BaseStepperProps) => {
  return (
    <div className="project-stepper-container b2">
      <BaseStepper {...props} hideTextOnTablet />
    </div>
  );
};

export const RecordingSteps = ({ step }: ProjectStepInterface) => {
  if (step < 0) return <></>;
  return <BaseProjectStepper stepTitles={recordingSteps} currentStep={step} />;
};

export const AAndRMixingSteps = ({ step }: ProjectStepInterface) => {
  const stepTitles = mixingStepDescriptions.slice(3);
  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export const MixingSteps = ({ step }: ProjectStepInterface) => {
  const stepTitles = mixingStepDescriptions.slice(1);
  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export const AtmosAandRMix = ({ step }: ProjectStepInterface) => {
  const stepTitles = atmosAandRMixingDescriptions.slice(3);
  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export const AtmosMixingSteps = ({ step }: ProjectStepInterface) => {
  const stepTitles = atmosAandRMixingDescriptions.slice(1);
  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export const MasteringSteps = ({ step }: ProjectStepInterface) => {
  const stepTitles = masteringStepDescriptions.slice(1);
  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export const AAndRProjectBillingSteps = ({ step }: ProjectStepInterface) => {
  const stepTitles = [
    "Project Booked",
    "Budget Approved",
    "Billing Info Submitted",
    "Paid to Service Provider",
  ];

  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export const AAndRSetupSteps = ({ step }: ProjectStepInterface) => {
  return <BaseProjectStepper stepTitles={aandRSetupSteps} currentStep={step} />;
};

export const EngineerSetupSteps = ({ step }: ProjectStepInterface) => {
  const stepTitles = engineerSetupSteps.slice(1);
  return <BaseProjectStepper stepTitles={stepTitles} currentStep={step} />;
};

export interface MinimalStepperProps {
  step: number;
  numberOfSteps: number;
  primaryColor: string;
  secondaryColor: string;
}

export const MinimalStepper = ({
  step,
  numberOfSteps,
  primaryColor,
  secondaryColor,
}: MinimalStepperProps) => {
  return (
    <div className="minimal-stepper-container ">
      {Array.from(Array(numberOfSteps).keys()).map((index) => {
        return (
          <div
            style={
              step === index
                ? { backgroundColor: primaryColor }
                : { backgroundColor: secondaryColor }
            }
            className={"minimal-stepper-step ".concat(
              step === index ? "current " : " ",
            )}
            key={index}
          />
        );
      })}
    </div>
  );
};

export const AtmosMixEngineerInstructionMap = new Map<number, StepInstruction>([
  [
    1,
    {
      title: "Waiting for artist to upload reference",
      body: "Project pending. Waiting for artist to upload a reference (demo) track.",
    },
  ],
  [
    2,
    {
      title: "Review song reference",
      body: "accept or request a re-upload for this song's reference (demo) track.",
    },
  ],
  [
    3,
    {
      title: "Waiting for file uploads",
      body: "waiting for [Name] to upload [Project Name]'s files.",
    },
  ],
  [
    4,
    {
      title: "Download and review the projects files",
      body: "Review the uploaded project files to make sure [Name] has uploaded everything you need.",
    },
  ],
  [
    5,
    {
      title: "Upload song mix",
      body: "[Name] is waiting for their mix. Use the project files to complete your mix",
    },
  ],
  [
    6,
    {
      title: "Processing Atmos Mix",
      body: "Verifying your uploaded Atmos mix",
    },
  ],
  [
    7,
    {
      title: "Your mix is in review",
      body: "[Name] is reviewing your mix for [Project Name].",
    },
  ],
  [
    8,
    {
      title:
        "Please upload the finished stems and working files for this project",
      body: "for [Name] to be able to download all the completed files.",
    },
  ],
  [
    9,
    {
      title: "Project complete",
      body: "You completed [Project Name]",
    },
  ],
]);

export const AtmosMixInstructionMap = new Map<number, StepInstruction>([
  [
    1,
    {
      title: "Upload your song",
      body: "Your request to have [Name] work on [Project Name] has been submitted. Please upload a reference (demo) .wav file of the song so the engineer can listen to it before responding to your request.",
    },
  ],
  [
    2,
    {
      title: "Your song reference (demo) is being reviewed",
      body: "[Name] is reviewing your song reference.",
    },
  ],
  [
    3,
    {
      title: "Your Request Was Accepted",
      body: "Congratulations! [Name] accepted your request to work on [Project Name]. Please combine all the project files (all finalized mix stems) the engineer will need into one .zip file. Then upload it below.",
    },
  ],
  [
    4,
    {
      title: "Your Project Files Are Being Reviewed",
      body: "[Name] has the project files you uploaded. They are reviewing the files to make sure they have everything they need and will let you know if anything else is needed.",
    },
  ],
  [
    5,
    {
      title: "Your Project Is Being Worked On",
      body: "[Name] reviewed your files and has started working on your project. Once they’ve finished, you’ll be able to review their work and provide feedback for revisions, if necessary.",
    },
  ],
  [
    6,
    {
      title: "Processing Dolby ATMOS mix",
      body: "Processing your Dolby ATMOS mix",
    },
  ],
  [
    7,
    {
      title: "Review your project",
      body: "[Name] completed their work, and [Project Name] is ready for you to listen to. You can hear the latest version and compare it with the original reference track below. Simply place the cursor on the part of the track you want to hear and the two versions will automatically sync.",
    },
  ],
  [
    8,
    {
      title: "Your Completed Project is being uploaded",
      body: "[Name] is uploading all the finished stems and working files for your project. Once this is complete, you will be able to download all the completed files.",
    },
  ],
  [
    9,
    {
      title: "Your Completed Project is uploaded",
      body: "Provide feedback on your experience with [Name]",
    },
  ],
]);
