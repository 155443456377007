import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtomValue } from "jotai";
import { FC, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory, useLocation } from "react-router-dom";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { DevLinkProvider } from "../../../devlink/";
import { EngineEarsFooter2024 } from "../../../devlink/EngineEarsFooter2024";
import useSearch from "../../../hooks/searchHooks/useSearch";
import {
  useSearchViewQuery,
  useServiceTypesQuery,
  useUpAndComingEngineerQuery,
} from "../../../hooks/searchHooks/useSearchFilters";
import { useSearchStudioRoomsQuery } from "../../../hooks/searchPageHooks/useSearchStudioRoomsQuery";
import useSearchUsersQuery from "../../../hooks/searchPageHooks/useSearchUsersQuery";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { SCREENS } from "../../../routes/screens";
import { MapSearchTypeEnum } from "../../../store/actions/mapSearch";
import { updateUserSearchPage } from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import {
  getProfileScreenRoute,
  getStudioRoomScreenRoute,
} from "../../../store/utils/routeGetters";
import { UNVERIFIED_ENG_PROMO_CODE } from "../../components/EngineerPromoListModal/EngineerPromoListModal";
import {
  getStudioRoomProfileCard,
  getUserProfileCard,
} from "../../components/ProfileCard/ProfileCard";
import { RequestStudio } from "../../components/RequestStudio/RequestStudio";
import { FullWidthHeader } from "../../elements/FullWidthHeader/FullWidthHeader";
import { MapSearchDisplay } from "../../elements/MapSearchDisplay/MapSearchDisplay";
import { ColorPalette } from "../../theme";
import { SearchScreen } from "../SearchScreen/SearchScreen";
import "./ExploreScreen.css";

export interface SearchLocationState {
  mapView?: boolean;
}

export const ExploreScreen = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const appliedPromoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );
  const { serviceTypes: selectedServiceFilters } = useServiceTypesQuery();
  const { upAndComingEngineer } = useUpAndComingEngineerQuery();

  useSetPageTitle("Search");

  const { toggleSearchView, mapView } = useSearchViewQuery();

  const { debouncedSearchTerm } = useSearch({
    enabled: true,
    mapView,
  });

  const history = useHistory();
  const location = useLocation<SearchLocationState>();
  const darkMode = useAtomValue(darkModeAtom);
  const dispatch = useAppDispatch();
  const nonStudioFilterSelected = useMemo(() => {
    return (
      selectedServiceFilters.filter(
        (service) => service !== ProjectType.RECORDING,
      ).length > 0
    );
  }, [selectedServiceFilters]);

  const [displayEngineersSecond, setDisplayEngineersSecond] =
    useState<boolean>(false);
  const [displayStudios, setDisplayStudios] = useState<boolean>(true);

  const { isFetching: engineersLoading, data: engineerUsers } =
    useSearchUsersQuery({
      enabled: !mapView,
      search: debouncedSearchTerm,
    });

  const { isFetching: studioRoomsLoading, data: studioRoomsData } =
    useSearchStudioRoomsQuery({
      search: debouncedSearchTerm,
      enabled: !mapView && displayStudios,
    });

  const updateSearchView = () => {
    history.replace({ ...location, state: { mapView: !mapView } });
    toggleSearchView();
  };

  useEffect(() => {
    if (
      upAndComingEngineer ||
      appliedPromoCode?.code === UNVERIFIED_ENG_PROMO_CODE
    ) {
      setDisplayStudios(false);
      setDisplayEngineersSecond(false);
    } else if (
      selectedServiceFilters.length === 0 &&
      !nonStudioFilterSelected
    ) {
      setDisplayStudios(true);
      setDisplayEngineersSecond(false);
    } else if (
      selectedServiceFilters.includes(ProjectType.RECORDING) &&
      !nonStudioFilterSelected
    ) {
      setDisplayStudios(true);
      setDisplayEngineersSecond(true);
    } else {
      setDisplayStudios(false);
      setDisplayEngineersSecond(false);
    }
  }, [
    selectedServiceFilters,
    nonStudioFilterSelected,
    upAndComingEngineer,
    appliedPromoCode,
  ]);

  const studioRooms = nonStudioFilterSelected
    ? []
    : studioRoomsData?.data || [];
  const engineers = engineerUsers?.data || [];

  const engineerResults = (
    <>
      <header className="explore-header-container">
        <h3 className="explore-section-header-text">
          {displayEngineersSecond
            ? "Recording Engineers"
            : "Verified Engineers"}
        </h3>
        <div
          onClick={() => {
            if (!displayStudios) {
              dispatch(updateUserSearchPage(2));
            } else {
              dispatch(updateUserSearchPage(1));
            }
            history.push({
              pathname: SCREENS.PAGINATED_ENGINEERS,
              search: location.search,
            });
          }}
          className="explore-view-more-top-button"
        >
          <p className="explore-view-more-text">View More</p>
          <FontAwesomeIcon
            size="xs"
            className="mx-2"
            icon={faChevronRight}
            color="var(--black)"
          />
        </div>
      </header>
      <div className="verified-list-container">
        {engineersLoading &&
          Array.from({ length: 4 }).map((_, index) => (
            <LoadingProfileCard key={index} />
          ))}
        {!engineersLoading &&
          engineers.length > 0 &&
          engineers.slice(0, displayStudios ? 4 : 12).map((user, index) => {
            const searchParams = new URLSearchParams(location.search);
            const promoCode = searchParams.get("promocode");
            const path = getProfileScreenRoute(user.username ?? "").concat(
              promoCode ? `?promocode=${promoCode}` : "",
            );
            return getUserProfileCard(user, index, path);
          })}
        {!engineersLoading && engineerUsers?.count === 0 && (
          <div className="no-results-container">
            <p className="no-results-text">
              {debouncedSearchTerm.length > 0
                ? `No results for "${debouncedSearchTerm}"`
                : "No results"}
            </p>
          </div>
        )}
      </div>
      {!displayStudios && engineerUsers && engineerUsers.count && (
        <div
          onClick={() => {
            dispatch(updateUserSearchPage(2));
            history.push(SCREENS.PAGINATED_ENGINEERS);
          }}
          className="explore-view-more-bottom-button"
        >
          <p className="explore-view-more-text">View More</p>
          <FontAwesomeIcon
            size="xs"
            className="mx-2"
            icon={faChevronRight}
            color="var(--black)"
          />
        </div>
      )}
    </>
  );

  return (
    <>
      <FullWidthHeader
        title=""
        color="blue"
        heading="Explore Profiles"
        subheading="Instantly book world class audio engineers and recording studios"
        backgroundColor={
          darkMode && isAuthenticated
            ? ColorPalette.SmoothIvoryDark
            : ColorPalette.SmoothIvory
        }
      />
      <SearchScreen
        disableStudioSearch={false}
        disableUserSearch={false}
        updateSearchView={updateSearchView}
        mapView={mapView}
      >
        {!mapView && (
          <div className="container">
            {!displayEngineersSecond && engineerResults}
            {displayStudios && (
              <>
                <header className="explore-header-container">
                  <h3 className="explore-section-header-text">
                    Verified Recording Studios
                  </h3>
                  <div
                    onClick={() => {
                      history.push({
                        pathname: SCREENS.PAGINATED_STUDIOS,
                        search: location.search,
                      });
                    }}
                    className="explore-view-more-top-button"
                  >
                    <p className="explore-view-more-text">View More</p>
                    <FontAwesomeIcon
                      size="xs"
                      className="mx-2"
                      icon={faChevronRight}
                      color="var(--black)"
                    />
                  </div>
                </header>
                {!studioRoomsLoading &&
                  !studioRoomsData?.local_studios_present && <RequestStudio />}
                <div className="verified-list-container">
                  {studioRoomsLoading &&
                    [1, 2, 3, 4].map((key, index) => (
                      <LoadingProfileCard key={index} />
                    ))}
                  {!studioRoomsLoading &&
                    Array.isArray(studioRooms) &&
                    studioRooms.slice(0, 4).map((studioRoom, index) => {
                      const location = history.location;
                      const queryParams = new URLSearchParams(location.search);
                      const promoCode = queryParams.get("promocode");

                      const path = getStudioRoomScreenRoute(
                        studioRoom.studio?.username ?? "",
                        studioRoom.id,
                      ).concat(promoCode ? `?promocode=${promoCode}` : "");
                      return getStudioRoomProfileCard(
                        studioRoom,
                        index,
                        path,
                        appliedPromoCode,
                      );
                    })}
                  {studioRooms.length === 0 && !studioRoomsLoading && (
                    <div className="no-results-container">
                      <p className="no-results-text">
                        {debouncedSearchTerm.length > 0
                          ? `No results for "${debouncedSearchTerm}"`
                          : "No results"}
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
            {displayEngineersSecond && engineerResults}
          </div>
        )}
        {mapView && <MapSearchDisplay searchType={MapSearchTypeEnum.EXPLORE} />}
        <DevLinkProvider>
          <EngineEarsFooter2024 />
        </DevLinkProvider>
      </SearchScreen>
    </>
  );
};

export const LoadingProfileCard: FC = () => {
  const { isDesktop } = useMediaQueryBreakpoint();
  return (
    <div className="trending-profile-card-container ">
      <Skeleton
        className="trending-profile-image-container"
        width={!isDesktop ? 345 : 285}
        height={!isDesktop ? 194 : 160}
      />
      <Skeleton width={200} height={8} />
      <Skeleton width={200} height={5} />
      <div className={"mx-2"} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Skeleton circle={true} width={20} height={20} className={"my-2"} />
        <Skeleton
          circle={true}
          width={20}
          height={20}
          className={"my-2 mx-2"}
        />
        <Skeleton width={90} height={5} />
      </div>
      <Skeleton width={200} height={5} />
    </div>
  );
};
