import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, HTMLAttributes, useMemo, useRef, useState } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { FullWidthBadges, Trophy } from "../../../store/models/trophy";
import { Badge, BadgeProgress } from "../../elements/Badge/Badge";
import { Card } from "../../elements/Card/Card";
import { MusoCard } from "../../elements/MusoCard/MusoCard";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { useFetchTrophies } from "../../../hooks/useFetchTrophies";
import "./TrophyRoomList.css";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";

export interface TrophyRoomListProps {
  userId?: number;
  studioRoomId?: number;
  studioId?: number;
}

interface TrophyListProps extends HTMLAttributes<HTMLLIElement> {
  trophy: Trophy;
  idx: number;
  hideText?: boolean;
  clipsProjectCompleted?: boolean;
  useProgress?: boolean;
  showOnlyCompleted?: boolean;
  showInfo?: boolean;
  showStatus?: boolean;
  showPublicProfileTitle?: boolean;
}

export const TrophyListItem: FC<TrophyListProps> = ({
  trophy,
  idx,
  hideText = false,
  clipsProjectCompleted = false,
  useProgress = false,
  showOnlyCompleted = true,
  showInfo = true,
  showStatus = true,
  showPublicProfileTitle = false,
  ...props
}) => {
  const BadgeComponent = useProgress ? BadgeProgress : Badge;

  if (showOnlyCompleted && trophy.progress !== 100) {
    return null;
  }

  const textToDisplay = showPublicProfileTitle
    ? trophy.publicProfileTitle
    : trophy.text;

  return (
    <li
      className={"".concat(
        FullWidthBadges.includes(trophy.badgeEnum)
          ? "trophy-list-item"
          : "trophy-list-item",
      )}
      key={idx}
      {...props}
    >
      <BadgeComponent badgeEnum={trophy.badgeEnum} progress={trophy.progress} />
      {!FullWidthBadges.includes(trophy.badgeEnum) && !hideText && (
        <div className="trophy-texts">
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <p
              className="b3 trophy-list-text"
              style={{
                color: trophy.progress !== 100 ? "gray" : "var(--black)",
              }}
            >
              {clipsProjectCompleted
                ? textToDisplay?.replaceAll(/[^0-9]/g, "")
                : textToDisplay}
            </p>
            {trophy.info && showInfo && <ToolTipTextArea text={trophy.info} />}
          </div>
          {trophy.textStatus && showStatus && (
            <p className="b3 trophy-list-textStatus">{trophy.textStatus}</p>
          )}
        </div>
      )}
    </li>
  );
};

const STUDIO_DESKTOP_HEIGHT = 275;
const ENGINEER_DESKTOP_HEIGHT = 425;

export const TrophyRoomCard: FC<TrophyRoomListProps> = ({
  userId,
  studioId,
}) => {
  const { isMobile } = useMediaQueryBreakpoint();
  const [showingMore, setShowingMore] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const showMoreText: string = showingMore ? "Hide" : "View more";
  const { loading, userTrophies, studioTrophies } = useFetchTrophies({
    userId,
    studioId,
  });
  const trophies =
    (userId ? userTrophies : studioId ? studioTrophies : []) ?? [];
  const displayCount: number = useMemo(() => {
    if (showingMore) {
      return trophies.length;
    }
    return isMobile ? 4 : 6;
  }, [showingMore, trophies.length, isMobile]);
  const cardHeight: string | number = useMemo(() => {
    if (isMobile) {
      return "fit-content";
    }
    return studioId ? STUDIO_DESKTOP_HEIGHT : ENGINEER_DESKTOP_HEIGHT;
  }, [isMobile, studioId]);

  const handleShowMore = () => {
    if (showingMore) {
      if (isMobile) {
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }
      setTimeout(() => {
        setShowingMore(false);
      }, 500);
    } else {
      setShowingMore(true);
    }
  };

  return (
    <Card
      hideOverflow={true}
      height={cardHeight}
      customClassName="dashboard-cards-mobile-container"
    >
      <div className="trophy-column-container">
        <div
          style={{ cursor: loading ? "disable" : "pointer" }}
          className="trophy-list-header"
        >
          <p
            className="b3-semi-bold"
            style={{
              color: "var(--medium-grey)",
              textTransform: "uppercase",
              paddingBottom: "15px",
            }}
          >
            Achievements
          </p>
        </div>
        <div className={"trophy-card-container "}>
          {loading ? (
            <SoundWaveLoader width={100} height={100} />
          ) : (
            <div ref={ref} className="trophy-items-container">
              {trophies.slice(0, displayCount).map((trophy, index) => (
                <TrophyListItem
                  key={trophy.badgeEnum}
                  trophy={trophy}
                  idx={index}
                  useProgress={true}
                  showOnlyCompleted={false}
                />
              ))}
            </div>
          )}
        </div>
        <div className="trophy-list-shared-muso-container">
          {!isMobile && (
            <div className="trophy-list-single-column-container">
              {!loading &&
                !showingMore &&
                trophies
                  .slice(displayCount, displayCount + 4)
                  .map((trophy, index) => (
                    <TrophyListItem
                      key={trophy.badgeEnum}
                      trophy={trophy}
                      idx={index}
                      useProgress={true}
                      showOnlyCompleted={false}
                    />
                  ))}
            </div>
          )}
          {!studioId && <MusoCard />}
        </div>
        {isMobile && (
          <div
            style={{ cursor: loading ? "disable" : "pointer" }}
            onClick={handleShowMore}
            className="view-more-container"
          >
            <p className="b3" style={{ marginRight: "5px" }}>
              {showMoreText}
            </p>
            <FontAwesomeIcon icon={showingMore ? faChevronUp : faChevronDown} />
          </div>
        )}
      </div>
    </Card>
  );
};
