import queryString from "query-string";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { SCREENS } from "../../routes/screens";
import { updateStudioRoomSearchPage } from "../../store/actions/studioRoomSearch";
import {
  requestLatLong,
  updateUserSearchPage,
} from "../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AmenityType } from "../../store/models/amenity";
import { Genre } from "../../store/models/genres";
import { ProjectType } from "../../store/models/project";
import { WEEKDAYS } from "../../store/models/workingHours";

export enum SearchView {
  ListView = "list-view",
  MapView = "map-view",
}

/**
 * Query parameter keys for the search filters
 * @example ?service_types=1,2&search_view=list-view
 */
export enum SearchScreenParamKeys {
  ServiceTypes = "service_types",
  SearchView = "search_view",
  SimpleBudgetSelected = "simple_budget_selected",
  MaxDistance = "max_distance",
  Duration = "duration",
  DaysAvailable = "days_available",
  Genres = "genres",
  Amenities = "amenities",
  AllowNoEngineer = "allow_no_engineer",
  UpAndComingEngineer = "up_and_coming_eng",
  MinRate = "min_rate",
  MaxRate = "max_rate",
  PromoCode = "promocode",
  PaymentDeposits = "payment_deposits",
}

export interface SetSearchFiltersProps {
  newServiceTypes: ProjectType[];
  newSimpleBudgetsSelected: string[];
  newMaxDistance: number;
  newDuration: number;
  newDaysAvailable: number[];
  newGenres: Genre[];
  newAmenities: AmenityType[];
  newAllowNoEngineer: boolean;
  newMinRate: number;
  newMaxRate: number;
}

type SearchFiltersQueryParams = Partial<
  Record<SearchScreenParamKeys, string | number | boolean | undefined>
>;

/**
 * Parses a comma-separated string of numbers into an array of integers.
 * Filters out any non-numeric values.
 * @param queryStringValue - The comma-separated string of numbers
 * @returns An array of parsed integers
 */
const parseQueryStringAsIntArray = (queryStringValue: string | undefined) => {
  return (queryStringValue
    ?.split(",")
    .map((el) => parseQueryStringAsInt(el))
    .filter((el) => !Number.isNaN(el)) || []) as number[];
};

/**
 * Parses a string into an integer.
 * @param queryStringValue - The string to parse
 * @param defaultValue - The value to return if parsing fails
 * @returns The parsed integer or the default value
 */
const parseQueryStringAsInt = (
  queryStringValue: string | undefined,
  defaultValue?: number,
) => {
  if (queryStringValue) {
    const queryStringValueAsInt = parseInt(queryStringValue);
    if (!Number.isNaN(queryStringValueAsInt)) {
      return queryStringValueAsInt;
    }
  }

  return defaultValue;
};

/**
 * Parses a string into a boolean value.
 * @param queryStringValue - The string to parse
 * @param defaultValue - The value to return if the string is not 'true'
 * @returns true if the string is 'true', otherwise the default value
 */
const parseQueryStringAsBoolean = (
  queryStringValue: string | undefined,
  defaultValue = false,
) => {
  if (queryStringValue && queryStringValue === "true") {
    return true;
  }

  return defaultValue;
};

/**
 * Parses the current URL's query parameters into a key-value object.
 * @returns An object with query parameter keys and their string values
 */
export const parseUrlQueryParams = () => {
  return queryString.parse(location.search) as Record<
    string,
    string | undefined
  >;
};

export const useUpdateSearchFilters = () => {
  const history = useHistory();

  return (
    newSearchFiltersObject: SearchFiltersQueryParams,
    newPathname?: string,
  ) => {
    const parsedQueryParams = parseUrlQueryParams();
    const updatedQueryParamsString = queryString.stringify(
      {
        ...parsedQueryParams,
        ...newSearchFiltersObject,
      },
      { skipNull: true, skipEmptyString: true },
    );

    history.push({
      pathname: newPathname,
      search: updatedQueryParamsString,
    });
  };
};

export const useSearchFilters = () => {
  const dispatch = useAppDispatch();
  const updateSearchFiltersAndNavigate = useUpdateSearchFilters();
  const parsedQueryParamsObject = parseUrlQueryParams();
  const { latitude, longitude } = useAppSelector((state) => state.userSearch);

  const serviceTypesValue =
    parsedQueryParamsObject[SearchScreenParamKeys.ServiceTypes];
  const simpleBudgetsSelectedValue =
    parsedQueryParamsObject[SearchScreenParamKeys.SimpleBudgetSelected];
  const maxDistanceValue =
    parsedQueryParamsObject[SearchScreenParamKeys.MaxDistance];
  const durationValue = parsedQueryParamsObject[SearchScreenParamKeys.Duration];
  const daysAvailableValue =
    parsedQueryParamsObject[SearchScreenParamKeys.DaysAvailable];
  const genresValue = parsedQueryParamsObject[SearchScreenParamKeys.Genres];
  const amenitiesValue =
    parsedQueryParamsObject[SearchScreenParamKeys.Amenities];
  const allowNoEngineerValue =
    parsedQueryParamsObject[SearchScreenParamKeys.AllowNoEngineer];
  const upAndComingEngineerValue =
    parsedQueryParamsObject[SearchScreenParamKeys.UpAndComingEngineer];
  const minRateValue = parsedQueryParamsObject[SearchScreenParamKeys.MinRate];
  const maxRateValue = parsedQueryParamsObject[SearchScreenParamKeys.MaxRate];
  const promoCodeValue =
    parsedQueryParamsObject[SearchScreenParamKeys.PromoCode];
  const paymentDepositsValue =
    parsedQueryParamsObject[SearchScreenParamKeys.PaymentDeposits];

  const serviceTypes = useMemo(() => {
    return parseQueryStringAsIntArray(serviceTypesValue).filter((el) =>
      Object.values(ProjectType).includes(el),
    ) as ProjectType[];
  }, [serviceTypesValue]);

  const simpleBudgetsSelected = useMemo(
    () => simpleBudgetsSelectedValue?.split(",") || [],
    [simpleBudgetsSelectedValue],
  );

  const maxDistance = useMemo(
    () => parseQueryStringAsInt(maxDistanceValue, 0)!,
    [maxDistanceValue],
  );

  // Get the user's location if the max distance is set
  useEffect(() => {
    if (maxDistance && !latitude && !longitude) {
      dispatch(requestLatLong());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const duration = useMemo(
    () => parseQueryStringAsInt(durationValue, 0)!,
    [durationValue],
  );

  const daysAvailable = useMemo(
    () =>
      parseQueryStringAsIntArray(daysAvailableValue).filter((el) =>
        Object.values(WEEKDAYS).includes(el),
      ) as WEEKDAYS[],
    [daysAvailableValue],
  );

  const genres = useMemo(
    () =>
      parseQueryStringAsIntArray(genresValue).filter((el) =>
        Object.values(Genre).includes(el),
      ) as Genre[],
    [genresValue],
  );

  const amenities = useMemo(
    () =>
      parseQueryStringAsIntArray(amenitiesValue).filter((el) =>
        Object.values(AmenityType).includes(el),
      ) as AmenityType[],
    [amenitiesValue],
  );

  const allowNoEngineer = useMemo(
    () => parseQueryStringAsBoolean(allowNoEngineerValue),
    [allowNoEngineerValue],
  );

  const upAndComingEngineer = useMemo(
    () => parseQueryStringAsBoolean(upAndComingEngineerValue),
    [upAndComingEngineerValue],
  );

  const minRate = useMemo(
    () => parseQueryStringAsInt(minRateValue, 0)!,
    [minRateValue],
  );
  const maxRate = useMemo(
    () => parseQueryStringAsInt(maxRateValue, 0)!,
    [maxRateValue],
  );

  const paymentDeposits = useMemo(
    () => parseQueryStringAsBoolean(paymentDepositsValue),
    [paymentDepositsValue],
  );

  const setSearchFilters = useCallback(
    ({
      newServiceTypes,
      newSimpleBudgetsSelected,
      newDuration,
      newDaysAvailable,
      newGenres,
      newAmenities,
      newAllowNoEngineer,
      newMinRate,
      newMaxRate,
      newMaxDistance,
    }: SetSearchFiltersProps) => {
      const updatedSearchQueryObject = {
        [SearchScreenParamKeys.ServiceTypes]: newServiceTypes.join(","),
        [SearchScreenParamKeys.SimpleBudgetSelected]:
          newSimpleBudgetsSelected.join(","),
        [SearchScreenParamKeys.MaxRate]: newMaxRate || undefined,
        [SearchScreenParamKeys.MinRate]: newMinRate || undefined,
        [SearchScreenParamKeys.Duration]: newDuration || undefined,
        [SearchScreenParamKeys.DaysAvailable]: newDaysAvailable.join(","),
        [SearchScreenParamKeys.Genres]: newGenres.join(","),
        [SearchScreenParamKeys.Amenities]: newAmenities.join(","),
        [SearchScreenParamKeys.AllowNoEngineer]:
          newAllowNoEngineer || undefined,
      } as SearchFiltersQueryParams;

      if (newMaxDistance && latitude && longitude) {
        updatedSearchQueryObject[SearchScreenParamKeys.MaxDistance] =
          newMaxDistance;
      } else {
        updatedSearchQueryObject[SearchScreenParamKeys.MaxDistance] = undefined;
      }

      let navigateTo = location.pathname;
      if (
        ![SCREENS.PAGINATED_ENGINEERS, SCREENS.PAGINATED_STUDIOS].includes(
          location.pathname as SCREENS,
        )
      ) {
        navigateTo = SCREENS.SEARCH;
      }

      updateSearchFiltersAndNavigate(updatedSearchQueryObject, navigateTo);
      dispatch(updateUserSearchPage(1));
      dispatch(updateStudioRoomSearchPage(1));
    },
    [dispatch, latitude, longitude, updateSearchFiltersAndNavigate],
  );

  const clearAllSearchFilters = useCallback(() => {
    updateSearchFiltersAndNavigate({
      [SearchScreenParamKeys.ServiceTypes]: undefined,
      [SearchScreenParamKeys.SimpleBudgetSelected]: undefined,
      [SearchScreenParamKeys.MaxRate]: undefined,
      [SearchScreenParamKeys.MinRate]: undefined,
      [SearchScreenParamKeys.Duration]: undefined,
      [SearchScreenParamKeys.DaysAvailable]: undefined,
      [SearchScreenParamKeys.Genres]: undefined,
      [SearchScreenParamKeys.Amenities]: undefined,
      [SearchScreenParamKeys.AllowNoEngineer]: undefined,
      [SearchScreenParamKeys.UpAndComingEngineer]: undefined,
      [SearchScreenParamKeys.MaxDistance]: undefined,
    });
    dispatch(updateUserSearchPage(1));
    dispatch(updateStudioRoomSearchPage(1));
  }, [dispatch, updateSearchFiltersAndNavigate]);

  return {
    serviceTypes,
    simpleBudgetsSelected,
    maxDistance,
    duration,
    daysAvailable,
    genres,
    amenities,
    allowNoEngineer,
    upAndComingEngineer,
    minRate,
    maxRate,
    paymentDeposits,
    promoCode: promoCodeValue,
    setSearchFilters,
    clearAllSearchFilters,
    parsedQueryParams: parsedQueryParamsObject,
  };
};

export const useUpAndComingEngineerQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();

  const upAndComingEngineerValue =
    parsedQueryParams[SearchScreenParamKeys.UpAndComingEngineer];

  const upAndComingEngineer = useMemo(
    () => parseQueryStringAsBoolean(upAndComingEngineerValue),
    [upAndComingEngineerValue],
  );

  const setUpAndComingEngineer = useCallback(
    (newUpAndComingEngineer: boolean) => {
      updateSearchFilters({
        [SearchScreenParamKeys.UpAndComingEngineer]:
          newUpAndComingEngineer || undefined,
      });
    },
    [updateSearchFilters],
  );

  return { upAndComingEngineer, setUpAndComingEngineer };
};

export const useGenresQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();
  const genresValue = parsedQueryParams[SearchScreenParamKeys.Genres];

  const genres = useMemo(
    () =>
      parseQueryStringAsIntArray(genresValue).filter((el) =>
        Object.values(Genre).includes(el),
      ) as Genre[],
    [genresValue],
  );

  const setGenres = (newGenres: Genre[]) => {
    updateSearchFilters({
      [SearchScreenParamKeys.Genres]: newGenres.join(","),
    });
  };

  return { genres, setGenres };
};

export const useServiceTypesQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();
  const serviceTypesValue =
    parsedQueryParams[SearchScreenParamKeys.ServiceTypes];

  const serviceTypes = useMemo(
    () =>
      parseQueryStringAsIntArray(serviceTypesValue).filter((el) =>
        Object.values(ProjectType).includes(el),
      ) as ProjectType[],
    [serviceTypesValue],
  );

  const setServiceType = (serviceType: ProjectType) => {
    let newServiceTypes: ProjectType[] = [];
    const updatedSearchQueryObject = {} as SearchFiltersQueryParams;

    if (serviceType === ProjectType.MIXING) {
      if (
        serviceTypes.includes(ProjectType.TWO_TRACK_MIXING) ||
        serviceTypes.includes(ProjectType.MIXING)
      ) {
        const updatedServiceTypes = serviceTypes.filter(
          (service) =>
            service !== ProjectType.TWO_TRACK_MIXING &&
            service !== ProjectType.MIXING,
        );

        newServiceTypes = updatedServiceTypes;
      } else {
        newServiceTypes = [
          ...serviceTypes,
          ProjectType.MIXING,
          ProjectType.TWO_TRACK_MIXING,
        ];
      }
    } else {
      if (serviceTypes.includes(serviceType)) {
        newServiceTypes = serviceTypes.filter(
          (service) => service !== serviceType,
        );
      } else {
        newServiceTypes = [...serviceTypes, serviceType];
      }
    }

    if (newServiceTypes.length === 5 || serviceType === ProjectType.NO_TYPE) {
      updatedSearchQueryObject[SearchScreenParamKeys.ServiceTypes] = undefined;
    } else {
      updatedSearchQueryObject[SearchScreenParamKeys.ServiceTypes] =
        newServiceTypes.join(",");
    }

    updateSearchFilters(updatedSearchQueryObject);
  };

  const setServiceTypes = (newServiceTypes: ProjectType[]) => {
    updateSearchFilters({
      [SearchScreenParamKeys.ServiceTypes]: newServiceTypes.join(","),
    });
  };

  return { setServiceType, serviceTypes, setServiceTypes };
};

export const useSearchViewQuery = () => {
  const parsedQueryString = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();

  const searchViewValue = parsedQueryString[SearchScreenParamKeys.SearchView];

  const searchView = useMemo(() => {
    if (
      searchViewValue &&
      Object.values(SearchView).includes(searchViewValue as SearchView)
    ) {
      return searchViewValue;
    }

    return SearchView.ListView;
  }, [searchViewValue]);

  const mapView = useMemo(
    () => searchView === SearchView.MapView,
    [searchView],
  );

  const setSearchView = useCallback(
    (newSearchView: SearchView) => {
      updateSearchFilters({
        [SearchScreenParamKeys.SearchView]: newSearchView,
      });
    },
    [updateSearchFilters],
  );

  const toggleSearchView = useCallback(() => {
    if (searchView === SearchView.ListView) {
      setSearchView(SearchView.MapView);
    } else {
      setSearchView(SearchView.ListView);
    }
  }, [searchView, setSearchView]);

  return { searchView, setSearchView, toggleSearchView, mapView };
};
