import { Box, useTheme } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthFlows } from "../../../constants/authSteps";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  useSubscriptionMonthlyPrice,
  useSubscriptionName,
} from "../../../hooks/useSubscriptionTrophy";
import { SCREENS } from "../../../routes/screens";
import { addBasicSubscriptionPlan } from "../../../store/actions/subscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import {
  getSubscriptionCardProps,
  getSubscriptionRecommendation,
} from "../../../store/utils/subscriptionUtils";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import {
  authModalOpenAtom,
  currentFlowAtom,
} from "../../components/Auth/atoms";
import {
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../../components/Navigation/BottomNav/useBottomTabBarOverlayView";
import { SubscriptionCard } from "../../components/SubscriptionCard/SubscriptionCard";
import { SubscriptionComparisonTable } from "../../components/SubscriptionComparisonTable/SubscriptionComparisonTable";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { selectSubscriptionStickyButtons } from "./atoms";
import { MembershipFaq } from "./components/Faq/MembershipFaq";
import "./SelectSubscriptionScreen.css";
import {
  SelectSubscriptionCardsContainer,
  SelectSubscriptionScreenContainer,
  SelectSubscriptionScreenHeaderContainer,
  StickyButtonsContainer,
} from "./SelectSubscriptionScreen.styles";

export interface SelectSubscriptionScreenProps {
  numberOfSongs: number;
  averageProjectPrice: number;
  onClickPreviousStep: () => void;
  refundFees: boolean;
  onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => void;
}

export const SelectSubscriptionScreen = ({
  numberOfSongs,
  averageProjectPrice,
  onClickPreviousStep,
  refundFees,
  onSubscriptionSelected,
}: SelectSubscriptionScreenProps) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const [loading, setLoading] = useState(false);
  const [selectBasicPlan, setSelectBasicPlan] = useState(false);
  const [isShowingStickyButtons] = useAtom(selectSubscriptionStickyButtons);
  const projectStats = useAppSelector(
    (state) => state.statService.projectStats,
  );
  const numberOfActiveAndCompletedSongs =
    Number(projectStats?.num_active_projects) +
    Number(projectStats?.num_completed_projects);
  const [recommendation, amountSaved] = getSubscriptionRecommendation(
    numberOfSongs,
    averageProjectPrice,
    numberOfActiveAndCompletedSongs,
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const { isMobile } = useMediaQueryBreakpoint();
  const setFlow = useSetAtom(currentFlowAtom);

  const subscriptionCardProps = getSubscriptionCardProps({
    plan: recommendation,
    onSubscriptionSelected: () => {
      recommendation === SUBSCRIPTION_PLAN.LEGACY_BASIC_PLAN
        ? setSelectBasicPlan(true)
        : onSubscriptionSelected(recommendation);
    },
    recommended: true,
  });

  const packageName = useSubscriptionName(recommendation);

  const comparisonTableRef = useRef<HTMLDivElement>(null);

  const onConfirmBasicPlan = () => {
    setLoading(true);
    window.analytics.track(getDebugEventPrefix + "choose_basic_subscription", {
      user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
    });
    dispatch(addBasicSubscriptionPlan()).then(() => {
      history.push(SCREENS.DASHBOARD);
    });
  };

  const subscriptionStickyButtons = useBottomTabBarOverlayView(
    // This is always true since we want to show on all screen sizes
    true,
    <StickyButtonsContainer>
      <Box
        sx={{
          display: "flex",
          padding: "16px 6px 16px 15px",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Text variant={TextStyleVariant.P2} weight={TEXT_WEIGHT.BOLD}>
          Essentials
        </Text>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            if (!isAuthenticated) {
              if (isMobile) history.push(SCREENS.AUTH_SCREEN);
              setFlow(AuthFlows.START_SIGNUP);
              setAuthModalOpen(true);
              return;
            }
            setSelectBasicPlan(true);
          }}
        >
          Get started
        </Button>
        <Text
          variant={TextStyleVariant.P2}
          style={{
            color: theme.palette.customColor.unselectedTextPrimaryColor,
          }}
        >
          No credit card required
        </Text>
        <Text variant={TextStyleVariant.H4} style={{ fontWeight: "400" }}>
          ${useSubscriptionMonthlyPrice(SUBSCRIPTION_PLAN.ESSENTIALS_PLAN)}
          <Text variant={TextStyleVariant.P1} style={{ display: "inline" }}>
            /mo
          </Text>
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "16px 6px 16px 15px",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Text variant={TextStyleVariant.P2} weight={TEXT_WEIGHT.BOLD}>
          Platinum
        </Text>
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => {
            if (!isAuthenticated) {
              if (isMobile) history.push(SCREENS.AUTH_SCREEN);
              setFlow(AuthFlows.START_SIGNUP);
              setAuthModalOpen(true);
              return;
            }
            onSubscriptionSelected(SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY);
          }}
        >
          {user?.engineer?.stripe_subscription_id || refundFees
            ? "Select plan"
            : "Start trial"}
        </Button>
        <Text
          variant={TextStyleVariant.P2}
          style={{
            color: theme.palette.customColor.unselectedTextPrimaryColor,
          }}
        >
          Starting at
        </Text>
        <Text variant={TextStyleVariant.H4} style={{ fontWeight: "400" }}>
          ${useSubscriptionMonthlyPrice(SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY)}
          <Text variant={TextStyleVariant.P1} style={{ display: "inline" }}>
            /mo
          </Text>
        </Text>
      </Box>
    </StickyButtonsContainer>,
    DEFAULT_TAB_OVERLAY_CLASS,
  );

  return (
    <>
      <SelectSubscriptionScreenContainer $isAuthenticated={isAuthenticated}>
        <SelectSubscriptionScreenHeaderContainer>
          <Text
            variant={TextStyleVariant.H4}
            style={
              isAuthenticated
                ? undefined
                : { fontWeight: 500, fontSize: "38px" }
            }
          >
            Compare our plans below
          </Text>
          <Text variant={TextStyleVariant.P1}>
            Join the EngineEars community and take your business to the next
            level.
          </Text>
        </SelectSubscriptionScreenHeaderContainer>
        <SelectSubscriptionCardsContainer>
          {[
            SUBSCRIPTION_PLAN.ESSENTIALS_PLAN,
            SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY,
          ].map((subscriptionPlan) => (
            <SubscriptionCard
              buttonDisabledMessage="This plan is unlocked once you have booked 10 mixes or masters through EngineEars."
              key={subscriptionPlan}
              refundFees={refundFees}
              comparisonTableRef={comparisonTableRef}
              {...getSubscriptionCardProps({
                plan: subscriptionPlan,
                onSubscriptionSelected: () => {
                  if (subscriptionPlan === SUBSCRIPTION_PLAN.ESSENTIALS_PLAN) {
                    setSelectBasicPlan(true);
                  } else {
                    onSubscriptionSelected(subscriptionPlan);
                  }
                },
                recommended: subscriptionPlan === recommendation,
              })}
            />
          ))}
        </SelectSubscriptionCardsContainer>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            alignSelf: "center",

            ".MuiTypography-root": {
              textAlign: "center",
            },
          }}
          ref={comparisonTableRef}
        >
          <Text
            variant={TextStyleVariant.H5}
            style={
              isAuthenticated
                ? undefined
                : { fontWeight: 500, fontSize: "38px" }
            }
          >
            Feature Comparison
          </Text>
        </Box>
        <SubscriptionComparisonTable
          onSubscriptionSelected={(selectedPlan: SUBSCRIPTION_PLAN) => {
            if (selectedPlan === SUBSCRIPTION_PLAN.ESSENTIALS_PLAN) {
              setSelectBasicPlan(true);
            } else {
              onSubscriptionSelected(selectedPlan);
            }
          }}
        />
        <div style={{ marginBottom: "160px" }}>
          <MembershipFaq />
        </div>
      </SelectSubscriptionScreenContainer>

      <BaseModal
        open={selectBasicPlan}
        setOpen={() => setSelectBasicPlan(false)}
        header="Confirm Essentials Plan"
      >
        <div className="select-basic-modal-subscription">
          <h3 className="mx-2">Are you sure you want to continue?</h3>
          <p className="b2 mx-2 my-4">
            The Essentials membership includes a 10% service fee on all payouts.
            We recommend Platinum to save on service fees.
          </p>
          <div className="select-basic-modal-footer">
            <Button loading={loading} onClick={onConfirmBasicPlan}>
              Choose Essentials
            </Button>
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={() => setSelectBasicPlan(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </BaseModal>

      {isShowingStickyButtons && subscriptionStickyButtons}
    </>
  );
};
