import { Box, styled } from "@mui/material";

export const SubscriptionSuccessScreenContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "84px",
  padding: "24px",
}));

export const SubscriptionSuccessScreenHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",

  ".MuiTypography-root": {
    textAlign: "center",
  },
}));

export const SubscriptionSuccessScreenBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  maxWidth: "calc(100% - 48px)",

  ".MuiTypography-root": {
    textAlign: "center",
  },
}));

export const SubscriptionSuccessScreenFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",

  ".MuiTypography-root": {
    textAlign: "center",
  },
}));
