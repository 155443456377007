import ListItem, { ListItemProps } from "@mui/material/ListItem";
import { Grid2, styled } from "@mui/material";
import { Button } from "../../core-ui/components/Button/MUIButton";
import useGeneratedAssetQuery, {
  GeneratedAssetQueryProps,
} from "../../../hooks/userHooks/useGeneratedAssetQuery";
import { useMemo } from "react";
import { DEFAULT_USER_IMAGE_SRC } from "../../../constants/googleStorage";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { Text } from "../../core-ui/components/Text/Text";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";

interface GeneratedAssetListItemProps
  extends Omit<ListItemProps, "divider" | "children"> {
  image: GeneratedAssetQueryProps;
  name?: string;
}

const SaveButton = styled(Button)(() => {
  return {
    width: "100%",
  };
});

const ThumbnailPreview = styled(`img`)(() => {
  return {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    opacity: 0,
  };
});

const ThumbnailPreviewLink = styled(`a`)(() => {
  return {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
});

export const GeneratedAssetListItem = ({
  image,
  name,
  ...props
}: GeneratedAssetListItemProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const { key, studioId, serviceType, promoCodeId, variant } = image;
  const { data, isLoading, isSuccess } = useGeneratedAssetQuery({
    key,
    studioId,
    serviceType,
    promoCodeId,
    variant,
  });
  const imageName = useMemo(() => {
    return data?.file.name?.split(".")[0];
  }, [data]);
  return (
    <ListItem {...props} divider>
      <Grid2 container width={"100%"} height={"10vh"}>
        <Grid2
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          size={{ xs: 3, md: 3 }}
        >
          <ThumbnailPreviewLink
            href={data?.downloadUrl}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                TRACKING_EVENTS_NAME.USER_CLICK_THUMBNAIL_ASSET,
                {
                  imageName,
                  key,
                  studioId,
                  serviceType,
                  promoCodeId,
                },
              );
            }}
            style={
              isLoading
                ? undefined
                : {
                    backgroundImage: `url(${data?.downloadUrl || DEFAULT_USER_IMAGE_SRC})`,
                  }
            }
            target={"_blank"}
          >
            {isLoading ? (
              <SoundWaveLoader width={100} height={100} />
            ) : (
              <ThumbnailPreview
                src={data?.downloadUrl || DEFAULT_USER_IMAGE_SRC}
                alt=""
              />
            )}
          </ThumbnailPreviewLink>
        </Grid2>
        <Grid2
          justifyContent={"center"}
          alignItems={"center"}
          px={2}
          display={"flex"}
          size={{ xs: 7, md: 5 }}
        >
          <Text>{name || "Your EngineEars Asset"}</Text>
        </Grid2>
        <Grid2
          display={"flex"}
          gap={1}
          alignItems={"center"}
          justifyContent={"center"}
          size={{ xs: 2, md: 4 }}
        >
          <SaveButton
            component={"a"}
            LinkProps={{
              download: imageName,
            }}
            disabled={!isSuccess || !data}
            href={data?.downloadUrl}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                TRACKING_EVENTS_NAME.USER_CLICK_SAVE_ASSET,
                {
                  imageName,
                  key,
                  studioId,
                  serviceType,
                  promoCodeId,
                },
              );
            }}
          >
            {!isDesktop ? "Save" : "Download to Share"}
          </SaveButton>
        </Grid2>
      </Grid2>
    </ListItem>
  );
};
